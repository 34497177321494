import moment from "moment";

export const DATA = [
  {
    start: moment().toDate(),
    end: moment().add(1, "hour").toDate(),
    title: "Soggiorno presso il B&B",
  },
  {
    start: moment().add(1, "day").add(2, "hours").toDate(),
    end: moment().add(1, "day").add(4, "hours").toDate(),
    title: "Soggiorno presso villa blu",
  },
  {
    start: moment().subtract(3, "day").add(3, "hours").toDate(),
    end: moment().subtract(3, "day").add(4, "hours").toDate(),
    title: "Soggiorno presso villa blu",
  },
]