import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import { PostDataType } from "data/types";
import { Link, useNavigate } from "react-router-dom";
import Logo from "shared/Logo/Logo";

export interface NotificationBannerProps {
  message: string;
  cta: string;
  link: string;
}

const NotificationBanner: FC<NotificationBannerProps> = ({
  message,
  cta,
  link
}) => {
  const [show, setShow] = useState<boolean>(true);
  const navigate = useNavigate()

  const handleCta = () => {
    setShow(false)
    navigate(link)
  }

  if (!show) {

  }
  else {
    return (
      <div id="marketing-banner" className="fixed z-50 flex flex-col md:flex-row justify-between w-[calc(100%-2rem)] p-4 -translate-x-1/2 bg-white border border-gray-100 rounded-lg shadow-sm lg:max-w-7xl left-1/2 bottom-6 dark:bg-gray-700 dark:border-gray-600">
        <div className="flex flex-col items-start mb-3 me-4 md:items-center md:flex-row md:mb-0">
          <a href="" className="flex items-center mb-2 border-gray-200 md:pe-4 md:me-4 md:border-e md:mb-0 dark:border-gray-600">
            <Logo />
          </a>
          <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">{message}</p>
        </div>
        <div className="flex items-center flex-shrink-0">
          <button onClick={handleCta} className="px-5 py-2 me-2 text-xs font-medium text-white bg-primary-6000 hover:bg-primary-700 ttnc-ButtonPrimary rounded-md focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">{cta}</button>
          <button onClick={() => setShow(false)} data-dismiss-target="#marketing-banner" type="button" className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span className="sr-only">Chiudi</span>
          </button>
        </div>
      </div>
    );
  }

};

export default NotificationBanner;
