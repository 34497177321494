import React, { useEffect, useState } from 'react';
import ChatSidebar from './Components/ChatSidebar';
import { Message } from './types/Message';
import { Chat } from './types/Chat';
// import ChatBody from './Components/ChatBody';
import ChatBody from '../../containers/Chat/Components/ChatBody';
import { useGetAllMessagesQuery, useGetConversationsQuery, useLazyGetAllMessagesQuery, useSendMessageMutation } from 'features/chat/chatApiSlice';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentChat, setChat, setMessages, setSendingMessage, isSendingMessage } from 'features/chat/chatSlice';
import { useDispatch } from 'react-redux';
import useWindowSize from 'hooks/useWindowResize';
import "./PageClientChat.css"
import { selectCurrentLoggedClient } from 'features/clientAuth/authClientSlice';

const PageClientChat: React.FC = () => {
  const chat = useSelector(selectCurrentChat)
  const client = useSelector(selectCurrentLoggedClient)
  const sendingMessage: Boolean = useSelector(isSendingMessage);

  const { data: chats, isLoading } = useGetConversationsQuery({})
  const [getMessages, { data: messages }] = useLazyGetAllMessagesQuery()
  const [sendMessage, { data: sentMessage }] = useSendMessageMutation()

  const [showChat, setShowChat] = useState(false)

  const dispatch = useDispatch()
  const { width } = useWindowSize()

  useEffect(() => {
    console.log("client ", client)
    document.body.classList.add('overflow-hidden');
    document.body.classList.add('chat-screen');
    return () => {
      document.body.classList.remove('overflow-hidden');
      document.body.classList.remove('chat-screen');

    }
  }, []);

  useEffect(() => {
    if (!chat && chats && isLoading === false) {
      dispatch(setChat(chats[0]))
    }
  }, [isLoading])

  useEffect(() => {
    if (chat && chat.chat_uuid) {
      getMessages(chat.chat_uuid)
    }
  }, [chat])

  useEffect(() => {
    dispatch(setMessages(messages))
  }, [messages])

  // const handleOnSubmit = async (e: any) => {
  //   e.preventDefault();
  //   let message = e.target.message.value || "";
  //   message = message.trim();
  //   if( message ){
  //     dispatch(setSendingMessage( true ));
  //     try {
  //       const res = await sendMessage({
  //         chat_uuid: chat.chat_uuid,
  //         message: e.target.message.value
  //       }).unwrap()
  //       e.target.reset();
  //     } catch (e) {
  //     }
  //   }
  //   dispatch(setSendingMessage( false ));
  // }

  const handleMessageSend = async ( message: String ) => {
    console.log( "Sending Message from new method" );
    if( message ){
      dispatch(setSendingMessage( true ));
      try {
        const res = await sendMessage({
          chat_uuid: chat.chat_uuid,
          message: message
        }).unwrap()
        // e.target.reset();
      } catch (e) {
      }
    }
    dispatch(setSendingMessage( false ));

  }

  return (
    <div className="PageClientChat flex flex-row sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 md:min-h-0 m-0 md:m-10">
      {
        chats &&
        <>
          {
            width < 912 && !showChat ?
              <ChatSidebar
                handleShowChat={() => setShowChat(!showChat)}
                chats={chats}
              />
              : width >= 912 &&
              <ChatSidebar
                handleShowChat={() => setShowChat(!showChat)}
                chats={chats}
              />
          }

          {
            width < 912 && showChat ?
              <ChatBody
                me={client.client_uuid}
                messages={messages}
                chat={chat}
                handleOnSubmit={handleMessageSend}
                sendingMessage={sendingMessage}
                // removeNavHeader={width < 769}
              />
              : width >= 912 &&
              <ChatBody
                me={client.client_uuid}
                messages={messages}
                chat={chat}
                handleOnSubmit={handleMessageSend}
                sendingMessage={sendingMessage}
                // removeNavHeader={false}
              />
          }
        </>
      }
    </div>
  );
};

export default PageClientChat;
