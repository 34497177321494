import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

import authHostReducer from "../features/hostAuth/authHostSlice";
import hostReducer from "../features/host/hostSlice";
import propertyReducer from "../features/property/propertySlice";
import calendarReducer from "../features/calendar/calendarSlice";
import bookingsReducer from "../features/bookings/bookingsSlice";
import expericeReducer from "../features/experience/experienceSlice";
import clientReducer from "../features/client/clientSlice";
import clientAuthtReducer from "../features/clientAuth/authClientSlice";
import chatReducer from "../features/chat/chatSlice";
import searchReducer from "../features/search/searchSlice";
import borghiReducer from "../features/borghi/borghiSlice";
import reviewReducer from "../features/review/reviewSlice";


import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const reducer = combineReducers({
  authclient: clientAuthtReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    hostAuth: authHostReducer,
    host: hostReducer,
    property: propertyReducer,
    events: calendarReducer,
    bookings: bookingsReducer,
    experience: expericeReducer,
    client: clientReducer,
    chat: chatReducer,
    search: searchReducer,
    borghi: borghiReducer,
    review: reviewReducer,
    persistedReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(apiSlice.middleware),
  devTools: true
});


