import React, { FC, useRef, useState, useEffect } from "react";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

export interface HeaderFilterProps {
  tabActive: string;
  tabs: string[];
  heading: ReactNode;
  subHeading?: ReactNode;
  showViewAll?: boolean;
  onClickTab: (item: string) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  showViewAll,
  subHeading = "",
  heading = "🎈 Latest Articles",
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleClickTab = (item: string) => {
    onClickTab && onClickTab(item);
    setTabActiveState(item);
  };

  const scroll = (direction: "left" | "right") => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -container.offsetWidth : container.offsetWidth;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <div className="flex items-center justify-between relative">
        <button
          onClick={() => scroll("left")}
          className="absolute left-0 p-2 rounded-full bg-white shadow-md flex-shrink-0 z-10 hover:bg-gray-100"
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <ChevronLeftIcon className="w-5 h-5" />
        </button>
        <div className="flex-grow overflow-hidden mx-8">
          <Nav
          ref={scrollContainerRef}
            className="flex-nowrap inline-flex"
            containerClassName="flex-nowrap inline-flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
          >
            {tabs.map((item, index) => (
              <NavItem
                key={index}
                isActive={tabActiveState === item}
                onClick={() => handleClickTab(item)}
              >
                {item}
              </NavItem>
            ))}
          </Nav>
        </div>
        <button
          onClick={() => scroll("right")}
          className="absolute right-0 p-2 rounded-full bg-white shadow-md flex-shrink-0 z-10 hover:bg-gray-100"
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <ChevronRightIcon className="w-5 h-5" />
        </button>
        {showViewAll && (
          <span className="hidden sm:block flex-shrink-0 ml-4">
            <ButtonSecondary className="!leading-none">
              <span>Vedi tutti</span>
              <i className="ml-3 las la-arrow-right text-xl"></i>
            </ButtonSecondary>
          </span>
        )}
      </div>
    </div>
  );
};

export default HeaderFilter;
