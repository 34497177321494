import React, { useEffect, useMemo, useState } from "react";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StayDatesRangeInput from "containers/ListingDetailPage/listing-stay-detail/StayDatesRangeInput";
import { Popover } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import GuestsInput from "containers/ListingDetailPage/listing-stay-detail/GuestsInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import RangeSelector from "containers/ListingDetailPage/listing-stay-detail/RangeSelector";

export interface MobileFooterStickyProps {
  startDate: Date;
  endDate: Date;
  maxGuests: number;
  onMonthChange: (date: Date) => void;
  onChangeDate: (dates: [Date | null, Date | null]) => void;
  onChangeGuests: (guests: GuestsObject) => void;
  guests: GuestsObject;
  showDatePicker: boolean,
  setShowDatePicker: (value: boolean) => void;
  rangeAvailability: any;
}

const InputBar = ({ startDate, endDate, onChangeDate, onChangeGuests, guests, onMonthChange, showDatePicker, setShowDatePicker, rangeAvailability }: MobileFooterStickyProps) => {
  return (
    <>
      <RangeSelector  />
      <StayDatesRangeInput
        onMonthChange={onMonthChange}
        startDateParam={startDate}
        endDateParam={endDate}
        showButtonInside
        onChange={onChangeDate}
        centerPicker
        shadow={false}
        className=""
        show={showDatePicker}
        setShow={setShowDatePicker}
        rangeAvailability={rangeAvailability}
      />
      <GuestsInput
        onChange={onChangeGuests}
        showButtonInside
        shadow={false}
        className=""
        guests={guests}
      />
    </>
  );
};

export default InputBar;
