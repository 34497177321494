import React, { useState } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface MobileFooterStickyProps {
  price: number | string;
  reviews?: number;
  rating?: string;
  startDate?: Date;
  isAvailable: boolean;
  showDateModal: boolean;
  endDate?: Date;
  onChangeDate?: (dates: [Date | null, Date | null]) => void;
  handleStartBooking: () => void;
  rangeAvailability?: any;
}


const MobileFooterSticky = ({ price, reviews, startDate, endDate, onChangeDate, handleStartBooking, isAvailable, rangeAvailability, showDateModal }: MobileFooterStickyProps) => {
  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="flex flex-col">
          <span className="block text-xl font-semibold">
            €{(Number(price) + (Number(price) * 0.15) + (Number(price) * 0.15 * 0.22)).toFixed(2)}
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              /notte
            </span>
          </span>
          {
            showDateModal && startDate && endDate && (
              <ModalSelectDate
                rangeAvailability={rangeAvailability}
                onChangeDate={(dates) => onChangeDate && onChangeDate(dates)}
                startDateParam={startDate}
                endDateParam={endDate}
                renderChildren={({ openModal }) => (
                  <span
                    onClick={openModal}
                    className="block text-sm underline font-medium"
                  >
                    {converSelectedDateToString([startDate, endDate])}
                  </span>
                )}
              />
            )
          }
        </div>

        <ModalReserveMobile
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              disabled={!isAvailable}
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={() => handleStartBooking()}
            >
              Prenota
            </ButtonPrimary>
          )}
        />
      </div>
    </div>
  );
};

export default MobileFooterSticky;
