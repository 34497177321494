import { apiSlice } from "../../app/api/apiSlice";

export const propertySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProperty: builder.query({
      query: (id) => ({
        url: `/property/single/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        property: {
          ...response.property,
        },
        host: {
          ...response.property.host,
        }
      }),
      providesTags: ["property", "EditingProperty"],
    }),
    getPropertyStats: builder.query({
      query: (id) => ({
        url: `/property/single/stats/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        ...response.stats,
      }),
      providesTags: ["property", "EditingProperty"],
    }),
    updateproperty: builder.mutation({
      query: (property) => ({
        url: "/property/update",
        method: "PUT",
        body: property,
      }),
      invalidatesTags: ["property"],
    }),
    createProperty: builder.mutation({
      query: (property) => ({
        url: "/property/create",
        method: "PUT",
        body: property,
      }),
      invalidatesTags: ["property"],
    }),
    uploadPropertyCover: builder.mutation({
      query: (formData) => ({
        url: "/property/upload/cover",
        method: "POST",
        body: formData,
      }),
    }),
    uploadPropertyAlbumImage: builder.mutation({
      query: (formData) => ({
        url: "/property/upload/album-image",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["EditingProperty"],
    }),
    getAllProperties: builder.query({
      query: () => ({
        url: "/property/all",
        method: "GET",
      }),
    }),
    editProperty: builder.mutation({
      query: (property) => ({
        url: "/property/edit",
        method: "PUT",
        body: property,
      }),
      invalidatesTags: ["EditingProperty"],
    }),
    getAvailablePropertiesByDate: builder.query({
      query: (query) => ({
        url: "/property/search",
        method: "POST",
        body: {
          check_in: query.check_in,
          check_out: query.check_out,
          guests: query.guests,
          mood: query.mood,
          latitude: query.latitude,
          longitude: query.longitude,
          region: query.region,
          state: query.country,
          trend: query.trend,
        },
      }),
    }),
    getPropertyAvailabilityByDates: builder.query({
      query: (availability) => ({
        url: "/property/single/availability",
        method: "POST",
        body: availability,
      }),
    }),
    getPropertyAvailabilityByRange: builder.query({
      query: (availability) => ({
        url: "/property/single/availability/range",
        method: "POST",
        body: availability,
      }),
    }),
    stopProperty: builder.query({
      query: (property_uuid) => ({
        url: "/property/stop",
        method: "POST",
        body: property_uuid,
      }),
    }),
    getAmenitiesListing: builder.query({
      query: () => ({
        url: "/amenity/all",
        method: "GET",
      }),
    }),
    getPropertiesByHost: builder.query({
      query: (host_uuid) => ({
        url: `/property/host/${host_uuid}`,
        method: "GET",
      }),
    }),
    getAveragePrice: builder.query({
      query: (city) => ({
        url: `/property/averagePrice/${city}`,
        method: "GET",
      }),
    }),
    getHighlights: builder.query({
      query: () => ({
        url: `/property/highlights/`,
        method: "GET",
      }),
    }),
  }),
});


export const {
  useGetPropertyQuery,
  useLazyGetPropertyQuery,
  useUpdatepropertyMutation,
  useCreatePropertyMutation,
  useUploadPropertyCoverMutation,
  useUploadPropertyAlbumImageMutation,
  useGetAllPropertiesQuery,
  useEditPropertyMutation,
  useGetAvailablePropertiesByDateQuery,
  useLazyGetAvailablePropertiesByDateQuery,
  useLazyGetPropertyStatsQuery,
  useGetPropertyStatsQuery,
  useGetAmenitiesListingQuery,
  useGetPropertyAvailabilityByDatesQuery,
  useLazyGetPropertyAvailabilityByDatesQuery,
  useLazyGetPropertyAvailabilityByRangeQuery,
  useLazyGetAllPropertiesQuery,
  useGetPropertiesByHostQuery,
  useLazyGetPropertiesByHostQuery,
  useLazyGetAveragePriceQuery,
  useLazyGetHighlightsQuery,
  useGetHighlightsQuery
}
  = propertySlice;