export const property_type = [
  {
    label: 'Appartamento',
    value: 'apartment',
    id: '4'
  },
  {
    label: 'Hotel',
    value: 'hotel',
    id: '1'
  },
  {
    label: 'Villa',
    value: 'villa',
    id: '3'
  },
  {
    label: 'Casa',
    value: 'home',
    id: '5'
  }
]
