import { Page } from "routers/types";
import { Route, Routes, useLocation, } from "react-router-dom";
import SiteHeader from "containers/SiteHeader";
import useWindowSize from "hooks/useWindowResize";
import FooterNav from "components/FooterNav";
import { useSelector } from "react-redux";
import { Client } from "types/Client";
import { selectCurrentclient } from "features/client/clientSlice";
import RequireClientAuth from "features/clientAuth/requireClientAuth";
import AddClientInfoPagesRouter from "Navigation/AddClientInfoPagesRouter";
import ClientPage from "containers/ClientPage/ClientPage";
import FooterNavClient from "components/FooterNavClient";
import CalendarStep from "containers/CheckoutSteps/CalendarStep";
import PaymentStep from "containers/CheckoutSteps/PaymentStep";
import SummaryStep from "containers/CheckoutSteps/SummaryStep";
import PayPage from "containers/PayPage/PayPage";
import MyReservationsClient from "containers/MyReservationsClient/MyReservationsClient";
import { selectCurrentLoggedClient } from "features/clientAuth/authClientSlice";
import PageClientChat from "containers/PageClientChat/PageClientChat";
import MyTripPage from "containers/MyTripPage/MyTripPage";
import PageCloseAccount from "containers/PageCloseAccount/PageCloseAccount";

const ClientPages: Page[] = [
  { path: "/", exact: true, component: ClientPage },
  { path: "/info/*", component: AddClientInfoPagesRouter },
  { path: "/booking/calendar", component: CalendarStep },
  { path: "/mytrip", component: MyTripPage },
  { path: "/booking/summary", component: SummaryStep },
  { path: "/booking/payment", component: PaymentStep },
  { path: "/booking/done/:session_id", component: PayPage },
  { path: "/bookings/", component: MyReservationsClient },
  { path: "/chat/", component: PageClientChat },
]


const ClientPagesRouter = () => {
  let WIN_WIDTH = useWindowSize().width;

  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const client: Client = useSelector(selectCurrentLoggedClient);

  const location = useLocation();

  const handleFooterMobile = () => {
    if (!location.pathname.endsWith("booking/calendar")) {
      return <FooterNavClient />
    }
    return null
  }

  return (
    <>
      <SiteHeader navType="ClientNav" />
      <Routes>
        <Route element={<RequireClientAuth />}>
          {ClientPages.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
        </Route>
      </Routes>
      {WIN_WIDTH < 768 ? !client?.client_uuid ? <FooterNav /> : handleFooterMobile() : null}
    </>

  )
}

export default ClientPagesRouter;