import React, { FC, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Label from "components/Label/Label";
import { useEditPropertyMutation, useGetPropertyQuery, useUpdatepropertyMutation } from "features/property/propertyApiSlice";
import { selectEditingproperty, setEditingroperty } from "features/property/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export interface EditListingPageSpaceProps {
  className?: string;
}

const EditListingSpace: FC<EditListingPageSpaceProps> = ({ className = "" }) => {
  const propertyId = useParams<{ id: string }>().id;

  const [updateProperty, { isLoading: updatingProperty }] = useEditPropertyMutation();
  const { data: property, isLoading, isError } = useGetPropertyQuery(propertyId);

  const editingProperty = useSelector(selectEditingproperty);

  const dispatch = useDispatch();

  const handleUpdatePropertyField = (field: string, value: any) => {
    dispatch(setEditingroperty({ ...editingProperty, [field]: value }))
  }

  const handleUpdateProperty = async () => {
    try {
      const toSend = { ...editingProperty }
      delete toSend.host

      await updateProperty(toSend).unwrap();
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (property) {
      dispatch(setEditingroperty(property.property))
    }
  }, [property]);

  useEffect(() => {
    return () => {
      dispatch(setEditingroperty(null));
    };
  }, []);


  if (isLoading || !editingProperty || !property) {
    <div>
      <div className={`nc-EditListingPage ${className}`} data-nc-id="EditListingPage">
        <CommonLayout propertyId={propertyId}>
          <div className="space-y-6 sm:space-y-8">
            {/* HEADING */}
            <h2 className="text-3xl font-semibold">Informazioni Spazio</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 è-"></div>
            <Skeleton className="w-32 h-32" count={3} />
          </div>
        </CommonLayout>
      </div>
    </div>
  }

  return (
    <div>
      <CommonLayout propertyId={propertyId}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Informazioni spazio</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex-grow mt-10 md:mt-0 max-w-3xl space-y-6">
            {/* ---- */}
            <div>
              <Label>Tipo di spazio</Label>
              <Select defaultValue={editingProperty?.rental_from} onChange={(e) => handleUpdatePropertyField("rental_from", e.target.value)} className="mt-1.5">
                <option value="entire_space">Intero immobile</option>
                <option value="private_room">Una stanza</option>
                <option value="share_room">Una stanza condivisa</option>
              </Select>
            </div>
            <div>
              <Label>Grandezza</Label>
              <Select onChange={(e) => handleUpdatePropertyField("square_meters", e.target.value)} className="mt-1.5">
                <option selected={editingProperty?.square_meters == "100"} value="100">100</option>
                <option selected={editingProperty?.square_meters == "200"} value="200">200</option>
                <option selected={editingProperty?.square_meters == "300"} value="300">300</option>
                <option selected={editingProperty?.square_meters == "400"} value="400">400</option>
                <option selected={editingProperty?.square_meters == "500"} value="500">500</option>
              </Select>
            </div>
            {/* ---- */}
            <div>
              <Label>Ospiti</Label>
              <Input defaultValue={editingProperty?.guests_included} onChange={(e) => handleUpdatePropertyField("guests_included", e.target.value)} className="mt-1.5" type="number" />
            </div>
            <div>
              <Label>Stanze</Label>
              <Input onChange={(e) => handleUpdatePropertyField("bedrooms", e.target.value)} className="mt-1.5" defaultValue={editingProperty?.bedrooms} type="number" />
            </div>
            <div>
              <Label>Letti</Label>
              <Input onChange={(e) => handleUpdatePropertyField("beds", e.target.value)} className="mt-1.5" defaultValue={editingProperty?.beds} type="number" />
            </div>
            <div>
              <Label>Bagni</Label>
              <Input onChange={(e) => handleUpdatePropertyField("bathrooms", e.target.value)} className="mt-1.5" defaultValue={editingProperty?.bathrooms} type="number" />
            </div>
            <div>
              <Label>Cucine</Label>
              <Input onChange={(e) => handleUpdatePropertyField("kitchen", e.target.value)} className="mt-1.5" defaultValue={editingProperty?.kitchen} type="number" />
            </div>
            <div className="pt-10">
              <ButtonPrimary onClick={handleUpdateProperty} loading={updatingProperty}>Aggiorna</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default EditListingSpace;
