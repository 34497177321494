import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
  name: "client",
  initialState: {
    client: null,
  },
  reducers: {
    setclient: (state, action) => {
      state.client = action.payload
    },
  },
});

export const { setclient } = clientSlice.actions;

export default clientSlice.reducer;

export const selectCurrentclient = (state) => state.client.client;
