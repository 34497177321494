import React, { useEffect, useState } from "react";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { DATA } from "./mockData";
import 'moment/locale/it';
import "./CalendarPage.css";
import SectionGridHasCalendar from "containers/ListingExperiencesPage/SectionGridHasCalendar";
import CheckoutForm from "components/CheckoutForm/CheckoutForm";
import { useSelector } from "react-redux";
import { selectBookings } from "features/bookings/bookingsSlice";
import { useStartBookingMutation } from "features/bookings/bookingApiSlice";


const CalendarPage = () => {
  return (
    <div className="nc-calendar-page w-fullself-center m-auto">
      <SectionGridHasCalendar  />
    </div >
  );
}
export default CalendarPage;
