import { HostContextProvider } from "contexts/HostContext";
import { Page } from "routers/types";
import { Route, Routes } from "react-router-dom";
import AddListingPagesRouter from "Navigation/AddListingPagesRouter";
import PageBecomeHost from "containers/PageBecomeHost/PageBecomeHost";
import PageLoginHost from "containers/PageLoginHost/PageLoginHost";
import AddHostInfoRouter from "Navigation/AddHostInfoPagesRouter";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import PageCreateStripeAccount from "containers/PageCreateStripeAccount/PageCreateStripeAccount";
import SiteHeader from "containers/SiteHeader";
import RequireHostAuth from "features/hostAuth/requireHostAuth";
import PageStripeLogin from "containers/PageStripeLogin/PageStripeLogin";
import useWindowSize from "hooks/useWindowResize";
import FooterNav from "components/FooterNav";
import FooterNavHost from "components/FooterNavHost";
import { useGetMeQuery } from "features/host/hostApiSlice";
import { useSelector } from "react-redux";
import { Host } from "types/Host";
import { selectCurrentHost } from "features/host/hostSlice";
import EditListingPage from "containers/EditListing/EditListingBase";
import EditListingSpace from "containers/EditListing/EditListingSpace";
import EditListingAmenities from "containers/EditListing/EditListingAmenities";
import PageMyListing from "containers/MyListing/PageMyListing";
import PageChooseListingType from "containers/PageChooseListingType/PageChooseListingType";
import AddExperiencePagesRouter from "Navigation/AddExperiencePagesRouter";
import EditExperiencePage from "containers/EditExperience/EditListingBase";
import EditingAvailabilities from "containers/EditExperience/EditingAvailabilities";
import PageHostChat from "containers/PageHostChat/PageHostChat";
import PageHostReservations from "containers/HostReservations/PageHostReservations";
import HostStatsPage from "containers/HostStatsPage/HostStatsPage";
import PageCloseAccount from "containers/PageCloseAccount/PageCloseAccount";

const HostPages: Page[] = [
  { path: "/info/*", component: AddHostInfoRouter },
  { path: "/bookings", component: PageHostReservations },
  { path: "/stats", component: HostStatsPage },
  { path: "/new-property/*", component: AddListingPagesRouter },
  { path: "/new-experience/*", component: AddExperiencePagesRouter },
  { path: "/new-listing/", component: PageChooseListingType },
  { path: "/edit-property/:id", component: EditListingPage },
  { path: "/edit-property/space/:id", component: EditListingSpace },
  { path: "/edit-property/amenities/:id", component: EditListingAmenities },
  { path: "/edit-experience/:id", component: EditExperiencePage },
  { path: "/edit-experience/availabilities/:id", component: EditingAvailabilities },
  { path: "/stripe/connect-account", component: PageCreateStripeAccount },
  { path: "/stripe/login", component: PageStripeLogin },
  { path: "/", component: AuthorPage },
  { path: "/chat", component: PageHostChat },
  { path: "/my-properties", component: PageMyListing },

]

const HostPagesRouter = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }
  const host: Host = useSelector(selectCurrentHost);

  return (
    <>
      <SiteHeader />
      <Routes>
        <Route element={<RequireHostAuth />}>
          {HostPages.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
        </Route>
      </Routes>
      {WIN_WIDTH < 768 ? !host?.host_uuid ? <FooterNav /> : <FooterNavHost /> : null}

    </>

  )
}

export default HostPagesRouter;