import React, { createContext, useState, useContext, useEffect } from 'react'
import { DefaultImagesList, Property, defaultProperty } from '../../types/Property'

type PageAddListingContextProviderProps = {
  children: React.ReactNode,
}
type PageAddListingContextType = {
  property: Property;
  propertyImages: Array<File>;
  updateProperty: (value: Property) => void;
  updateImageList: (value: Array<File>) => void;
};

const PageAddListingContext = createContext<PageAddListingContextType | undefined>(undefined);

export const usePageAddListingContext = () => {
  const context = useContext(PageAddListingContext);
  if (!context) {
    throw new Error('usePageAddListingContext must be used within a PageAddListingContextProvider');
  }
  return context;
};

export const PageAddListingContextProvider = ({ children }: PageAddListingContextProviderProps) => {
  const [property, setProperty] = useState<Property>(defaultProperty)
  const [propertyImages, setPropertyImages] = useState<Array<File>>(DefaultImagesList)

  const updateProperty = (value: Property) => {
    setProperty(value)
  }

  const updateImageList = (value: Array<File>) => {
    setPropertyImages(value)
  }

  useEffect(() => {
    console.log(property)
  }, [property])

  return (
    <PageAddListingContext.Provider value={{ property, propertyImages, updateProperty, updateImageList }}>
      {children}
    </PageAddListingContext.Provider>
  )
}
