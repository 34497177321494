import React, { FC, ReactNode, useEffect } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings,
  gridClass = "",
  heading = "Destinazioni in evidenza",
  subHeading = "Alcune destinazioni selezionati per te dal nostro team e dai nostri viaggiatori",
  headingIsCenter,
  tabs = ["Borgo 1", "Borgo 2", "Borgo 3", "Borgo 4"],
}) => {

  const [activeTab, setActiveTab] = React.useState(tabs[0]);

  const renderCard = (stay: any) => {
    return <StayCard key={stay.property_uuid} data={stay} />;
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  }

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={activeTab}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={(tab) => handleTabClick(tab)}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {
          stayListings && stayListings.length > 0 &&
          stayListings.filter((stay) => stay.borgo_name === activeTab).map(renderCard)
        }
      </div>
      {/* 
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Scopri di più</ButtonPrimary>
      </div>
      */}
    </div>
  );
};

export default SectionGridFeaturePlaces;
