import { createSlice } from "@reduxjs/toolkit";

const propertySlice = createSlice({
  name: "property",
  initialState: {
    property: null,
    editingProperty: null,
    propertyList: null,
    availability: null,
  },
  reducers: {
    setproperty: (state, action) => {
      state.property = action.payload
    },
    setpropertyList: (state, action) => {
      state.propertyList = action.payload
    },
    setEditingroperty: (state, action) => {
      state.editingproperty = action.payload
    },
    setAvailability: (state, action) => {
      state.availability = action.payload
    },
  },
});

export const { setproperty, setpropertyList, setEditingroperty, setAvailability } = propertySlice.actions;

export default propertySlice.reducer;

export const selectCurrentproperty = (state) => state.property.property;
export const selectpropertyList = (state) => state.property.propertyList;
export const selectEditingproperty = (state) => state.property.editingproperty;
export const selectAvailability = (state) => state.property.availability;

