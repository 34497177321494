import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { BuildingOfficeIcon, UserCircleIcon, UserIcon } from "@heroicons/react/24/outline";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continua come host",
    href: "#",
    icon: <UserIcon />,
  },
  {
    name: "Continua come cliente",
    href: "#",
    icon: <UserIcon />,
  },

];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || TRIBYOU</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Come vuoi proseguire?
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="flex flex-row gap-3">
            <a
              href="/#/login-host"
              className="nc-will-change-transform min-h-[10rem] flex flex-col justify-center items-center w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-6 transform transition-transform sm:px-6 hover:translate-y-[-2px] hover:opacity-90"
            >
              <div className="flex-shrink-0 mr-3"><BuildingOfficeIcon height={25} width={25} /></div>
              <h3 className="flex-grow text-center text-sm font-bold text-neutral-700 dark:text-neutral-300 sm:text-sm">
                Continua come Host e/o fornitore di esperienze e servizi
              </h3>

              <span className="text-xs mt-2 text-stone-700">
                Registrati se sei una struttura alberghiera o extra-alberghiera, se sei una DMC o DMO, un Tour Operator, un'attività di riistorazione, un co-working, un fornitore di esperienze o servizi sui territori, una guida turistica, un'organizzazione del territorio.
              </span>
            </a>

            <a
              href="/#/login-client"
              className="nc-will-change-transform min-h-[10rem] flex flex-col justify-center items-center w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-6 transform transition-transform sm:px-6 hover:translate-y-[-2px] hover:opacity-90"
            >
              <div className="flex-shrink-0 mr-3"><UserCircleIcon height={25} width={25} /></div>
              <h3 className="flex-grow text-center text-sm font-bold text-neutral-700 dark:text-neutral-300 sm:text-sm">
                Continua come cliente
              </h3>

              <span className="text-xs mt-2 text-stone-700">
                Prosegui come cliente per esplorare una vasta gamma di opzioni di soggiorno e attività. Che tu stia pianificando una vacanza, un viaggio d'affari o un'esperienza unica, qui troverai una selezione di alloggi, attività e servizi su misura per le tue esigenze.
              </span>
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PageLogin;
