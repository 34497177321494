import { setChat } from 'features/chat/chatSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import Avatar from 'shared/Avatar/Avatar';

interface ChatPreviewItemProps {
  chat_uuid: string;
  previewText: string;
  unreadCount?: number | 0;
  host_name: string;
  host_avatar: string;
  host_uuid: string;
  onClick: () => void;
  handleShowChat: () => void;
}

const ChatPreviewItem: React.FC<ChatPreviewItemProps> = ({
  chat_uuid,
  host_name,
  host_avatar,
  host_uuid,
  previewText,
  unreadCount,
  onClick,
  handleShowChat
}) => {
  const dispatch = useDispatch();

  const handleChatClick = () => {
    handleShowChat();
    dispatch(setChat({ chat_uuid, host_name, host_avatar, host_uuid }));
  }

  return (
    <div
      className="chat-preview-item flex flex-row w-full border-b-neutral-200 border-b-[1px] gap-4 py-4 px-6 items-center cursor-pointer hover:opacity-60"
      onClick={handleChatClick}>
      <Avatar
        sizeClass='h-11 w-11'
        imgUrl={host_avatar}
      />
      <div className='flex flex-col'>
        <div className="chat-preview-item__chat-id font-bold text-md">{host_name}</div>
        <div className="chat-preview-item__preview-text text-sm">{previewText ? previewText : "Hai prenotato da questo host"}</div>
      </div>
      {unreadCount && unreadCount > 0 && (
        <div className="chat-preview-item__unread-count">{unreadCount}</div>
      )}
      <div className="chat-preview-item__time text-sm">12:00</div>
    </div>
  );
};

export default ChatPreviewItem;
