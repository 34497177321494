import { PageAddListingContextProvider } from "contexts/PageAddListingContext";
import { Page } from "routers/types";
import { Route, Routes } from "react-router-dom";
import PageAddExperience1 from "containers/PageAddExperience/PageAddExperience";
import PageAddExperience2 from "containers/PageAddExperience/PageAddExperience2";
import PageAddExperience3 from "containers/PageAddExperience/PageAddExperience3";
import PageAddExperience4 from "containers/PageAddExperience/PageAddExperience4";
import PageAddExperience5 from "containers/PageAddExperience/PageAddExperience5";

const AddExperiencePages: Page[] = [
  { path: "/add-listing-1", component: PageAddExperience1 },
  { path: "/add-listing-2", component: PageAddExperience2 },
  { path: "/add-listing-3", component: PageAddExperience3 },
  { path: "/add-listing-4", component: PageAddExperience4 },
  { path: "/add-listing-5", component: PageAddExperience5 },

]

const AddExperiencePagesRouter = () => {
  return (
    <PageAddListingContextProvider>
      <Routes>
        {AddExperiencePages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
      </Routes>
    </PageAddListingContextProvider>

  )
}

export default AddExperiencePagesRouter;