import { createSlice } from "@reduxjs/toolkit";

const bookingsSlice = createSlice({
  name: "bookings",
  initialState: {
    bookings: [
    ],
    range: 3
  },
  reducers: {
    setBooking: (state, action) => {
      state.bookings.push(action.payload);
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
    setRange: (state, action) => {
      state.range = action.payload;
    }
  },
});

export const { setBooking, setBookings, setRange } = bookingsSlice.actions;

export default bookingsSlice.reducer;

export const selectBookings = (state) => state.bookings.bookings;
export const selectBookingByPropertyUUID = (state, propertyUUID) => {
  return state.bookings.bookings.filter((booking) => booking.property_uuid === propertyUUID);
}
export const selectRange = (state) => state.bookings.range;
