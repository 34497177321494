import React, { FC } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

export interface CheckoutFormProps {
  className?: string;
  clientSecret: string;
  bookings: any;
}
const stripePromise = loadStripe('pk_live_51JxIDXBv3K4XoK72El710WtzNOa5OF45GkJIA7IhMJR5yNvhuHv1jMq6lefI2GVBCqLNGRcVDdYA5Q3Pd2SJEqox00YnRIh9jE');

const CheckoutForm: FC<CheckoutFormProps> = ({
  clientSecret,
  bookings
}) => {
  const options = { clientSecret };
  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={options}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>

  );
};

export default CheckoutForm;
