
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/clientAuth/authClientSlice";
import { useLoginClientMutation, useRegisterClientMutation, useResetPswClientMutation } from "features/clientAuth/authClientApiSlice";
import { useResetPswHostMutation } from "features/hostAuth/authHostApiSlice";

export interface PageSignUpClientProps {
  className?: string;
  onLogin?: () => void;
}

const PageHostResetPsw: FC<PageSignUpClientProps> = ({ className = "", onLogin }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const [resetPsw, { isLoading: isLoading, isSuccess }] = useResetPswHostMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleResetPsw = async (e: any) => {
    e.preventDefault();
    try {
      const resetPswResponse = await resetPsw({ email }).unwrap()
    } catch (error: any) {
      setError("Si è verificato un errore, riprova più tardi")
    }
  }


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Recupera passowrd | TRIBYOU</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}

          {!isSuccess ?

            <>
              <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Recupera password
              </h2>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleResetPsw} method="post">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Indirzzo email
                  </span>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                  />
                </label>
                {error && <span className="text-red-500">{error}</span>}
                <ButtonPrimary type="submit" loading={isLoading}>Recupera password</ButtonPrimary>
              </form>

            </>
            :
            <>
              <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Abbiamo inviato un link per resettare la password
              </h2>
              <p className="mt-2 text-neutral-800">
                Se la tua email è già registrata su tribyou, riceverai un link per resettare la password a
                <span className="font-semibold"> {email}</span>
              </p>
            </>
          }

        </div>
      </div>
    </div>
  );
};

export default PageHostResetPsw;
