import React, { FC, useEffect } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link, useNavigate } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import moment from "moment";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AddToCalendarButton } from 'add-to-calendar-button-react';

export interface ExperienceCardProps {
  className?: string;
  data?: any;
  size?: "default" | "small";
  customData?: any;
  href?: string;
  showRefund?: boolean;
  onRefundClick?: () => void;
  onReviewClick?: () => void;
  showReview?: boolean;
  navigationIsNotAlowed?: boolean;
  myReservations?: boolean;
  score?: number;
  bookingPrice?: number;
  check_in?: string;
  check_out?: string;
  showCanceled?: boolean;
  showEdit?: boolean;
  showAddToCalendar?: boolean;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const ExperienceCard: FC<ExperienceCardProps> = ({
  size = "default",
  className = "",
  data,
  href,
  showRefund,
  showReview,
  onRefundClick,
  onReviewClick,
  showCanceled,
  myReservations,
  bookingPrice,
  score,
  check_in,
  check_out,
  showEdit,
  showAddToCalendar
}) => {
  const navigate = useNavigate()
  const {
    experience_uuid,
    name,
    address,
    max_guests,
    minutes_duration,
    price,
    cover,
    media,
    loading,
    reviews,
    rating,
    like = false,
  } = data || DEMO_DATA;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${experience_uuid}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={media && media.length ? media : [cover]}
          href={href}
        />
        {/*saleOff && <SaleOffBadge className="absolute left-3 top-3" />*/}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            Per {max_guests} persone • {minutes_duration} minuti
          </span>
          <div className="flex items-center space-x-2">
            {/*isAds && <Badge name="ADS" color="green" />*/}
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                }`}
            >
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          {
            !myReservations &&
            <span className="text-base font-semibold">
              da {data?.price}
              {` `}
              {size === "default" && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  /per persona
                </span>
              )}
            </span>
          }
          {
            myReservations &&
            <div className="text-base font-semibold flex flex-col">
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                <b>Pagato</b> {bookingPrice}€
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                Dal {moment(check_in).format("DD MMM")} al {moment(check_out).format("DD MMM")}
              </span>
            </div>
          }
          {!!rating && (
            <StartRating reviewCount={reviews} point={rating} />
          )}
        </div>
        {
          showRefund && (
            <div className="flex items-center justify-between">
              <ButtonSecondary
                onClick={onRefundClick}
              >
                Annulla prenotazione
              </ButtonSecondary>
              {showAddToCalendar && (
                <div className="absolute z-[99] right-2">
                  <AddToCalendarButton
                    name={data?.name}
                    startDate={moment(check_in).format("YYYY-MM-DD")}
                    options={['Apple', 'Google', 'MicrosoftTeams']}
                    timeZone="Europe/Rome"
                    buttonStyle="round"
                    hideTextLabelButton
                    size="5"
                    listStyle="overlay"
                    language="it"
                    buttonsList
                  ></AddToCalendarButton>
                </div>
              )}
            </div>
          )
        }
        {
          showEdit && (
            <div className="flex items-center justify-between">
              <ButtonPrimary
                onClick={() => navigate("/host/edit-experience/" + data?.experience_uuid)}
              >
                Modifica
              </ButtonPrimary>
            </div>
          )
        }
        {
          showReview && (

            score ?
              <div className="flex items-center justify-between">
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  La tua recensione:
                </span>
                <StartRating reviewCount={1} point={score} />
              </div>
              :
              <div className="flex items-center justify-between">
                <ButtonPrimary
                  onClick={onReviewClick}
                >
                  Scrivi una recensione
                </ButtonPrimary>
              </div>
          )
        }
        {
          showCanceled && (
            <div className="flex items-center justify-between">
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                Prenotazione annullata
              </span>
            </div>
          )
        }
      </div>
    );
  };

  return (
    <div
      className={`nc-ExperienceCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="ExperienceCard"
    >
      {renderSliderGallery()}
      <div onClick={() => { href && navigate("/host/edit-experience/" + experience_uuid) }}>{renderContent()}</div>
    </div >
  );
};

export default ExperienceCard;
