import { CustomLink } from "data/types";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";


export interface PaginationProps {
  className?: string;
  totalPages: number;
  currentPage: number;
  onPageChange?: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({ className = "", totalPages, currentPage, onPageChange }) => {
  const maxPage = 3;


  const renderItem = (pag: CustomLink, index: number) => {
    if (index === Number(currentPage) - 1 || Number(currentPage) > maxPage && index === maxPage - 1) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {Number(pag.label) < Number(currentPage) ? currentPage : pag.label}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <button
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        onClick={() => onPageChange && onPageChange(index + 1)}
      >
        {pag.label}
      </button>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {Array.from({ length: Math.min(totalPages, maxPage) }).map((_, index) => {
        const pag = {
          label: (index + 1).toString(),
          href: `#`,
        };
        return renderItem(pag, index);
      })}
      {totalPages > maxPage && Number(currentPage) < Number(totalPages) && (
        // show arrow next
        <button
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
          onClick={() => onPageChange && onPageChange(Number(currentPage) + 1)}
        >
          <svg
            className="w-4 h-4"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M9 5l7 7-7 7"></path>
          </svg>
        </button>
      )}
    </nav>
  );
};

export default Pagination;
