import React, { useState } from "react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Property } from "types/Property";
import { useContext } from 'react';
import { PageAddListingContextProvider } from '../../contexts/PageAddListingContext'
import { useNavigate } from "react-router-dom";

export interface CommonLayoutProps {
  index: string;
  nextHref: string;
  backtHref?: string;
  nextBtnText?: string;
  children: React.ReactNode;
  data?: Property
  canGoNext?: boolean
}

const CommonLayout: FC<CommonLayoutProps> = ({
  index = "01",
  children,
  nextHref,
  nextBtnText,
  backtHref,
  data,
  canGoNext
}) => {
  const navigate = useNavigate()

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 4
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">{children}</div>
        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          {
            backtHref && (
              <ButtonSecondary onClick={() => navigate(backtHref)}>Torna indietro</ButtonSecondary>
            )
          }
          <ButtonPrimary disabled={!canGoNext} onClick={() => navigate(nextHref)}>
            {nextBtnText || "Continua"}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
