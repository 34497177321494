import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { setSearchResults, setSearchType } from "features/search/searchSlice";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

type Type = {
  name: string;
  value: string;
}

interface TypeSelectorProps {
  onSelectType: (type: Type) => void;
  defaultType: number;
  className?: string;
}

const bookingTypes = [
  { name: "Esperienze e servizi", value: "experiences" },
  { name: "Alloggi", value: "properties" },
];

const TypeSelector: React.FC<TypeSelectorProps> = ({ onSelectType, className, defaultType }) => {
  const [selected, setSelected] = useState<Type>(bookingTypes[defaultType]);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelected(bookingTypes[defaultType]);
    dispatch(setSearchResults([]));
    dispatch(setSearchType(bookingTypes[defaultType].value));
  }, [defaultType]);

  useEffect(() => {
    dispatch(setSearchResults([]));
    dispatch(setSearchType(selected.value));
  }, [selected]);

  return (
    <div className={`${className} items-center flex justify-center`}>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <div className="flex flex-col">
              <span className="block truncate xl:text-lg font-semibold">{selected.name}</span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {"Tipo di ricerca"}
              </span>
            </div>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-5 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm ">
              {bookingTypes.map((type, typeIdx) => (
                <Listbox.Option
                  key={typeIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary-200 text-primary-6000' : 'text-gray-900'
                    }`
                  }
                  value={type}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                          }`}
                      >
                        {type.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-6000">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>

  );
};

export default TypeSelector;
