import { apiSlice } from "../../app/api/apiSlice";

export const chatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConversations: builder.query({
      query: () => ({
        url: "/chats/",
        method: "GET",
      }),
      providesTags: ["Chats"],
    }),
    sendMessage: builder.mutation({
      query: (details) => ({
        url: "/chats/message/send",
        method: "POST",
        body: {
          message: details.message,
          chat_uuid: details.chat_uuid,
        }
      }),
      invalidatesTags: ["Messages"],
    }),
    getAllMessages: builder.query({
      query: (chat_uuid) => ({
        url: `/chats/messages/${chat_uuid}`,
        method: "GET",
      }),
      providesTags: ["Messages"],
    }),
  })
});


export const {
  useGetConversationsQuery,
  useLazyGetConversationsQuery,
  useSendMessageMutation,
  useGetAllMessagesQuery,
  useLazyGetAllMessagesQuery
} = chatApiSlice;