import { 
  onLCP, onFID, onCLS, onFCP, onINP, onTTFB

 } from 'web-vitals';

const reportWebVitals = (onPerfEntry: any) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onCLS(console.log);
    onFID(console.log);
    onLCP(console.log);
    onFCP(console.log); 
    onINP(console.log); 
    onTTFB(console.log);

  }
  // if (onPerfEntry && onPerfEntry instanceof Function) {
  //   import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
  //     getCLS(onPerfEntry);
  //     getFID(onPerfEntry);
  //     getFCP(onPerfEntry);
  //     getLCP(onPerfEntry);
  //     getTTFB(onPerfEntry);
  //   });
  // }
};

export default reportWebVitals;
