
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { useLoginHostMutation, useRegisterHostMutation } from "../../features/hostAuth/authHostApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/hostAuth/authHostSlice";
import Checkbox from "shared/Checkbox/Checkbox";
import InputPsw from "shared/InputPsw/InputPsw";

export interface PageBecomeHostProps {
  className?: string;
}

const PageBecomeHost: FC<PageBecomeHostProps> = ({ className = "" }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPasword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [agree, setAgree] = useState(false);

  const [loginHost, { isLoading: isLoginLoading }] = useLoginHostMutation();
  const [registerHost, { isLoading: isRegisterLoading }] = useRegisterHostMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showOtp, setShowOtp] = useState(false);


  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      const loginReponse = await loginHost({ email, password, otp_code: otp }).unwrap()
      dispatch(setCredentials({ ...loginReponse }))
      if (!loginReponse.error) {
        navigate("/host/")
      }
    } catch (error: any) {
      if (!error?.data) {
        setError("Nessuna connessione al server")
      } else {
        setError("Email o password sbagliata")
      }
    }
  }

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        setError("Inserisci email e password")
        return
      }
      if (password !== confirmPasword) {
        setError("Le password non coincidono")
        return
      }
      setError('')
      const signUpHostResponse = await registerHost({ email, password }).unwrap()
      setShowOtp(true);
    } catch (error: any) {
      if (!error?.data) {
        setError("Nessuna connessione al server")
      } else if (error?.status === 412) {
        setError("Email già registrata")
      } else {
        setError("Errore nella registrazione")
      }
    }
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || TRIBYOU</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}

          {!showOtp ?

            <>
              <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Diventa un host
              </h2>
              <form className="grid grid-cols-1 gap-6" onSubmit={onFormSubmit} method="post">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Indirzzo email
                  </span>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                  </span>
                  <InputPsw
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Conferma password
                  </span>
                  <InputPsw
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                    className="mt-1"
                  />
                </label>
                <label className="flex flex-row items-center gap-3">
                  <Checkbox
                    className="mt-1"
                    name="agree"
                    isChecked={agree}
                    onChange={(e) => setAgree(e)}
                  />
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Acconsento a ricevere email di verifica e comunicazioni correlate.
                  </span>
                </label>
                {error && <span className="text-red-500">{error}</span>}
                <ButtonPrimary
                  type="submit"
                  loading={isRegisterLoading}
                  disabled={!agree}
                >
                  Continua
                </ButtonPrimary>
              </form>

              {/* ==== */}
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Sei già un host TRIBYOU? {` `}
                <Link to="/login">Accedi</Link>
              </span>
            </>
            :
            <>
              <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Verifica la tua email
              </h2>
              <p className="mt-2 text-neutral-800">
                Ti abbiamo inviato un codice di verifica all'indirizzo email
                <span className="font-semibold"> {email}</span>
              </p>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin} method="post">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Codice a 6 cifre
                  </span>
                  <Input
                    onChange={(e) => setOtp(e.target.value)}
                    type="text"
                    placeholder="123456"
                    className="mt-1"
                  />
                </label>
                {error && <span className="text-red-500">{error}</span>}
                <ButtonPrimary type="submit" loading={isLoginLoading}>Verifica</ButtonPrimary>
              </form>

              {/* ==== */}
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Non hai ricevuto il codice? {` `}
                <a className="text-red-500">Invia di nuovo</a>
              </span>
            </>
          }

        </div>
      </div>
    </div>
  );
};

export default PageBecomeHost;
