import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import AvatarDropdown from "./AvatarDropdown";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "types/Client";
import { selectCurrentclient, setclient } from "features/client/clientSlice";
import { useGetMeClientQuery } from "features/client/clientApiSlice";
import { selectCurrentLoggedClient, setLoggedClient } from "features/clientAuth/authClientSlice";
import { useLocation } from "react-router-dom";
import MyTrip from "./MyTrip";

export interface ClientNavProps {
  className?: string;
}

const ClientNav: FC<ClientNavProps> = ({ className = "" }) => {
  const { data, isLoading, isError } = useGetMeClientQuery({})
  const client: Client = useSelector(selectCurrentLoggedClient);

  const dispatch = useDispatch()
  useEffect(() => {
    if (!isLoading && data) {
      dispatch(setLoggedClient({ client: data }))
    }
  }, [isLoading])

  const location = useLocation();

  if (!location.pathname.endsWith("booking/calendar") && !location.pathname.endsWith("booking/summary") && !location.pathname.endsWith("booking/payment")) {
    return (
      <div className={`nc-ClientNav relative z-10 ${className}`}>
        <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
          <div className="hidden lg:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
            <Logo />
            <Navigation />
          </div>

          <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
            <HeroSearchForm2MobileFactory />
          </div>

          <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
            <div className="hidden xl:flex items-center space-x-0.5">

              <SearchDropdown />
              <div className="px-1" />
              <MyTrip /> 
              <div className="px-3" />
              {
                client ?
                  <AvatarDropdown isClient url={client.client_uuid ? client.avatar ? client.avatar : "https://www.hotelnicolaas.nl/images/joomlart/demo/default.jpg" : "https://www.hotelnicolaas.nl/images/joomlart/demo/default.jpg"} />
                  :
                  <ButtonPrimary href="/login">Accedi</ButtonPrimary>
              }
            </div>
            <div className="flex xl:hidden items-center">
              <SwitchDarkMode />
              <div className="px-0.5" />
              <MenuBar />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="p-5">
        <Logo />
      </div>
    )
  }
};

export default ClientNav;
