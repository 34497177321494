import React, { FC, useEffect, useState } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import CommonLayout from "./CommonLayout";
import { usePageAddListingContext } from "contexts/PageAddListingContext";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentproperty, setproperty } from "features/property/propertySlice";
import { useGetAmenitiesListingQuery } from "features/property/propertyApiSlice";

export interface PageAddListing4Props { }

const PageAddListing4: FC<PageAddListing4Props> = () => {
  const { data: amenitiesListing, isLoading } = useGetAmenitiesListingQuery({});

  const [amenities, setAmenities] = useState<string[]>([]);
  const property = useSelector(selectCurrentproperty)
  const dispatch = useDispatch()

  const handleUpdateProperty = (field: string, value: any) => {
    try {
      dispatch(setproperty({ ...property, [field]: value }))
    }
    catch (e) {
      console.log(e)
    }
  };

  const _handleUpdateAmenities = (label: string, enabled: boolean) => {
    if (enabled) {
      // check if label is already in the array
      if (!amenities.includes(label)) {
        setAmenities([...amenities, label])
      }
    }
    else {
      // remove label from array
      const updatedAmenities = amenities.filter((item) => item !== label)
      setAmenities(updatedAmenities)
    }
  }
  useEffect(() => {
    handleUpdateProperty("amenities", amenities);
  }, [amenities])

  return (
    <CommonLayout
      index="04"
      backtHref="/host/new-property/add-listing-3"
      nextHref="/host/new-property/add-listing-6"
      canGoNext
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Servizi </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Aggiungi i servizi che offri agli ospiti durante il loro soggiorno
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        {
          isLoading ? <div>Loading...</div> : (
            <div className="space-y-8">
              {/* ITEM */}
              {amenitiesListing.map((item: any) => (
                <div key={item.category}>
                  <label className="text-lg font-semibold" htmlFor="">
                    {item.category}
                  </label>
                  <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                    {item.amenities.map((amenity: any) => (
                      <Checkbox
                        key={amenity}
                        label={amenity}
                        name={amenity}
                        onChange={(e) => _handleUpdateAmenities(amenity, e)}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )
        }
      </>
    </CommonLayout>
  );
};

export default PageAddListing4;
