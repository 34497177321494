import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { selectCurrentHost, setHost } from "features/host/hostSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetMeQuery } from "features/host/hostApiSlice";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

export interface PageAddHostInfoProps { }

const PageAddHostInfo: FC<PageAddHostInfoProps> = () => {
  const { data: host, isLoading, isError } = useGetMeQuery({})
  const host_updated = useSelector(selectCurrentHost)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const handleUpdateHost = (field: string, value: any) => {
    dispatch(setHost({ ...host_updated, [field]: value }));
  };

  const _updateDefaultFields = async () => {
    dispatch(setHost({
      ...host_updated,
      is_private_host: true,
    }))
  }

  const onSubmit = async () => {
    navigate("/host/info/add-info-2")
  }

  useEffect(() => {
    if (host) {
      _updateDefaultFields()
    }
  }, [host])

  if (isLoading) {
    return <Skeleton className="h-44 rounded-md" />
  }
  return (
    <CommonLayout
      index="01"
      onNext={onSubmit}
      nextHref="/host/info/add-info-2"
      canGoNext={host_updated?.name ? true : false}
    >
      <>
        <h2 className="text-2xl font-semibold">I tuoi dati</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Nome visibile agli ospiti"
            desc="Inserisci il tuo nome e cognome se sei un privato, oppure il nome della tua azienda se sei un'azienda"
          >
            <Input
              defaultValue={host.name}
              onChange={(e) => handleUpdateHost("name", e.target.value)}
              placeholder="Nome"
            />
          </FormItem>
          <FormItem
            label="Numero di telefono visibile a tribyou"
            desc="Inserisci il tuo numero di telefono. Sarà visibile solo a tribyou in caso di necessità"
          >
            <Input
              defaultValue={host.phone}
              onChange={(e) => handleUpdateHost("phone", e.target.value)}
              placeholder="phone"
            />
          </FormItem>
          <FormItem
            label="Sono un"
            desc="Dichiarando che sei un'azienda o un privato, potremo adattare le funzioni della piattaforma in base ai tuoi bisogni operativi e fiscali"
          >
            <Select
              onChange={(e) => handleUpdateHost("is_private_host", e.target.value === "true")}
            >
              <option value="true">Privato</option>
              <option value="false">Azienda</option>
            </Select>
          </FormItem>
        </div>
      </>
    </CommonLayout >
  );
};

export default PageAddHostInfo;