import { selectAvailability } from "features/property/propertySlice";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";

interface Props {
  dayOfMonth: number;
  date?: Date | undefined;
  isAvailable?: boolean | true;
  rangeAvailability?: any;
}

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth }) => {
  return (<span className={`react-datepicker__day_span`}>{dayOfMonth}</span>);
};

export default DatePickerCustomDay;
