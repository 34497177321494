import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chat: null,
    messages: [],
    sendingMessage : false,
  },
  reducers: {
    setChat: (state, action) => {
      state.chat = action.payload
    },
    setMessages: (state, action) => {
      state.messages = action.payload
    },
    setSendingMessage( state, action ){
      console.log( "Sending message state from dispatch hello world this looks good", {state, action} );
      state.sendingMessage = action.payload;
    }
  },
});

export const { setChat, setMessages, setSendingMessage } = chatSlice.actions;

export default chatSlice.reducer;

export const selectCurrentChat = (state) => state.chat.chat;
export const selectMessages = (state) => state.chat.messages;
export const isSendingMessage = (state) => state.chat.sendingMessage;