import React, { FC, Fragment, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { selectCurrentclient, setclient } from "features/client/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useGetMeClientQuery } from "features/client/clientApiSlice";
import prefixs from "./prefix";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import Label from "components/Label/Label";

export interface PageAddClientInfoProps { }

const PageAddClientInfo: FC<PageAddClientInfoProps> = () => {
  const [selected, setSelected] = useState(prefixs.filter((prefix) => prefix.code === "IT")[0])
  const { data: client, isLoading, isError } = useGetMeClientQuery({})
  const client_updated = useSelector(selectCurrentclient)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const handleUpdateClient = (field: string, value: any) => {
    dispatch(setclient({ ...client_updated, [field]: value }));
  };

  const _updateDefaultFields = async () => {
    dispatch(setclient({
      ...client_updated,
    }))
  }

  const onSubmit = async () => {
    navigate("/client/info/add-info-2")
  }

  useEffect(() => {
    if (client) {
      _updateDefaultFields()
    }
  }, [client])

  if (isLoading) {
    return <Skeleton className="h-44 rounded-md" />
  }
  return (
    <CommonLayout
      index="01"
      onNext={onSubmit}
      nextHref="/client/info/add-info-2"
      canGoNext={client_updated?.name ? true : false}
    >
      <>
        <h2 className="text-2xl font-semibold">I tuoi dati</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Nome visibile pubblicamente"
            desc="Inserisci il tuo nome e cognome"
          >
            <Input
              defaultValue={client.name}
              onChange={(e) => handleUpdateClient("name", e.target.value)}
              placeholder="Nome"
            />
          </FormItem>
          <Label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Numero di telefono
          </Label>
          <div
            className="flex flex-row w-full space-x-4 items-center !mt-0"
          >
            <div className="relative">
              <Listbox value={selected} onChange={setSelected}>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded-xl bg-white py-3 pl-3 pr-10 text-left focus:outline-none 
                   focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 sm:text-sm border-neutral-200 border-[1px]">
                    <span className="block truncate">{selected.dial_code} ({selected.code})</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {prefixs.map((prefix, prefixIdx) => (
                        <Listbox.Option
                          key={prefixIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-2  ${active ? 'bg-primary-200 text-primary-6000' : 'text-gray-900'
                            }`
                          }
                          value={prefix}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                  }`}
                              >
                                {prefix.dial_code} ({prefix.code})
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 flex items-center pr-2 text-primary-6000 right-0">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>

            <Input
              defaultValue={client.phone}
              onChange={(e) => handleUpdateClient("phone", e.target.value)}
              placeholder="Cellulare"
            />
          </div>
        </div>
      </>
    </CommonLayout >
  );
};

export default PageAddClientInfo;