import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "searchSlice",
  initialState: {
    city: null,
    region: null,
    check_in: null,
    check_out: null,
    latitude: null,
    longitude: null,
    searchType: 0,
    guests: 2,
    mood: null,
    trend: null,
    searchResults: [],
  },
  reducers: {
    setSearchResults: (state, action) => {
      state.searchResults = action.payload
    },
    setCity: (state, action) => {
      state.city = action.payload
    },
    setRegion: (state, action) => {
      state.region = action.payload
    },
    setCheckIn: (state, action) => {
      state.check_in = action.payload
    },
    setCheckOut: (state, action) => {
      state.check_out = action.payload
    },
    setGuests: (state, action) => {
      state.guests = action.payload
    },
    setMood: (state, action) => {
      state.mood = action.payload
    },
    setLatitude: (state, action) => {
      state.latitude = action.payload
    },
    setLongitude: (state, action) => {
      state.longitude = action.payload
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload
    },
    setTrend: (state, action) => {
      state.trend = action.payload
    },
    setCountry: (state, action) => {
      state.country = action.payload
    }
  },
});

export const {
  setSearchResults,
  setCity,
  setRegion,
  setCheckIn,
  setCheckOut,
  setGuests,
  setMood,
  setLatitude,
  setLongitude,
  setSearchType,
  setTrend,
  setCountry
} = searchSlice.actions;

export default searchSlice.reducer;

export const selectSearchResults = (state) => state.search.searchResults;
export const selectCity = (state) => state.search.city;
export const selectRegion = (state) => state.search.region;
export const selectCheckIn = (state) => state.search.check_in;
export const selectCheckOut = (state) => state.search.check_out;
export const selectGuests = (state) => state.search.guests;
export const selectMood = (state) => state.search.mood;
export const selectLatitude = (state) => state.search.latitude;
export const selectLongitude = (state) => state.search.longitude;
export const selectSearchType = (state) => state.search.searchType;
export const selectTrend = (state) => state.search.trend;
export const selectCountry = (state) => state.search.country;
