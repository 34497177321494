import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import surfer from "images/shaka.png";
import business from "images/business-trip.png";
import casual from "images/sweatshirt.png";
import VectorImg from "images/VectorHIW.svg";
import SmartWalking from "images/smart-walking.png";
import Tourism from "images/turismo-radici.png";
import SmartHub from "images/hub-smart.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch } from "react-redux";
import { setMood, setTrend } from "features/search/searchSlice";
import useWindowSize from "hooks/useWindowResize";
import { useNavigate } from "react-router-dom";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const TRENDS: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: SmartWalking,
    title: "Smart Walking",
    desc: "se sei appassionato di camminate e trekking, ma hai bisogno di luoghi comodi anche per lavorare",
  },
  {
    id: 2,
    img: Tourism,
    title: "Turismo delle radici",
    desc: "Ricerchi i luoghi delle tue origini, vuoi scoprire le storie autentiche, respirare e assaporare profumi e sapori di un tempo passato",
  },
  {
    id: 3,
    img: SmartHub,
    title: "Smart hub",
    desc: "cerchi spazi flessibili e adattabili alle esigenze del tuo lavoro, che sia un team retreat, una riunione in presenza o in remoto",
  },
];

const SectionTrend: FC<SectionHowItWorkProps> = ({
  className = "",
  data = TRENDS,
}) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const handleNavigation = (item: any) => {
    dispatch(setTrend(item.title));
    if (width > 768) {
      {
        navigate(`1/search`)
      }
    }
  }

  return (
    <div
      className={`nc-SectionHowItWork  ${className} lg:!my-[5rem]`}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc="Lasciate ispirare dagli ultimi trend">
        Scopri i trend
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage
              containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"
              src={item.img}
            />
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
              <ButtonPrimary onClick={() => handleNavigation(item)}>Esplora</ButtonPrimary>
            </div>
          </div>
        ))}
      </div>
    </div >
  );
};

export default SectionTrend;
