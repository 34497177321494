import React, { FC } from "react";
import rightImgPng from "images/home_1.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import feature1 from "images/liberta.png";
import feature2 from "images/sicurezza.png";
import feature3 from "images/tribu.png";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  const [selected, setSelected] = React.useState(0);

  const returnImage = () => {
    switch (selected) {
      case 0:
        return feature1;
      case 1:
        return feature3;
      case 2:
        return feature2;
      default: return feature1;
    }
  }
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col-reverse items-center ${type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={returnImage()} className="w-full" />
      </div>
      <div
        className={`max-w-3xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/4 ${type === "type1" ? "lg:pl-14" : "lg:pr-16"
          }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest pl-4">
          Benefits
        </span>
        <h2 className="font-semibold text-4xl mt-5 pl-4">Unisciti a TRIBYOU </h2>

        <ul className="lg:mt-16 mt-8">
          <li className="space-y-4 hover:bg-neutral-100 rounded-lg p-4 cursor-pointer" onMouseEnter={() => setSelected(0)}>
            <Badge color="yellow" name="Libertà" />
            <span className="block text-xl font-semibold">
              La tua libertà
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              La libertà di viaggiare, lavorare e conoscere. Muoviti liberamente in Italia e all’estero. Scopri i servizi e le location della nostra piattaforma.
            </span>
          </li>
          <li className="space-y-4 hover:bg-neutral-100 rounded-lg p-4 cursor-pointer" onMouseEnter={() => setSelected(1)}>
            <Badge color="blue" name="Appartenenza" />
            <span className="block text-xl font-semibold">
              La tua tribù
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Appartenere a qualcosa, ad un gruppo di persone che si muove, lavora e soggiorna i luoghi bellissimi e con tutti i confort per lavorare in libertà.
            </span>
          </li>
          <li className="space-y-4 hover:bg-neutral-100 rounded-lg p-4 cursor-pointer" onMouseEnter={() => setSelected(2)}>
            <Badge color="green" name="Sicurezza" />
            <span className="block text-xl font-semibold">
              La tua sicurezza
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              La sicurezza di una rete di strutture e servizi sulla quale contare, per aiutarti ad organizzare i tuoi spostamenti ed il tuo lavoro al meglio.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
