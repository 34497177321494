import DatePicker from "react-datepicker";
import React, { FC, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useDispatch } from "react-redux";
import { selectCheckIn, selectCheckOut, setCheckIn, setCheckOut } from "features/search/searchSlice";
import { useSelector } from "react-redux";
import moment from "moment";


export interface StayDatesRangeInputProps {
  className?: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
}) => {
  const dispatch = useDispatch();

  const checkin = useSelector(selectCheckIn)
  const checkout = useSelector(selectCheckOut)

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    dispatch(setCheckIn(start));
    dispatch(setCheckOut(end));
  };
  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` Quando vuoi partire?`}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        <DatePicker
          onChange={onChangeDate}
          startDate={checkin}
          endDate={checkout}
          selectsRange
          monthsShown={1}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} isAvailable />
          )}
        />
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
