import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { DATA } from "./mockData";
import 'moment/locale/it';
import "./CalendarPage.css";
import ThreeDays from "./ThreeDays";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";
import { selectEvents, setEvents } from "features/calendar/calendarSlice";
import { useDispatch } from "react-redux";
import { Popover, Transition } from '@headlessui/react'
import { EllipsisHorizontalCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'
import { selectBookings } from "features/bookings/bookingsSlice";

moment.locale('it');
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const MyCustomToolbar = (toolbar) => {
  const [view, setView] = useState('three');

  const goToToday = () => {
    // Implementa la logica per andare a "Today"
    toolbar.onNavigate('TODAY');
  };

  const goToPrev = () => {
    // Implementa la logica per andare a "Back"
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    // Implementa la logica per andare a "Next"
    toolbar.onNavigate('NEXT');
  };

  const changeViewTo3Days = () => {
    // Cambia la visualizzazione a 3 giorni
    setView('three');
    toolbar.onView('three');
  };

  const changeViewToWeek = () => {
    // Cambia la visualizzazione a 7 giorni
    // if screen is less than 1468px
    if (window.innerWidth < 1920) {
      alert("La vista settimanale è disponibile solo su schermi con larghezza maggiore di 1920")
      return;
    }
    setView('week');
    toolbar.onView('week');
  };


  return (
    <div className="custom-toolbar flex flex-row w-full items-center justify-between mb-[2rem]">
      <div className="flex flex-col">
        <h2 className={`text-3xl md:text-3xl font-semibold`}>
          Calendario
        </h2>
        <div className="text-sm md:text-base text-gray-500">
          <span className="mr-2">Settimana del</span>
          <span>{moment(toolbar.date).format("DD MMMM YYYY")}</span>
        </div>
      </div>


      <div className="flex flex-row items-center justify-center">
        <ButtonSecondary className="mr-2" onClick={goToPrev}>
          <ArrowLeftIcon width={20} height={20} />
        </ButtonSecondary>
        <ButtonSecondary onClick={goToNext} className="mr-2">
          <ArrowRightIcon width={20} height={20} />
        </ButtonSecondary>
        <ButtonSecondary onClick={() => view === 'three' ? changeViewToWeek() : changeViewTo3Days()}>
          {
            view === 'three' ? 'Settimana' : '3 giorni'
          }
        </ButtonSecondary>

      </div>
    </div>
  );
};

const getDayProps = date => {
  return {
    backgroundColor: moment(date).day() === 0 || moment(date).day() === 6 ? 'red' : 'white', // Esempio di colore di sfondo diverso per sabato e domenica
  };
};

const MyCustomHeader = ({ date, label }) => {
  const bookins = useSelector(selectBookings);
  return (
    <div>
      <h2>{label}</h2>
      <div>
        {
          bookins?.map((booking, index) => {
            // if date is between start and end date
            if (moment(date).isBetween(booking.startDate, booking.endDate, undefined, '[]')) {
              return (
                <div key={index} className="custom-header-event">
                  <span> {booking.property.name.slice(0, 35)} {booking.property.name.length >= 35 && "..."}</span>
                </div>
              )
            }
          })
        }
      </div>
    </div>
  );
};
const EventComponent = ({ event }) => {
  const dispatch = useDispatch();
  const events = useSelector(selectEvents);

  const handleRemoveEvent = () => {
    const filteredEvents = events.filter(e => e?.experience_uuid !== event.experience_uuid);
    dispatch(setEvents(filteredEvents));
  }

  return (
    <div
      onClick={() => console.log(event)}
      style={{
      }}
    >
      {event.name}
      {
        event.experience_uuid &&
        <div onClick={handleRemoveEvent} className="absolute top-2 right-2">
          <XMarkIcon width={20} height={20} />
        </div>
      }
    </div>
  );
};

const CustomCalendar = ({ events, defaultDate, onNavigate, checkIfSlotsIsAvailable }) => {
  // get events from redux store
  const dispatch = useDispatch();

  const dayFormat = (date, culture, localizer) => localizer.format(date, 'D MMM', culture);

  const moveEvent = async ({ event, start, end }) => {
    const idx = events.indexOf(event);
    // get hours from start and end
    const startHours = moment().hours(start.getHours()).minutes(start.getMinutes())
    const endHours = moment().hours(end.getHours()).minutes(end.getMinutes())

    const validInterval = await isValidInterval(event, event.available_from, event.available_to, startHours, endHours, event.minutes_duration, event.start, event.end);
    // Verifica se il nuovo intervallo (start-end) è valido
    if (validInterval === false) {
      alert("Errore: l'evento non può essere spostato al di fuori degli intervalli disponibili");
      return;
    }

    const updatedEvent = { ...event, start, end };
    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    dispatch(setEvents(nextEvents));
  };

  const isValidInterval = async (experience, availableFrom, availableTo, startDate, endDate, minutesDuration, requestedCheckin, requestedCheckout) => {
    // Calcola la fine degli slot orari in base all'intervallo desiderato
    const slotDuration = moment.duration(minutesDuration, 'minutes');
    const availableStart = moment().hours(availableFrom).startOf('hour');
    const availableEnd = moment().hours(availableTo).startOf('hour');
    // Inizializza gli orari di inizio e fine dell'intervallo selezionato

    // Controlla che l'inizio e la fine dell'intervallo selezionato corrispondano agli inizi e alle fine degli slot orari desiderati
    if (!startDate.isSameOrAfter(availableStart) || !startDate.isSameOrBefore(availableEnd)) {
      return false;
    }

    // if startDate does not start at slotDuration or startDate is not at the start of the hour if slotDuration is 60
    if (startDate.minutes() % slotDuration.asMinutes() !== 0 || (slotDuration.asMinutes() === 60 && startDate.minutes() !== 0)) {
      return false;
    }

    const slots = [];
    let currentSlot = availableStart.clone();
    while (currentSlot.isSameOrBefore(availableEnd)) {
      slots.push(currentSlot.hours());
      currentSlot.add(slotDuration);
    }

    // Check if the selected start and end date are in the slots
    if (!slots.includes(startDate.hours()) || !slots.includes(endDate.hours())) {
      return false;
    }

    experience.requestedCheckin = requestedCheckin;
    experience.requestedCheckout = requestedCheckout;
    const checkAvailable = await checkIfSlotsIsAvailable(experience, startDate.hours(), endDate.hours());
    return checkAvailable.available;
  };

  if (!events) return null;
  return (
    <div className="nc-calendar-page">
      <div className="h-[auto] mx-auto px-10 shadow-lg rounded-lg mb-10 pb-10">
        {
          events &&
          <DnDCalendar
            defaultDate={defaultDate}
            onNavigate={date => onNavigate(date)}
            defaultView="three"  // Imposta la vista predefinita a 'day' (giorno)
            views={{
              day: true,
              week: true,
              three: ThreeDays
            }}
            events={events}
            resizable={false}
            style={{ height: "100vh" }}
            min={moment().startOf("day").add(6, "hours").toDate()}
            max={moment().startOf("day").add(21, "hours").toDate()}
            culture="it"
            formats={{
              dayFormat,
              timeGutterFormat: 'HH:mm', // Imposta il formato delle ore in 24
            }}
            onEventDrop={moveEvent}
            components={{
              toolbar: MyCustomToolbar, // Usa il tuo componente personalizzato per la toolbar
              event: EventComponent,
              header: MyCustomHeader
            }}
            dayPropGetter={getDayProps}
            localizer={localizer}
          />
        }

      </div>

    </div >
  );
}
export default CustomCalendar;
