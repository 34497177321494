import { apiSlice } from "../../app/api/apiSlice";

export const borghiApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBorgo: builder.query({
      query: (name) => ({
        url: "/borgo/" + name,
        method: "GET",
      }),
      providesTags: ["Borgo"],
      invalidatesTags: ["Borgo"],
    }),
    getBorgoSections: builder.query({
      query: (name) => ({
        url: "/borgo/sections/" + name,
        method: "GET",
      }),
    }),
    getAllBorghi: builder.query({
      query: (name) => ({
        url: "/borgo/list/all/",
        method: "GET",
      }),
    }),
    createBorgo: builder.mutation({
      query: (values) => ({
        url: "/borgo/create",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Borgo"],
    }),
    uploadBorgoCover: builder.mutation({
      query: (formData) => ({
        url: "/borgo/upload/cover",
        method: "POST",
        body: formData,
      }),
    }),
    getAmenities: builder.query({
      query: () => ({
        url: "/borgo/list/amenities",
        method: "GET",
      }),
    }),
    addAmenitiesToBorgo: builder.mutation({
      query: (values) => ({
        url: "/borgo/add/amenities",
        method: "POST",
        body: values,
      }),
    }),
  }),

});


export const {
  useGetBorgoQuery,
  useLazyGetBorgoQuery,
  useGetBorgoSectionsQuery,
  useLazyGetBorgoSectionsQuery,
  useGetAllBorghiQuery,
  useCreateBorgoMutation,
  useUploadBorgoCoverMutation,
  useGetAmenitiesQuery,
  useAddAmenitiesToBorgoMutation
} = borghiApiSlice;