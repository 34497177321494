import React, { FC, useEffect } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLazySearchPropertiesQuery } from "features/search/searchApiSlice";
import TypeSelector from "../TypeSelector";
import MoodSelector from "../MoodSelector";

interface StaySearchFormProps {
  handleSearch: () => void;
  defaultType: number;
}

const StaySearchForm: FC<StaySearchFormProps> = ({ handleSearch, defaultType }) => {

  const renderForm = () => {
    return (
      <form
        onSubmit={handleSearch}
        className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
        <LocationInput className="flex-[1.1]" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput className="flex-1" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <TypeSelector
          onSelectType={() => null}
          className="flex-[0.5]"
          defaultType={defaultType}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <MoodSelector
          onSelectType={() => null}
          className="flex-[0.7]"
          defaultType={defaultType}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput
          handleSubmit={handleSearch}
          className="flex-1"
        />
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
