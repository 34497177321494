import { createSlice } from "@reduxjs/toolkit";

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    events: []
  },
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload
    },
  },
});

export const { setEvents } = calendarSlice.actions;

export default calendarSlice.reducer;

export const selectEvents = (state) => state.events.events;

