import React, { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import convertNumbThousand from "utils/convertNumbThousand";
import Slider from "rc-slider";
import { useLazyFilterExperienceQuery } from "features/experience/experienceApiSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setExperiencesList } from "features/experience/experienceSlice";

// DEMO DATA
const typeOfExpriences = [
  {
    name: "Food & drink",
    description: "Esperienze culinarie e degustazioni",
    value: "food",
  },
  {
    name: "Arte e cultura",
    description: "Esperienze come musei, tour e altro",
    value: "art",
  },
  {
    name: "Escursione e natura",
    description: "Esperienze all'aperto e a contatto con la natura",
    value: "excursion",
  },
  {
    name: "Sport",
    description: "Divertimento all'aria aperta e attività sportive",
    value: "sport",
  },
];

const timeOfdays = [
  {
    name: "Mattina",
    description: "Inizio prima delle 12pm",
    value: "morning",
  },
  {
    name: "Pomeriggio",
    description: "Inizio 12pm",
    value: "afternoon",
  },
  {
    name: "Sera",
    description: "Inizio dopo 5pm",
    value: "evening",
  },
];

//
const moreFilter1 = typeOfExpriences;
const moreFilter2 = timeOfdays;

export interface TabFiltersProps {
  latitude?: string;
  longitude?: string;
  handleFilter?: (selectedType: any, latitude: any, longitude: any, radius: number, rangePrices: any, selectedMoment: any) => Promise<any>;
}

const TabFilters: FC<TabFiltersProps> = ({ latitude, longitude, handleFilter }) => {

  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  //
  const [isOnSale, setIsOnSale] = useState(true);
  const [rangePrices, setRangePrices] = useState([0, 1000]);
  const [radius, setRadius] = useState(25);

  //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const [selectedType, setSelectedType] = useState<String[]>([]);
  const [selectedMoment, setSelectedMoment] = useState<String>();

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabsTypeOfPlace = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`${selectedType.length && "bg-primary-50 border border-primary-500 text-primary-500  hover:border-primary-500"} flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${open ? "!border-primary-500 " : ""
                }`}
            >
              <span>Tipo di esperienza</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfExpriences.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                          isChecked={selectedType?.includes(item.value)}
                          onChange={() => {
                            if (selectedType?.includes(item.value)) {
                              setSelectedType(selectedType.filter((i) => i !== item.value))
                            } else {
                              setSelectedType([...selectedType, item.value])
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <Popover.Button>
                      <ButtonThird
                        onClick={handleFilter ? () => handleFilter(null, latitude, longitude, radius, rangePrices, selectedMoment).then(() => setSelectedType([]))
                          : () => { }}
                        sizeClass="px-4 py-2 sm:px-5">
                        Reset
                      </ButtonThird>
                    </Popover.Button>
                    <Popover.Button>
                      <ButtonPrimary
                        onClick={handleFilter ? () => handleFilter(selectedType, latitude, longitude, radius, rangePrices, selectedMoment) : () => { }}
                      >
                        Applica
                      </ButtonPrimary>
                    </Popover.Button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };
  const renderDistanceTab = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none`}
            >
              <span>
                Distanza massima
              </span>
              {renderXClear()}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Distanza massima</span>
                      <Slider
                        max={250}
                        defaultValue={radius}
                        allowCross={false}
                        onChange={(e) => setRadius(e as number)}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Distanza totale
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            type="text"
                            name="distance"
                            disabled
                            id="distance"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={radius + " KM"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <Popover.Button>
                      <ButtonThird
                        onClick={handleFilter ? () => handleFilter(selectedType, latitude, longitude, radius, rangePrices, selectedMoment).then(() => setRangePrices([0, 1000]))
                          : () => { }}
                        sizeClass="px-4 py-2 sm:px-5">
                        Reset
                      </ButtonThird>
                    </Popover.Button>
                    <Popover.Button>
                      <ButtonPrimary
                        sizeClass="px-4 py-2 sm:px-5"
                        onClick={(handleFilter ? () => handleFilter(selectedType, latitude, longitude, radius, rangePrices, selectedMoment) : () => { })}
                      >
                        Applica
                      </ButtonPrimary>
                    </Popover.Button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };


  const renderTabsTimeOfDay = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`${selectedMoment && "bg-primary-50 border border-primary-500 text-primary-500  hover:border-primary-500"} flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${open ? "!border-primary-500 " : ""
                }`}
            >
              <span>Momento del giorno</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900   border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {timeOfdays.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                          isChecked={selectedMoment?.includes(item.value)}
                          onChange={() => {
                            if (selectedMoment?.includes(item.value)) {
                              setSelectedMoment(undefined)
                            } else {
                              setSelectedMoment(item.value)
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <Popover.Button>
                      <ButtonThird
                        onClick={handleFilter ? () => handleFilter(selectedType, latitude, longitude, radius, rangePrices, null).then(() => setSelectedMoment(undefined))
                          : () => { }}
                        sizeClass="px-4 py-2 sm:px-5">
                        Reset
                      </ButtonThird>
                    </Popover.Button>

                    <Popover.Button>
                      <ButtonPrimary
                        onClick={handleFilter ? () => handleFilter(selectedType, latitude, longitude, radius, rangePrices, selectedMoment) :
                          () => { }}
                        sizeClass="px-4 py-2 sm:px-5"
                      >
                        Applica
                      </ButtonPrimary>
                    </Popover.Button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none`}
            >
              <span>
                {`€${convertNumbThousand(
                  rangePrices[0]
                )} - €${convertNumbThousand(rangePrices[1])}`}{" "}
              </span>
              {renderXClear()}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Prezzo complessivo</span>
                      <Slider
                        range
                        min={0}
                        max={2000}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={(e) => setRangePrices(e as number[])}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Prezzo min
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              €
                            </span>
                          </div>
                          <input
                            type="text"
                            name="minPrice"
                            disabled
                            id="minPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={rangePrices[0]}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Prezzo max
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              €
                            </span>
                          </div>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={rangePrices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <Popover.Button>
                      <ButtonThird
                        onClick={handleFilter ? () => handleFilter(selectedType, latitude, longitude, radius, rangePrices, selectedMoment).then(() => setRangePrices([0, 1000]))
                          : () => { }}
                        sizeClass="px-4 py-2 sm:px-5">
                        Reset
                      </ButtonThird>
                    </Popover.Button>
                    <Popover.Button>
                      <ButtonPrimary
                        sizeClass="px-4 py-2 sm:px-5"
                        onClick={handleFilter ? () => handleFilter(selectedType, latitude, longitude, radius, rangePrices, selectedMoment) : () => { }}
                      >
                        Applica
                      </ButtonPrimary>
                    </Popover.Button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabOnSale = () => {
    return (
      <div
        className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${isOnSale
          ? "border-primary-500 bg-primary-50 text-primary-700"
          : "border-neutral-300 dark:border-neutral-700"
          }`}
        onClick={() => setIsOnSale(!isOnSale)}
      >
        <span>On sale</span>
        {isOnSale && renderXClear()}
      </div>
    );
  };

  const renderMoreFilterItem = (
    data: {
      name: string;
      description?: string;
      defaultChecked?: boolean;
      value: string;
    }[]
  ) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid sm:grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
              isChecked={selectedType?.includes(item.value)}
              onChange={() => {
                if (selectedType?.includes(item.value)) {
                  setSelectedType(selectedType.filter((i) => i !== item.value))
                } else {
                  setSelectedType([...selectedType, item.value])
                }
              }}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
              isChecked={selectedType?.includes(item.value)}
              onChange={() => {
                if (selectedType?.includes(item.value)) {
                  setSelectedType(selectedType.filter((i) => i !== item.value))
                } else {
                  setSelectedType([...selectedType, item.value])
                }
              }}
            />
          ))}
        </div>
      </div>
    );
  };
  const renderMoreFilterItemMoment = (
    data: {
      name: string;
      description?: string;
      defaultChecked?: boolean;
      value: string;
    }[]) => {
    return (
      <div className="grid sm:grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {data.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={false}
              isChecked={selectedMoment?.includes(item.value)}
              onChange={() => {
                if (selectedMoment?.includes(item.value)) {
                  setSelectedMoment(undefined)
                } else {
                  setSelectedMoment(item.value)
                }
              }}
            />
          ))}
        </div>
      </div>
    );
  };
  const handleMobileFilterClose = () => {
    closeModalMoreFilter();

    if (handleFilter) {
      handleFilter(selectedType, latitude, longitude, radius, rangePrices, selectedMoment)
    }
  }

  const handleReset = () => {
    closeModalMoreFilter();
    setSelectedType([]);
    setSelectedMoment(undefined);
    setRangePrices([0, 1000]);

    if (handleFilter) {
      handleFilter(null, latitude, longitude, radius, null, null)
    }
  }

  const renderTabMobileFilter = () => {
    return (
      <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilter}
        >
          <span>
            Filtra esperienze
          </span>
          {renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={handleMobileFilterClose}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full mb-[45%]">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Filtra esperienze
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 sm:px-6 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h3 className="text-xl font-medium">
                          Tipo di esperienza
                        </h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(moreFilter1)}
                        </div>
                      </div>
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Momento del giorno</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItemMoment(moreFilter2)}
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Fascia di prezzo</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                className="text-red-400"
                                min={0}
                                max={2000}
                                defaultValue={[rangePrices[0], rangePrices[1]]}
                                allowCross={false}
                                onChange={(e) => setRangePrices(e as number[])}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Prezzo min
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      €
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    disabled
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[0]}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Prezzo max
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      €
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    disabled
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[1]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Distanza massima</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                className="text-red-400"
                                max={250}
                                defaultValue={radius}
                                allowCross={false}
                                onChange={(e) => setRadius(e as number)}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Distanza massima
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <input
                                    type="text"
                                    disabled
                                    name="radius"
                                    id="radius"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={radius + " KM"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-4 sm:p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between ">
                    <ButtonThird
                      onClick={handleReset}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset
                    </ButtonThird>

                    <ButtonPrimary
                      onClick={handleMobileFilterClose}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Applica
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      <div className="hidden lg:flex space-x-4">
        {renderTabsTypeOfPlace()}
        {renderDistanceTab()}
        {renderTabsPriceRage()}
        {renderTabsTimeOfDay()}
        {/*renderTabOnSale()*/}
      </div>
      <div className="flex lg:hidden space-x-4">
        {renderTabMobileFilter()}
        {/*renderTabOnSale()*/}
      </div>
    </div>
  );
};

export default TabFilters;
