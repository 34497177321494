import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import AvatarDropdown from "./AvatarDropdown";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentHost, setHost } from "features/host/hostSlice";
import { Host } from "types/Host";
import { useGetMeQuery } from "features/host/hostApiSlice";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { data, isLoading, isError } = useGetMeQuery({})
  const host: Host = useSelector(selectCurrentHost);

  const dispatch = useDispatch()
  useEffect(() => {
    if (!isLoading && data) {
      dispatch(setHost({ ...data }))
    }
  }, [isLoading])
  
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            {
              host &&
              <ButtonPrimary href="/host/new-listing/">Carica annuncio</ButtonPrimary>
            }
            <SearchDropdown />
            <div className="px-1" />
            {
              host ?
                <AvatarDropdown url={host.host_uuid ? host.avatar : ""} />
                :
                <ButtonPrimary href="/login">Accedi</ButtonPrimary>
            }
          </div>
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
