import { apiSlice } from "../../app/api/apiSlice";

export const bookingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startBooking: builder.mutation({
      query: ({ bookings, events }) => ({
        url: "/booking/start",
        method: "POST",
        body: {
          bookings,
          events
        },
      }),
      invalidatesTags: ["Booking"],
    }),
    getBookingOutcome: builder.query({
      query: (session_id) => ({
        url: `/booking/outcome/${session_id}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        outcome: response.outcome.outcome
      }),
      invalidatesTags: ["Booking"],
    }),
    checkExperienceAvailability: builder.query({
      query: ({ experience }) => ({
        url: "/booking/experience/availability",
        method: "POST",
        body: { experience }
      }),
    }),
    cancelBooking: builder.mutation({
      query: (booking) => ({
        url: "/booking/cancel",
        method: "POST",
        body: {
          booking,
        },
      }),
      invalidatesTags: ["Booking"],
    }),
    rejectBooking: builder.mutation({
      query: (booking) => ({
        url: "/booking/reject",
        method: "POST",
        body: {
          booking,
        },
      }),
      invalidatesTags: ["Booking"],
    }),
  })
});


export const {
  useStartBookingMutation,
  useGetBookingOutcomeQuery,
  useLazyCheckExperienceAvailabilityQuery,
  useCancelBookingMutation,
  useRejectBookingMutation
} = bookingApiSlice;