import { apiSlice } from "../../app/api/apiSlice";

export const experienceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createExperience: builder.mutation({
      query: (experience) => ({
        url: "/experience/create",
        method: "POST",
        body: experience,
      }),
      invalidatesTags: ["experience"],
    }),
    getExperience: builder.query({
      query: (id) => ({
        url: `/experience/single/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        experience: {
          ...response.experience,
        },
        host: {
          ...response.experience.host,
        }
      }),
      providesTags: ["experience", "EditingExperience"],
    }),
    uploadExperienceCover: builder.mutation({
      query: (formData) => ({
        url: "/experience/upload/cover",
        method: "POST",
        body: formData,
      }),
    }),
    uploadExperienceAlbumImage: builder.mutation({
      query: (formData) => ({
        url: "/experience/upload/album-image",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["experience"],
    }),
    editExperience: builder.mutation({
      query: (experience) => ({
        url: "/experience/edit",
        method: "PUT",
        body: experience,
      }),
      invalidatesTags: ["EditingExperience"],
    }),
    getExperiencesNearby: builder.query({
      query: (coords) => ({
        url: `/experience/nearby/${coords.latitude}/${coords.longitude}/${coords.radius}`,
        method: "GET",
      }),
      providesTags: ["ExperiencesList"],
    }),
    filterExperience: builder.query({
      query: (filter) => ({
        url: `/experience/filter/`,
        method: "POST",
        body: filter,
      }),
      invalidatesTags: ["ExperiencesList"],
    }),
    getExperienceTimeSlots: builder.query({
      query: (availability) => ({
        url: `/experience/timeslots/`,
        method: "POST",
        body: availability,
      }),
      providesTags: ["ExperiencesList"],
    }),
    getExperiencesByHostUUID: builder.query({
      query: (host_uuid) => ({
        url: `/experience/host/${host_uuid}`,
        method: "GET",
      }),
      providesTags: ["ExperiencesList"],
    }),
  }),
});


export const {
  useCreateExperienceMutation,
  useUploadExperienceCoverMutation,
  useUploadExperienceAlbumImageMutation,
  useLazyGetExperienceQuery,
  useGetExperienceQuery,
  useEditExperienceMutation,
  useGetExperiencesNearbyQuery,
  useLazyGetExperiencesNearbyQuery,
  useFilterExperienceQuery,
  useLazyFilterExperienceQuery,
  useGetExperienceTimeSlotsQuery,
  useLazyGetExperienceTimeSlotsQuery,
  useGetExperiencesByHostUUIDQuery
} = experienceApiSlice;