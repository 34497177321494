import Label from "components/Label/Label";
import React, { useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import Checkbox from "shared/Checkbox/Checkbox";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEditPropertyMutation, useGetPropertyQuery } from "features/property/propertyApiSlice";
import { selectEditingproperty, setEditingroperty } from "features/property/propertySlice";

const EditListingAmenities = () => {
  const propertyId = useParams<{ id: string }>().id;

  const [updateProperty, { isLoading: updatingProperty }] = useEditPropertyMutation();
  const { data: property, isLoading, isError } = useGetPropertyQuery(propertyId);

  const [amenities, setAmenities] = useState<string[]>([]);

  const editingProperty = useSelector(selectEditingproperty);

  const dispatch = useDispatch();


  const _handleUpdateAmenities = (label: string, enabled: boolean) => {
    if (enabled) {
      // check if label is already in the array
      if (!editingProperty.amenities.includes(label)) {
        dispatch(setEditingroperty({ ...editingProperty, amenities: [...editingProperty.amenities, label] }))
      }
    }
    else {
      // remove label from array
      const updatedAmenities = editingProperty.amenities.filter((item: any) => item !== label)
      dispatch(setEditingroperty({ ...editingProperty, amenities: updatedAmenities }))
    }
  }

  const handleUpdateProperty = async () => {
    try {
      const toSend = { ...editingProperty }
      delete toSend.host

      await updateProperty(toSend).unwrap();
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    if (property) {
      dispatch(setEditingroperty(property.property))
    }
  }, [property]);

  useEffect(() => {
    return () => {
      dispatch(setEditingroperty(null));
    };
  }, []);
  
  if (isLoading || !editingProperty || !property) {
    return null
  }
  return (
    <div>
      <CommonLayout propertyId={propertyId}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Informazioni servizi</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <label className="text-lg font-semibold" htmlFor="">
                Servizi base
              </label>
              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                <Checkbox
                  label="Wifi"
                  name="Wifi"
                  defaultChecked={editingProperty.amenities.includes("Wifi")}
                  onChange={(e) => _handleUpdateAmenities("Wifi", e)}
                />
                <Checkbox
                  label="TV"
                  name="TV"
                  defaultChecked={editingProperty.amenities.includes("TV")}
                  onChange={(e) => _handleUpdateAmenities("TV", e)}
                />
                <Checkbox
                  label="Aria condizionata"
                  name="aria condizionata"
                  defaultChecked={editingProperty.amenities.includes("aria condizionata")}
                  onChange={(e) => _handleUpdateAmenities("aria condizionata", e)}
                />
                <Checkbox
                  label="Ventilatore"
                  name="ventilatore"
                  defaultChecked={editingProperty.amenities.includes("ventilatore")}
                  onChange={(e) => _handleUpdateAmenities("ventilatore", e)}
                />
                <Checkbox
                  label="Asciugacapelli"
                  name="Asciugacapelli"
                  defaultChecked={editingProperty.amenities.includes("Asciugacapelli")}
                  onChange={(e) => _handleUpdateAmenities("Asciugacapelli", e)}
                />
                <Checkbox
                  label="Lavastoviglie"
                  name="lavastoviglie"
                  defaultChecked={editingProperty.amenities.includes("lavastoviglie")}
                  onChange={(e) => _handleUpdateAmenities("lavastoviglie", e)}
                />
                <Checkbox
                  label="Prodotti per la cura personale"
                  name="Prodotti per la cura personale"
                  defaultChecked={editingProperty.amenities.includes("Prodotti per la cura personale")}
                  onChange={(e) => _handleUpdateAmenities("Prodotti per la cura personale", e)}
                />
                <Checkbox
                  label="Asciugatore"
                  name="Asciugatore"
                  defaultChecked={editingProperty.amenities.includes("Asciugatore")}
                  onChange={(e) => _handleUpdateAmenities("Asciugatore", e)}
                />
                <Checkbox
                  label="Scrivania"
                  name="Scrivania"
                  defaultChecked={editingProperty.amenities.includes("Scrivania")}
                  onChange={(e) => _handleUpdateAmenities("Scrivania", e)}
                />
                <Checkbox
                  label="Frigo"
                  name="Frigo"
                  defaultChecked={editingProperty.amenities.includes("Frigo")}
                  onChange={(e) => _handleUpdateAmenities("Frigo", e)}
                />
              </div>
            </div>

            {/* ITEM */}
            <div>
              <label className="text-lg font-semibold" htmlFor="">
                Altri servizi
              </label>
              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                <Checkbox
                  label="Armadio"
                  name="armadio"
                  defaultChecked={editingProperty.amenities.includes("armadio")}
                  onChange={(e) => _handleUpdateAmenities("armadio", e)}
                />
                <Checkbox
                  label="Cassaforte"
                  name="Cassaforte"
                  defaultChecked={editingProperty.amenities.includes("Cassaforte")}
                  onChange={(e) => _handleUpdateAmenities("Cassaforte", e)}
                />
                <Checkbox
                  label="Cuscini extra"
                  name="Cuscini extra"
                  defaultChecked={editingProperty.amenities.includes("Cuscini extra")}
                  onChange={(e) => _handleUpdateAmenities("Cuscini extra", e)}
                />
                <Checkbox
                  label="Griglia per barbecue"
                  name="Griglia per barbecue"
                  defaultChecked={editingProperty.amenities.includes("Griglia per barbecue")}
                  onChange={(e) => _handleUpdateAmenities("Griglia per barbecue", e)}
                />
                <Checkbox
                  label="Tostapane"
                  name="Tostapane"
                  defaultChecked={editingProperty.amenities.includes("Tostapane")}
                  onChange={(e) => _handleUpdateAmenities("Tostapane", e)}
                />
                <Checkbox
                  label="Asciugamani"
                  name="Asciugamani"
                  defaultChecked={editingProperty.amenities.includes("Asciugamani")}
                  onChange={(e) => _handleUpdateAmenities("Asciugamani", e)}
                />
              </div>
            </div>
            <div className="pt-2">
              <ButtonPrimary onClick={handleUpdateProperty} loading={isLoading || updatingProperty}>Aggiorna</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default EditListingAmenities;
