import React, { FC, useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import useNcId from "hooks/useNcId";
import BorghiSlider from "./BorghiSlider";
import { useGetAllBorghiQuery } from "features/borghi/borghiApiSlice";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Mare e spiaggia",
    taxonomy: "category",
    count: 17288,
    thumbnail:
      "https://images.pexels.com/photos/2581922/pexels-photo-2581922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Montagna e sci",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/2351649/pexels-photo-2351649.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "Relax nella natura",
    taxonomy: "category",
    count: 36612,
    thumbnail:
      "https://images.pexels.com/photos/962464/pexels-photo-962464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Città e cultura",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/248837/pexels-photo-248837.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

const SectionSliderBorghi: FC<SectionSliderNewCategoriesProps> = ({
  heading = "Destinazioni più ricercate",
  subHeading = "Scopri le destinazioni più ricercate dalla nostra tribù di viaggiatori e smartworker",
  className = "",
  itemClassName = "",
  categories = DEMO_CATS,
  sliderStyle = "style1",
}) => {
  const { data, isLoading } = useGetAllBorghiQuery({});

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`flow-root md:px-10`}>
        <Heading
          desc={subHeading}
          hasNextPrev={false}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        <div>
          <div className="flex flex-col gap-10 md:gap-5">
            {data?.borghi.map((borgoType: any) => {
              // Estrai la chiave (tipo di borgo) e il valore (array di borghi)
              const tipo = Object.keys(borgoType)[0];
              const borghiArray = borgoType[tipo];

              // Costruisci il titolo del componente BorghiSlider
              const title = `Destinazione ${tipo}`;
              return (
                // Mostra il componente BorghiSlider
                <BorghiSlider
                  categoryCardType={"card3"}
                  itemPerRow={5}
                  title={title}
                  itemClassName={itemClassName}
                  borghi={borghiArray}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderBorghi;
