import Glide from '@glidejs/glide';
import CardCategory3 from 'components/CardCategory3/CardCategory3';
import CardCategory4 from 'components/CardCategory4/CardCategory4';
import CardCategory5 from 'components/CardCategory5/CardCategory5';
import Heading from 'components/Heading/Heading';
import { TaxonomyType } from 'data/types';
import useNcId from 'hooks/useNcId';
import React, { useEffect, useMemo } from 'react';

interface Props {
  // Define your component's props here
  categoryCardType: "card3" | "card4" | "card5";
  itemPerRow: 4 | 5;
  uniqueClassName?: string;
  itemClassName?: string;
  title: string;
  borghi: any;
}
const categories: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Mare e spiaggia",
    taxonomy: "category",
    count: 17288,
    thumbnail:
      "https://images.pexels.com/photos/2581922/pexels-photo-2581922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Montagna e sci",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/2351649/pexels-photo-2351649.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "Relax nella natura",
    taxonomy: "category",
    count: 36612,
    thumbnail:
      "https://images.pexels.com/photos/962464/pexels-photo-962464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Città e cultura",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/248837/pexels-photo-248837.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

const BorghiSlider: React.FC<Props> = ({
  categoryCardType,
  itemPerRow,
  uniqueClassName,
  itemClassName = "",
  title,
  borghi
}) => {

  uniqueClassName = uniqueClassName || "BorghiSlider";
  // Implement your component logic here
  const renderCard = (item: TaxonomyType, index: number) => {
    const obj = {
      ...item,
      href: "/borgo/" + item.name
    }
    switch (categoryCardType) {
      case "card3":
        return <CardCategory3 taxonomy={obj} />;
      case "card4":
        return <CardCategory4 taxonomy={obj} />;
      case "card5":
        return <CardCategory5 taxonomy={obj} />;
      default:
        return <CardCategory4 taxonomy={obj} />;
    }
  };

  const UNIQUE_CLASS =
    "SectionSliderNewCategories__" + uniqueClassName + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 5,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 5 - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  return (
    <div className={`${UNIQUE_CLASS}`}>
      <Heading
        desc={""}
        hasNextPrev={true}
        textClass="text-xl md:text-2xl"
        className="mb-4"
        isCenter={false}
      >
        {title}
      </Heading>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {borghi?.map((item: any, index: number) => (
            <li key={index} className={`glide__slide ${itemClassName}`}>
              {renderCard(item, index)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BorghiSlider;
