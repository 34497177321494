import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { updateHostFields } from "backend/services";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentHost, setHost } from "features/host/hostSlice";
import { useNavigate } from "react-router-dom";
import { useUpdateHostMutation } from "features/host/hostApiSlice";
import { provinceItaliane } from "./provincie";

export interface PageAddHostInfoProps { }

const PageAddHostInfo3: FC<PageAddHostInfoProps> = () => {
  const host = useSelector(selectCurrentHost);
  const [updateHost, { isLoading: isUpdating }] = useUpdateHostMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [canGoNext, setCanGoNext] = useState<boolean>(false)

  const handleUpdateHost = (field: string, value: any) => {
    dispatch(setHost({ ...host, [field]: value }));
  };

  const _updateDefaultFields = () => {
    dispatch(setHost({
      ...host,
      state: "italia",
    }));
  }

  const onSubmit = async () => {
    try {
      const response = await updateHost(host).unwrap();
      navigate("/host")
    } catch (err) {
      alert("Errore durante l'aggiornamento dei dati")
      console.log(err)
    }
  }

  useEffect(() => {
    _updateDefaultFields()
  }, []);

  useEffect(() => {
    if (host.address && host.city && host.country && host.postal_code) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
    }
  }, [host])

  return (
    <CommonLayout
      index="03"
      nextHref={canGoNext ? "/host/info/upload" : ""}
      backtHref="/host/info/add-info-2"
      canGoNext={canGoNext}
      onNext={onSubmit}
    >
      <>
        <h2 className="text-2xl font-semibold">I tuoi dati</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Nazione"
          >
            <Select
              defaultValue={host.state}
              onChange={(e) => handleUpdateHost("state", e.target.value)}
              placeholder="Nazione"
            >
              <option value="Italia">Italia</option>
            </Select>
          </FormItem>
          <FormItem
            label="Indirizzo"
          >
            <Input
              defaultValue={host.address}
              onChange={(e) => handleUpdateHost("address", e.target.value)}
              placeholder="Indirizzo"
            />
          </FormItem>
          <FormItem
            label="Città"
          >
            <Input
              defaultValue={host.city}
              onChange={(e) => handleUpdateHost("city", e.target.value)}
              placeholder="Città"
            />
          </FormItem>
          <FormItem
            label="Provincia"
          >
            <Select
              defaultValue={host.country}
              onChange={(e) => handleUpdateHost("country", e.target.value)}
              placeholder="Provnicia"
            >
              {
                provinceItaliane.map((p, i) => (
                  <option key={i} value={p.value}>{p.label}</option>
                ))
              }
            </Select>
          </FormItem>
          <FormItem
            label="Codice postale"
          >
            <Input
              defaultValue={host.postal_code}
              onChange={(e) => handleUpdateHost("postal_code", e.target.value)}
              placeholder="Codice postale"
            />
          </FormItem>
        </div>
      </>
    </CommonLayout >
  );
};

export default PageAddHostInfo3;
