import { apiSlice } from "../../app/api/apiSlice";

export const hostSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => ({
        url: "/host/",
        method: "GET",
      }),
      providesTags: ["Host"],
    }),
    getHost: builder.query({
      query: (host_uuid) => ({
        url: '/host/profile/' + host_uuid,
        method: "GET",
      }),
    }),
    uploadAvatar: builder.mutation({
      query: (file) => ({
        url: "/host/upload/cover",
        method: "POST",
        body: file,
      }),
    }),
    updateHost: builder.mutation({
      query: (host) => ({
        url: "/host/update",
        method: "PUT",
        body: host,
      }),
      invalidatesTags: ["Host"],
    }),
    createStripeAccount: builder.mutation({
      query: () => ({
        url: "/host/stripe/create-account",
        method: "POST",
      }),
    }),
    stripeLogin: builder.query({
      query: () => ({
        url: "/host/stripe/login",
        method: "GET",
      }),
    }),
    getAllMyProperties: builder.query({
      query: () => ({
        url: "/host/properties",
        method: "GET",
      }),
    }),
    getAllMyExperiences: builder.query({
      query: () => ({
        url: "/host/experiences",
        method: "GET",
      }),
    }),
    getHostBookings: builder.query({
      query: (filter) => ({
        url: `/host/bookings/${filter}`,
        method: "GET",
      }),
    }),
    updateHostSelf: builder.mutation({
      query: (host) => ({
        url: "/host/updateSelf",
        method: "PUT",
        body: host,
      }),
      invalidatesTags: ["Host"],
    }),
    changeHostPassword: builder.mutation({
      query: ({ oldPassword, newPassword }) => ({
        url: "/host/changePassword",
        method: "POST",
        body: { oldPassword, newPassword }
      }),
      invalidatesTags: ["Host"],
    }),
    getHostStats: builder.query({
      query: () => ({
        url: `/host/stats/`,
        method: "GET"
      }),
    }),
  })
});


export const {
  useGetMeQuery,
  useLazyGetMeQuery,
  useUploadAvatarMutation,
  useUpdateHostMutation,
  useCreateStripeAccountMutation,
  useStripeLoginQuery,
  useGetAllMyPropertiesQuery,
  useGetAllMyExperiencesQuery,
  useLazyGetAllMyExperiencesQuery,
  useLazyGetAllMyPropertiesQuery,
  useLazyGetHostQuery,
  useGetHostQuery,
  useLazyGetHostBookingsQuery,
  useGetHostBookingsQuery,
  useChangeHostPasswordMutation,
  useUpdateHostSelfMutation,
  useGetHostStatsQuery
} = hostSlice;