import ExperienceCard from "components/ExperienceCard/ExperienceCard";
import RefundModal from "components/RefundModal/RefundModal";
import ReviewModal from "components/ReviewModal/ReviewModal";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import HeaderFilter from "containers/PageHome/HeaderFilter";
import { useCancelBookingMutation, useGetBookingOutcomeQuery } from "features/bookings/bookingApiSlice";
import { useGetMyBookingsQuery } from "features/client/clientApiSlice";
import { useCreateExperienceMutation } from "features/experience/experienceApiSlice";
import { useCreateReviewMutation } from "features/review/reviewApiSlice";
import moment from "moment";
import React, { FC, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";

export interface MyReservationsClientProps {
  className?: string;
}

const MyReservationsClient: FC<MyReservationsClientProps> = ({ className = "" }) => {
  const { data, isLoading, isSuccess } = useGetMyBookingsQuery({})
  const [cancelBooking, { data: cancelBookingDatga, isLoading: isLoadingCancelBooking }] = useCancelBookingMutation()
  const [createReview, { data: createReviewData, isLoading: isLoadingCreateReview }] = useCreateReviewMutation()

  const [activeTab, setActiveTab] = React.useState<string>("Prossime");
  const [showRefundModal, setShowRefundModal] = React.useState<boolean>(false);
  const [showReviewModal, setShowReviewModal] = React.useState<boolean>(false);
  const [activeBooking, setActiveBooking] = React.useState<any>({})

  const tabs = ["Prossime", "Passate"]

  const handleOpenRefundModal = (booking: any) => {
    setActiveBooking(booking)
    setShowRefundModal(true)
  }

  const handleOpenReviewModal = (booking: any) => {
    setActiveBooking(booking)
    setShowReviewModal(true)
  }

  const handleCreateReview = async (booking_uuid: string, stars: number, comment: string) => {
    try {
      const res = await createReview({ booking_uuid, score: stars, comment }).unwrap()
      toast.success("Recensione inviata con successo");
      // after 700 ms reload the page
      setTimeout(() => {
        window.location.reload()
      }, 700);
    } catch (error) {
      toast.error("Errore durante l'invio della recensione");
      console.log(error);
    }
  }


  const handleChangeTab = () => {
    if (activeTab === "Prossime") {
      if (data) {
        return data.incoming.map((item: any) => {
          return renderCard(item, item.status !== "canceled", item.status === "canceled", false)
        })
      }
    }
    if (activeTab === "Passate") {
      if (data) {
        return data.outcoming.map((item: any) => {
          return renderCard(item, false, false, true)
        })
      }
    }
  };

  const handleCancelBooking = async (booking_uuid: string) => {
    try {
      const res = await cancelBooking(booking_uuid).unwrap();
      setShowRefundModal(false)
      toast.success("Prenotazione annullata con successo");
      // after 700 ms reload the page
      setTimeout(() => {
        window.location.reload()
      }, 700);
      console.log(res);
    } catch (error) {
      toast.error("Errore durante l'annullamento della prenotazione");
      console.log(error);
    }
  }


  const renderCard = (booking: any, showRefund: boolean, showCanceled: boolean, showReview: boolean) => {
    if (booking.item_type === "property") {
      return (
        <StayCard
          key={booking.booking_uuid}
          data={booking}
          showRefund={showRefund}
          showCanceled={showCanceled}
          showAddToCalendar={showRefund}
          showReview={showReview}
          onRefundClick={() => handleOpenRefundModal(booking)}
          onReviewClick={() => handleOpenReviewModal(booking)}
          myReservations
          bookingPrice={booking.price}
          comment={booking?.comment}
          score={booking?.score}
          check_in={booking.check_in}
          check_out={booking.check_out}
        />
      );
    }
    else {
      return (
        <ExperienceCard
          href={"/host/edit-experience/" + booking.item_uuid}
          key={booking.booking_uuid}
          data={booking}
          showRefund={showRefund}
          showAddToCalendar={showRefund}
          showCanceled={showCanceled}
          showReview={showReview}
          onRefundClick={() => handleOpenRefundModal(booking)}
          onReviewClick={() => handleOpenReviewModal(booking)}
          bookingPrice={booking.price}
          check_in={booking.check_in}
          check_out={booking.check_out}
          score={booking?.score}
          myReservations
        />
      );
    }
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-8 flex flex-col">
        <HeaderFilter
          tabActive={activeTab}
          tabs={tabs}
          heading={"Le mie prenotazioni"}
          subHeading={"le prenotazioni attive su TRIBYOU"}
          onClickTab={(e) => setActiveTab(e)}
        />
        <div
          className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-3`}
        >
          {
            isLoading ?
              <>
                <Skeleton className="w-full h-[10rem] md:h-[10rem]" />
                <Skeleton className="w-full h-[10rem] md:h-[10rem]" />
                <Skeleton className="w-full h-[10rem] md:h-[10rem]" />
              </>
              :
              handleChangeTab()
          }
        </div>
      </div>
      <RefundModal
        isOpen={showRefundModal}
        closeModal={() => setShowRefundModal(false)}
        cancelBooking={() => handleCancelBooking(activeBooking?.booking_uuid)}
        isLoading={isLoadingCancelBooking}
        booking={activeBooking}
      />
      <ReviewModal
        isOpen={showReviewModal}
        closeModal={() => setShowReviewModal(false)}
        sendReview={(stars, comment) => handleCreateReview(activeBooking?.booking_uuid, stars, comment)}
        isLoading={isLoadingCreateReview}
        booking={activeBooking}
      />
    </div>
  );
};

export default MyReservationsClient;
