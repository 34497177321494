import React from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import useWindowSize from "hooks/useWindowResize";
import PageLoginHost from "containers/PageLoginHost/PageLoginHost";
import HostPagesRouter from "Navigation/HostPagesRouter";
import PageLogout from "containers/LogoutPage";
import MainRouter from "Navigation/MainRouter/MainRouter";
import { store } from "app/store";
import { Provider, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import ClientPagesRouter from "Navigation/ClientPagesRouter";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

let persistor = persistStore(store);

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;

  moment.locale("it");

  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          position="bottom-right"
        />
        <HashRouter>
          <ScrollToTop />
          <Routes>
            <Route path="*" element={<MainRouter />} />
            <Route path="/host/*" element={<HostPagesRouter />} />
            <Route path="/client/*" element={<ClientPagesRouter />} />
            <Route element={<Page404 />} />
          </Routes>
          <Footer />
        </HashRouter>
      </PersistGate>
    </Provider>
  );
};

export default MyRoutes;
