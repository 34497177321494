import Label from "components/Label/Label";
import React, { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { toast } from 'react-toastify';
import { useEditExperienceMutation, useGetExperienceQuery, useLazyGetExperienceQuery, useUploadExperienceAlbumImageMutation, useUploadExperienceCoverMutation } from "features/experience/experienceApiSlice";
import { selectEditingExperience, setEditingExperience } from "features/experience/experienceSlice";
import { Dialog, Transition } from "@headlessui/react";

export interface EditExperiencePageProps {
  className?: string;
}

const EditExperiencePage: FC<EditExperiencePageProps> = ({ className = "" }) => {
  const experienceId = useParams<{ id: string }>().id;
  const [isOpen, setIsOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);

  const [getExperience, { isLoading, isError }] = useLazyGetExperienceQuery();

  const [updateAlbumImage, { isLoading: uploadingAlbumImage }] = useUploadExperienceCoverMutation()
  const [updateCoverImage, { isLoading: uploadingCoverImage }] = useUploadExperienceAlbumImageMutation()
  const [updateexperience, { isLoading: updatingexperience, isSuccess, isUninitialized }] = useEditExperienceMutation();

  const editingExperience = useSelector(selectEditingExperience);

  const dispatch = useDispatch();

  const [cover, setCover] = useState()
  const [album, setAlbum] = useState<any[]>(editingExperience?.media || [])

  const coverRef = React.useRef<HTMLInputElement>(null);
  const albumRef = React.useRef<HTMLInputElement>(null);

  const notify = async (success: boolean) => {
    if (success) {
      toast.success("Esperienza aggiornato con successo")
    } else {
      toast.error("Errore nell'aggiornamento dell'esperienza")
    }
  }

  const getCover = async (e: any) => {
    try {
      if (e.target.files[0].size > 10000000) {
        throw alert('Inserisci un file di dimensioni inferiori a 10mb')
      }
      setCover(e.target.files[0])
    } catch (err) {
      console.log(err)
    }
  }

  const getAlbum = async (e: any) => {
    try {
      const url = URL.createObjectURL(e.target.files[0]);
      setAlbum([...album, e.target.files[0]])
    } catch (err) {
      console.log(err)
    }
  }

  const uploadCover = async () => {
    if (cover) {
      const formData = new FormData()
      formData.append('experience-cover', cover)
      const response: any = await updateCoverImage(formData).unwrap();
      return response.result.s3Location
    }
    return editingExperience.cover
  }

  const uploadAlbum = async () => {
    const media = []
    for (const file of album) {
      console.log("iterating")
      if (typeof file === 'object') {
        const formData = new FormData()
        formData.append('experience-album-image', file)
        const response = await updateAlbumImage(formData).unwrap();
        media.push(response.result.s3Location)
      } else {
        media.push(file)
      }
    }
    return media
  }

  const onSubmit = async () => {
    try {
      const media = await uploadAlbum();
      const cover = await uploadCover();

      dispatch(setEditingExperience({ ...editingExperience, media, cover }))

      const toSend = { ...editingExperience, media, cover }
      delete toSend.host

      await updateexperience(toSend).unwrap().then((payload) => notify(true))
        .catch((error) => notify(false))

    } catch (err) {
      console.log(err)
    }
  }


  const handleUpdateexperienceField = (field: string, value: any) => {
    dispatch(setEditingExperience({ ...editingExperience, [field]: value }))
  }

  const fetchExperience = async () => {
    try {
      const response = await getExperience(experienceId).unwrap();
      dispatch(setEditingExperience(response.experience))
      setAlbum(response.experience.media)
    } catch (err) {
      console.log(err)
    }
  }

  const handleStop = async () => {
    try {
      await updateexperience({
        experience_uuid: editingExperience.experience_uuid,
        name: editingExperience.name,
        stopped: true
      }).unwrap().then((payload) => notify(true))
        .catch((error) => notify(false));
      setIsOpen(false)
      window.location.reload()
      toast.success("Esperienzze messa in pausa con successo")
    } catch (err) {
      toast.error("Errore nel mettere in pausa l'esperienza")
      console.log(err)
    }
  }

  const handlePublish = async () => {
    try {
      await updateexperience({
        experience_uuid: editingExperience.experience_uuid,
        name: editingExperience.name,
        stopped: false
      }).unwrap().then((payload) => notify(true))
        .catch((error) => notify(false));
      setIsOpen(false)
      toast.success("Alloggio pubblicato con successo")
      window.location.reload()
    } catch (err) {
      toast.error("Errore durante la pubblicazione  dell'alloggio")
      console.log(err)
    }
  }


  useEffect(() => {
    fetchExperience()
    return () => {
      dispatch(setEditingExperience(null));
    };
  }, [experienceId]);

  if (isLoading || !editingExperience) return (
    <div className={`nc-EditExperiencePage ${className}`} data-nc-id="EditExperiencePage">
      <Helmet>
        <title>La tua esperienza || TRIBYOU</title>
      </Helmet>
      <CommonLayout experienceId={experienceId}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Informazioni esperienza</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 è-"></div>
          <Skeleton className="w-32 h-32" count={3} />
        </div>
      </CommonLayout>
    </div>
  );
  return (
    <div className={`nc-EditExperiencePage ${className}`} data-nc-id="EditExperiencePage">
      <Helmet>
        <title>La tua esperienza || TRIBYOU</title>
      </Helmet>
      <CommonLayout experienceId={experienceId}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Informazioni esperienza</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex-grow mt-10 md:mt-0 max-w-3xl space-y-6">
            <div>
              <Label>Nome</Label>
              <Input onChange={(e) => handleUpdateexperienceField("name", e.target.value)} className="mt-1.5" defaultValue={editingExperience.name} />
            </div>
            <div>
              <Label>Descrizione</Label>
              <Textarea onChange={(e) => handleUpdateexperienceField("description", e.target.value)} className="mt-1.5" defaultValue={editingExperience.description} />
            </div>
            <div>
              <Label>Immagine di copertina</Label>
              <div className="mt-1.5 flex items-start flex-col">
                {cover ?
                  <img src={URL.createObjectURL(cover)} className="h-auto w-full md:w-40" />
                  :
                  <div>
                    <input type="file" ref={coverRef} accept="image/*" onChange={getCover} className="opacity-0 h-0 w-0 absolute" />
                    <img src={editingExperience.cover} className="h-auto w-full md:w-40" />
                  </div>
                }
                <div className="mt-5">
                  <ButtonSecondary onClick={() => coverRef.current?.click()}>Carica nuova immagine</ButtonSecondary>
                </div>
              </div>
            </div>
            <div>
              <Label>Galleria annuncio</Label>
              <div className="grid grid-cols-3 gap-4 mt-4">
                {album && album.length > 0 ?
                  album.map((image: any, index) => (
                    <div key={index} className="relative">
                      <img src={image.length ? image : URL.createObjectURL(image)} alt="" className="w-full h-32 object-cover rounded-md" />
                      <div className="absolute top-0 right-0">
                        <button
                          onClick={() => {
                            setAlbum(album.filter((item, i) => i !== index))
                          }}
                          className="bg-neutral-100 dark:bg-neutral-800 rounded-full p-1"
                        >
                          <svg
                            className="w-4 h-4 text-neutral-6000 dark:text-neutral-300"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
                  :
                  null
                }
              </div>
              <div className="mt-5">
                <input type="file" ref={albumRef} multiple accept="image/*" onChange={getAlbum} className="opacity-0 h-0 w-0 absolute" />
                <ButtonSecondary onClick={() => albumRef.current?.click()}>Carica nuova immagine</ButtonSecondary>
              </div>
            </div>
            <div className="pt-2 flex flex-row gap-2">
              {
                editingExperience.stopped ?
                  <ButtonPrimary onClick={() => setIsPublishModalOpen(true)}>Pubblica esperienza</ButtonPrimary>
                  :
                  <>
                    <ButtonPrimary loading={isLoading || uploadingAlbumImage || uploadingCoverImage || updatingexperience} onClick={onSubmit}>
                      {
                        isLoading ? "Carico i dati..." :
                          uploadingAlbumImage ? "Carico la galleria..." :
                            uploadingCoverImage ? "Carico la copertina..." :
                              updatingexperience ? "Salvo i dati..." : "Salva i dati"
                      }
                    </ButtonPrimary>
                    <ButtonSecondary onClick={() => setIsOpen(true)}>Metti in pausa</ButtonSecondary>

                  </>
              }
            </div>
          </div>
        </div>
      </CommonLayout>

      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div className="fixed inset-0 flex items-center justify-center p-5 md:p-0">
              <div className="bg-white rounded-lg p-10 flex items-start flex-col justify-center">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Metti in pausa l'annuncio
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 break-words max-w-md">
                    Sei sicuro di voler mettere in pausa l'annuncio? L'annuncio non sarà più visibile agli utenti.
                  </p>
                </div>
                <div className="mt-4 flex flex-row gap-3">
                  <ButtonSecondary
                    onClick={() => setIsOpen(false)}
                  >
                    Chiudi
                  </ButtonSecondary>

                  <ButtonPrimary
                    onClick={() => handleStop()}
                    loading={isLoading}
                  >
                    Metti in pausa
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={isPublishModalOpen} as={Fragment}>
        <Dialog as="div" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div className="fixed inset-0 flex items-center justify-center p-5 md:p-0">
              <div className="bg-white rounded-lg p-10 flex items-start flex-col justify-center">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Pubblica l'annuncio
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 break-words max-w-md">
                    Sei sicuro di voler pubblicare l'annuncio? L'annuncio sarà visibile agli utenti.
                  </p>
                </div>
                <div className="mt-4 flex flex-row gap-3">
                  <ButtonSecondary
                    onClick={() => setIsPublishModalOpen(false)}
                  >
                    Chiudi
                  </ButtonSecondary>

                  <ButtonPrimary
                    onClick={() => handlePublish()}
                    loading={isLoading}
                  >
                    pubblica
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default EditExperiencePage;
