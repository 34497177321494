import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import axios from 'axios';
import { BASE_URL } from "app/api/apiSlice";
import { toast } from "react-toastify";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 SEDE LEGALE",
    desc: "Via Giordano Bruno 21, 56025 Pontedera (PI) Italy",
  },
  {
    title: "🗺 SEDE OPERATIVA",
    desc: " Via Giovanni Andrea Dell'Anguillara 4 - 01015 Sutri (VT)",
  },
  {
    title: "💌 EMAIL",
    desc: "info@hqvillage.com",
  },
];


const PageCloseAccount: FC<PageContactProps> = ({ className = "" }) => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const sendSupportEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name || !email || !message) {
      toast.error('Compila tutti i campi per inviare il messaggio');
      return;
    }
    const sendEmail = await axios.post(`${BASE_URL}email/support`, {
      name,
      email,
      message
    });
    if (sendEmail.status === 200) {
      toast.success('Richiesta inoltrata con successo');
    }
    else {
      toast.error('Errore nell\'invio del messaggio');
    }
  }

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Chiudi il tuo account || TRIBYOU</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Chiudi il tuo account
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={sendSupportEmail}>
                <label className="block">
                  <Label>Nome</Label>

                  <Input
                    placeholder="Example Doe"
                    type="text"
                    name="name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>La tua email(associata al tuo account tribyou)</Label>

                  <Input
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    name="email"
                    placeholder="example@example.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Messaggio</Label>

                  <Textarea
                    className="mt-1"
                    rows={6}
                    name="message"
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit">Invia messaggio</ButtonPrimary>
                </div>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  Inviando questo form richiedi la chiusura del tuo account.
                </span> 
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageCloseAccount;
