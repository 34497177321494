import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC, useEffect, useRef } from "react";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { usePageAddListingContext } from "contexts/PageAddListingContext";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentproperty, setproperty } from "features/property/propertySlice";
import Textarea from "shared/Textarea/Textarea";
import Input from "shared/Input/Input";
import axios from "axios";
import { useAddAmenitiesToBorgoMutation, useCreateBorgoMutation, useGetAmenitiesQuery, useUploadBorgoCoverMutation } from "features/borghi/borghiApiSlice";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { selectCurrentBorgo, setBorgo } from "features/borghi/borghiSlice";
import Checkbox from "shared/Checkbox/Checkbox";

export interface PageAddListing3Props { }

const PageAddListingBorgo: FC<PageAddListing3Props> = () => {
  const property = useSelector(selectCurrentproperty);
  const dispatch = useDispatch()
  const borgo = useSelector(selectCurrentBorgo)
  const [cover, setCover] = React.useState<string>("")
  const coverRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const [updateCover, { isLoading: uploadingCover, }] = useUploadBorgoCoverMutation();
  const [createBorgo, { isLoading: creatingBorgo }] = useCreateBorgoMutation();
  const [addAmenities, { isLoading: addingAmenities }] = useAddAmenitiesToBorgoMutation();

  const { data: amenities, isLoading: loadingAmenities } = useGetAmenitiesQuery({});
  const [amenitiesSelected, setAmenitiesSelected] = React.useState<any[]>([])

  const handleRemoveCover = () => {
    if (coverRef.current) {
      coverRef.current.value = "";
    }
    dispatch(setBorgo({ ...borgo, cover: "" }))
    setCover("")
  }

  const handleUpdateBorgo = (field: string, value: any) => {
    try {
      dispatch(setBorgo({ ...borgo, [field]: value }));
    }
    catch (e) {
      console.log(e)
    }
  };

  const uploadCover = async (e: any) => {
    try {
      // if file size is greater than 4mb throw error
      if (e.target.files[0].size > 10000000) {
        throw alert('Inserisci un file di dimensioni inferiori a 10mb')
      }
      const url = URL.createObjectURL(e.target.files[0]);
      setCover(url)

      const formData = new FormData();
      formData.append('borgo-cover', e.target.files[0]);

      const response = await updateCover(formData).unwrap()
      const coverUrl = `${response.result.s3Location}?timestamp=${Date.now()}`;

      dispatch(setBorgo({ ...borgo, cover: coverUrl }))
    } catch (err) {
      console.log(err)
    }
  }

  const handleCreateBorgo = async () => {
    try {
      const response = await createBorgo(borgo).unwrap();
      if (response?.borgo[0]?.name) {
        const addAmenitiesResponse = await addAmenities({ borgo: response.borgo[0].name, amenities: amenitiesSelected }).unwrap();
        navigate("/host/new-property/add-listing-3")
      }
    } catch (e) {
      console.log(e)
    }
  }

  function CustomPicker({ url }: { url: string }) {
    if (url && url.length > 0) {
      return (
        <div className="">
          <img src={url} alt="" className="w-auto max-h-80" />
          <div className="absolute top-0 right-0 z-50">
            <button
              onClick={() => {
                handleRemoveCover()
              }}
              className="bg-neutral-100 dark:bg-neutral-800 rounded-full p-1"
            >
              <svg
                className="w-4 h-4 text-neutral-6000 dark:text-neutral-300"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
          <div
            className="space-y-1 text-center relative">
            <svg
              className="mx-auto h-12 w-12 text-neutral-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
            <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
              <label
                htmlFor="file-upload"
                className="relative flex justify-center cursor-pointer text-center  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
              >
                <span>Carica qui</span>
              </label>
              <p className="pl-1">il tuo file</p>
            </div>
            <p className="text-xs text-neutral-500 dark:text-neutral-400">
              PNG, JPG, JPEG non più di 10MB
            </p>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {

    dispatch(setBorgo({ type: "mare", cover: "", name: property?.city, region: property?.region }))
  }, [])

  return (
    <CommonLayout
      index="03"
      backtHref="/host/new-property/add-listing-2"
      nextHref="/host/new-property/add-listing-4"
      customNext={handleCreateBorgo}
      loading={creatingBorgo}
      canGoNext={borgo?.description && borgo?.inhabitants && borgo?.type && borgo?.cover && !uploadingCover}
    >
      <>
        <h2 className="text-2xl font-semibold">Aggiungi i dettagli della destinazione</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label="Descrizione">
            <Textarea
              maxLength={500}
              placeholder="Inserisci una descrizione"
              onChange={(e) => handleUpdateBorgo("description", e.target.value)}
            />
          </FormItem>
          <FormItem label="Abitanti">
            <Input
              placeholder="Inserisci il numero di abitanti"
              type="number"
              onChange={(e) => handleUpdateBorgo("inhabitants", e.target.value)}
            />
          </FormItem>
          <FormItem
            label="Tipo di destinazione"
          >
            <Select
              onChange={(e) => handleUpdateBorgo("type", e.target.value)}
            >
              <option value="mare">Mare</option>
              <option value="montagna">Montagna</option>
              <option value="città">Città</option>
              <option value="campagna">Collina</option>
              <option value="lago">Lago</option>
              <option value="centro storico">Centro storico</option>
              <option value="periferia">Periferia</option>
            </Select>
          </FormItem>

          <div className="relative">
            <FormItem
              label="Foto di copertina"
            >
              {
                uploadingCover ?
                  <div className="w-full h-32 ">
                    <Skeleton className="h-full rounded-full" />
                  </div>
                  :
                  <CustomPicker url={borgo?.cover} />
              }
              <Input
                type="file"
                className="absolute w-full h-full z-40 opacity-0 top-0"
                disabled={uploadingCover}
                ref={coverRef}
                onChange={(e) => uploadCover(e)}
              />
            </FormItem>

          </div>

          <div className="space-y-8">
            {/* ITEM */}
            <h3 className="text-lg font-semibold">Servizi</h3>
                <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                  {amenities?.amenities?.map((amenity: any) => (
                    <Checkbox
                      key={amenity.name}
                      label={amenity.name}
                      name={amenity.name}
                      isChecked={amenitiesSelected.some((a) => a?.name === amenity.name)}
                      onChange={(checked) => {
                        if (checked) {
                          setAmenitiesSelected([...amenitiesSelected, amenity])
                        } else {
                          setAmenitiesSelected(amenitiesSelected.filter((a) => a?.name !== amenity.name))
                        }
                      }}
                    />
                  ))}
              </div>

          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListingBorgo;
