import React, { FC, useEffect } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentExperience, setExperience } from "features/experience/experienceSlice";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";

export interface PageAddListing8Props { }

const PageAddExperience3: FC<PageAddListing8Props> = () => {
  const [availability, setAvailability] = React.useState<any>({
    week: false,
    weekend: false,
    weekdays: false,
  });

  const experience = useSelector(selectCurrentExperience)
  const dispatch = useDispatch()

  const handleUpdateExperience = (field: string, value: any,) => {
    try {
      dispatch(setExperience({ ...experience, [field]: value }))
    }
    catch (e) {
      console.log(e)
    }
  };

  const handleUpdateAvailability = (name: string, e: any) => {
    const arr = { ...availability, [name]: e }
    // flag to false all the other keys
    Object.keys(arr).forEach((key) => {
      if (key !== name) {
        arr[key] = false
      }
    })
    setAvailability(arr)
  }

  useEffect(() => {
    const key = Object.keys(availability).find(key => availability[key] === true)
    handleUpdateExperience("availability", key)
  }, [availability])

  return (
    <CommonLayout
      index="03"
      backtHref="/host/new-experience/add-listing-2"
      nextHref="/host/new-experience/add-listing-4"
      canGoNext={experience.price?.length && experience.availability?.length > 0 && experience.available_from?.length > 0 && experience.available_to?.length > 0 && experience.minutes_duration?.length > 0}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Prezzo e disponibilità</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Scegli il prezzo per ogni ospite che partecipa all'esperienza e quando è disponibile la tua esperienza
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label="Valuta">
            <Select>
              <option value="EUR">EUR</option>
            </Select>
          </FormItem>
          <FormItem label="Prezzo per persona">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">€</span>
              </div>
              <Input onChange={(e) => handleUpdateExperience("price", e.target.value)} className="!pl-8 !pr-10" placeholder="0.00" />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">EUR</span>
              </div>
            </div>
          </FormItem>
          <FormItem label="Posti disponibili">
            <Input
              placeholder="Inserisci i posti disponibili per ogni slot"
              type="number"
              onChange={(e) => handleUpdateExperience("max_guests", e.target.value)}
            />
          </FormItem>
          <FormItem label="Disponibilità">
            <Checkbox
              isChecked={availability.week}
              onChange={(e) => handleUpdateAvailability("week", e)}
              className="!pl-8 !pr-10 mt-2"
              label="Tutta la settimana"
              name="week"
            />
            <Checkbox
              isChecked={availability.weekend}
              onChange={(e) => handleUpdateAvailability("weekend", e)}
              className="!pl-8 !pr-10 mt-2"
              label="Fine settimana"
              name="weekend" />
            <Checkbox
              isChecked={availability.weekdays}
              onChange={(e) => handleUpdateAvailability("weekdays", e)}
              className="!pl-8 !pr-10 mt-2"
              label="Solo in settimana"
              name="weekdays"
            />
          </FormItem>
          <FormItem label="Disponibile dalle">
            <Select onChange={(e) => handleUpdateExperience("available_from", e.target.value)}>
              <option defaultChecked value={""}>Seleziona</option>
              {
                Array.from(Array(23).keys()).slice(8).map((item: any, index: Number) => (
                  <option key={item} value={item}>{item}:00</option>
                ))
              }
            </Select>
          </FormItem>
          {
            experience.available_from && (
              <FormItem label="Disponibile fino alle">
                <Select onChange={(e) => handleUpdateExperience("available_to", e.target.value)}>
                  {
                    Array.from(Array(23).keys()).slice(Number(experience.available_from) + 1).map((item: any, index: Number) => (
                      <option key={item} value={item}>{item}:00</option>
                    ))
                  }
                </Select>
              </FormItem>
            )
          }
          <FormItem label="Durata (in minuti)">
            <Input
              placeholder="Inserisci la durata dell'esperienza in minuti"
              type="number"
              onChange={(e) => handleUpdateExperience("minutes_duration", e.target.value)}
            />
          </FormItem>
          
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddExperience3;
