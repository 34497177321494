import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  verified: boolean;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  verified,
  btnText,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <div className="flex flex-row items-center border-primary-400  border-[1px] rounded-full w-fit py-1 px-4">
            <CheckBadgeIcon className="w-5 h-5 text-primary-500 dark:text-primary-400" />
            <span className="text-neutral-500 dark:text-neutral-400 font-semibold">{verified ? "Verificata" : "Non verificata"}</span>
          </div>
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
        </div>
        <div className="flex-grow">
          <img className="w-full rounded-md" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
