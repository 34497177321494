import rightImg from "images/about-hero-right.png";
import React, { FC, useEffect } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { useParams } from "react-router-dom";
import { useGetBorgoQuery, useGetBorgoSectionsQuery } from "features/borghi/borghiApiSlice";
import { useLazyFilterPropertiesQuery, useLazyGetByCityQuery } from "features/search/searchApiSlice";
import StayCard from "components/StayCard/StayCard";
import Heading from "shared/Heading/Heading";

export interface PageBorgoProps {
  className?: string;
}

const PageBorgo: FC<PageBorgoProps> = ({ className = "" }) => {
  const { name } = useParams();
  const { data, isLoading } = useGetBorgoQuery(name);
  const { data: sections, isLoading: isLoadingSections } = useGetBorgoSectionsQuery(name);

  const [filterProperties, { data: properties, isLoading: isLoadingProperties }] = useLazyGetByCityQuery({});

  useEffect(() => {
    filterProperties({ city: name });
  }, []);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const renderCard = (stay: any) => {
    return <StayCard key={stay.property_uuid} data={stay} />;
  };

  return (
    <div
      className={`nc-PageBorgo overflow-hidden relative ${className}`}
      data-nc-id="PageBorgo"
    >
      <Helmet>
        <title>{data?.borgo?.name || "Borgo"} || TRIBYOU</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={data?.borgo?.cover || ""}
          heading={data?.borgo?.name && capitalizeFirstLetter(data.borgo.name) || ""}
          btnText=""
          subHeading={data?.borgo?.description || ""}
          verified={data?.borgo?.verified}
        />
        <div className="nc-SectionGridFeaturePlaces relative">
          <Heading
          desc={`A ${data?.borgo?.name} ci sono ${properties?.length || 0} strutture disponibili`}
          >
            {`Strutture a ${data?.borgo?.name}`}
          </Heading>
          <div
            className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
          >
            {
              properties && properties.length > 0 &&
              properties.map(renderCard)
            }
          </div>
          {/* 
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Scopri di più</ButtonPrimary>
      </div>
      */}
        </div>
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageBorgo_" />
        </div>

        <SectionStatistic
          borgo={data?.borgo || {}}
        />
        {
          // map sections except the first one
          sections && sections.sections.slice(1).map((section: any, index: number) => {
            return (
              <SectionFounder
                title={section.title}
                description={section.description}
                image={section.cover}
                key={section.section_uuid}
                index={index}
              />
            );
          })
        }
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageBorgo;
