import React, { FC, Fragment, useMemo, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Popover, Transition } from '@headlessui/react'
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import NcImage from "shared/NcImage/NcImage";
import Glide from "@glidejs/glide";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useDispatch } from "react-redux";
import { setMood } from "features/search/searchSlice";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import surfer from "images/shaka.png";
import business from "images/business-trip.png";
import casual from "images/sweatshirt.png";

interface MoodSliderProps {
  className?: string;
  show?: boolean;
  onStepCompleted?: () => void;
  closeModal: () => void;
}

const MOODS = [
  {
    id: 1,
    img: business,
    title: "Business",
  },
  {
    id: 2,
    img: casual,
    title: "Comfort",
  },
  {
    id: 3,
    img: surfer,
    title: "Authentic",
  },
]

const MoodSlider: FC<MoodSliderProps> = ({ className = "", onStepCompleted, closeModal }) => {

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.SectionSliderNewCategories__MoodSlider`, {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 3,
        },
        1024: {
          gap: 20,
          perView: 1,
        },
        768: {
          gap: 20,
          perView: 1,
        },
        640: {
          gap: 20,
          perView: 1,
        },
        500: {
          gap: 20,
          perView: 1,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (MY_GLIDEJS) {
      setTimeout(() => {
        MY_GLIDEJS.mount();
      }, 100);
    }
  }, [MY_GLIDEJS]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const updateMoodField = (value: string) => {
    dispatch(setMood(value));
    if (width > 768) {
      {
        closeModal();
        navigate(`1/search`)
      }
    }
  }

  return (
    <>
      <span className="font-bold mb-10 text-2xl">
        Scegli il tuo mood
      </span>
      <div className={`SectionSliderNewCategories__MoodSlider w-full`}>
        <div className="flex">
          {
            width < 768 && (
              <div
                className={`nc-NextPrev relative flex items-center text-neutral-900 dark:text-neutral-300 ${className}`}
                data-nc-id="PrevPrev"
                data-glide-el="controls"
              >
                <button
                  className={` bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300`}
                  title="Prev"
                  data-glide-dir="<"
                >
                  <i className="las la-angle-left"></i>
                </button>
              </div>
            )
          }

          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {MOODS.map((item) => (
                <li className="glide__slide flex items-center justify-center flex-col">
                  <NcImage
                    containerClassName="rounded-full w-40 h-auto"
                    src={item.img}
                    alt={item.title}
                  />
                  <span className="block text-center mt-2 w-40 font-bold">{item.title}</span>
                  <span className="text-base md:text-sm text-center text-neutral-500 dark:text-neutral-400 mb-3">
                    lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </span>
                  <ButtonSecondary onClick={() => updateMoodField(item.title)} className="!p-[0.8rem]">
                    Scegli questo mood
                  </ButtonSecondary>
                </li>
              ))}
            </ul>
          </div>
          {
            width <= 768 && (
              <div
                className={`nc-NextPrev relative flex items-center text-neutral-900 dark:text-neutral-300 ${className}`}
                data-nc-id="NextPrev"
                data-glide-el="controls"
              >
                <button
                  className={` bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300`}
                  title="Next"
                  data-glide-dir=">"
                >
                  <i className="las la-angle-right"></i>
                </button>
              </div>
            )
          }
        </div>
        {
          width < 768 && (
            <div
              className="mt-10 glide__bullets flex items-center justify-center"
              data-glide-el="controls[nav]"
            >
              {MOODS.map((item, index) => (
                <button
                  key={item.id}
                  className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 mx-1 focus:outline-none"
                  data-glide-dir={`=${index}`}
                ></button>
              ))}
            </div>
          )
        }

      </div>
    </>
  )
}

export default MoodSlider;