import { Dialog, Transition } from '@headlessui/react'
import GallerySlider from 'components/GallerySlider/GallerySlider';
import StartRating from 'components/StartRating/StartRating';
import { Fragment, useState } from 'react'
import React, { FC } from "react";
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

export interface SideDrawerProps {
  className?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  experience: any;
  closeModal: () => void;
}

const SideDrawer: FC<SideDrawerProps> = ({
  className = "",
  children,
  isOpen,
  experience,
  closeModal
}) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex h-full items-center justify-end  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-full max-w-md transform overflow-y-scroll md:rounded-s-2xl bg-white p-8 text-left align-middle shadow-xl transition-all ">
                  <Dialog.Title
                    as="h2"
                    className="text-3xl font-semibold"
                  >
                    {experience?.name}
                  </Dialog.Title>
                  <div className="flex items-center space-x-4 text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                    <StartRating reviewCount={experience?.reviews_count ? experience.reviews_count : 0} point={experience?.rating ? experience.rating : 0} />
                    <span>· </span>
                    <div className="flex items-center">
                      <span className="hidden sm:inline-block  text-base">
                        <i className="las la-map-marked"></i>
                      </span>
                      <span className="sm:ml-2"> {experience?.address}</span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">
                      {experience?.description}
                    </p>
                  </div>

                  <div className='mt-4'>
                    <GallerySlider
                      galleryImgs={experience?.media}
                      uniqueID={`SideDrawer_${experience?.experience_uuid}`}
                    />
                  </div>

                  <div className='flex flex-col gap-1'>
                    <h4 className="font-semibold mt-4 text-xl">Durata:</h4>
                    <div className='flex flex-row gap-2 items-center'>
                      <i className="las la-clock text-md"></i>
                      <p className="text-md text-gray-500">
                        {experience?.minutes_duration} minuti
                      </p>
                    </div>
                  </div>

                  <div className='flex flex-col gap-1'>
                    <h4 className="font-semibold mt-4 text-xl">Posti:</h4>
                    <div className='flex flex-row gap-2 items-center'>
                      <i className="las la-user text-md"></i>
                      <p className="text-md text-gray-500">
                        {experience?.max_guests} persone
                      </p>
                    </div>
                  </div>

                  <div className='flex flex-col gap-1'>
                    <h4 className="font-semibold mt-4 text-xl">Disponibilità:</h4>
                    <div className='flex flex-row gap-2 items-center'>
                      <i className="las la-user text-md"></i>
                      <p className="text-md text-gray-500">
                        Sono disponibili slot orari ogni {experience?.minutes_duration} minuti
                        dalle {experience?.available_from} alle {experience?.available_to}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row items-center absolute bottom-6 right-3">
                    <ButtonSecondary
                      onClick={closeModal}
                    >
                      Chiudi
                    </ButtonSecondary>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SideDrawer;
