"use client";

import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import { LatLng, getDetails } from "use-places-autocomplete";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
} from "@reach/combobox";
import { useLoadScript } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { setCity, setCountry, setLatitude, setLongitude, setRegion } from "features/search/searchSlice";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Destinazione",
  desc = "Dove vuoi andare?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [showPopover, setShowPopover] = useState(autoFocus);

  const dispatch = useDispatch();

  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      language: "it",
    }
  });

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = async (address: any) => {
    setValue(address, false);
    const results = await getGeocode({ address });
    const details = await getDetails({ placeId: results[0].place_id, language: "it" });

    if (typeof details === "string") return;

    const latitude = details?.geometry?.location?.lat();
    const longitude = details?.geometry?.location?.lng();
    let city, region, country;
    // if in address_components.types is not included "locality" then select region or country
    if (details.address_components && !details.address_components?.find((item: any) => item.types.includes("locality"))) {
      region = details?.address_components.find((item: any) => item.types.includes("administrative_area_level_1"))?.long_name;
      country = details?.address_components.find((item: any) => item.types.includes("country"))?.long_name;
    }

    city = details?.name;

    dispatch(setCity(city || ""));
    dispatch(setRegion(region || ""));
    dispatch(setCountry(country || ""));

    dispatch(setLatitude(latitude));
    dispatch(setLongitude(longitude));

    setShowPopover(false);
  };

  const renderSearchValue = () => {
    return (
      <>
        {data.map(({ place_id, description }) => (
          <span
            onClick={handleSelectLocation.bind(null, description)}
            key={place_id}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
              <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              <ComboboxOption key={place_id} value={description} style={{
                listStyle: "none",
              }} />
            </span>
          </span>
        ))}
      </>
    );
  };

  return (
    <Combobox onSelect={handleSelectLocation}>
      <div className={`relative flex ${className}`} ref={containerRef}>
        <div
          onClick={() => setShowPopover(true)}
          className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${showPopover ? "nc-hero-field-focused" : ""
            }`}
        >
          <div className="text-neutral-300 dark:text-neutral-400">
            <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
          </div>
          <div className="flex-grow">
            <ComboboxInput
              onChange={(e) => setValue(e.target.value)}
              value={value}
              autoFocus={showPopover}
              ref={inputRef}
              className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
              placeholder="Cerca un indirizzo"
            />
            <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
              <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
            </span>
            {value && showPopover && (
              <ClearDataButton
                onClick={() => {
                  setValue("");
                }}
              />
            )}
          </div>
        </div>

        {showPopover && (
          <div
            className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
          ></div>
        )}

        {showPopover && (
          <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
            {value && renderSearchValue()}
          </div>
        )}
      </div>
    </Combobox>
  );
};

export default LocationInput;
