import { EyeIcon } from "@heroicons/react/24/outline";
import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
}

const InputPsw = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-[1.063rem] font-normal",
      rounded = "rounded-2xl",
      children,
      type = "text",
      ...args
    },
    ref
  ) => {
    const [show, setShow] = React.useState(false);
    const toggleShow = () => setShow(!show);

    return (
      <div className="relative">
        <input
          ref={ref}
          type={show ? "text" : "password"}
          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className}`}
          {...args}
        />
        <EyeIcon
          onClick={toggleShow}
          className="w-5 h-5 text-neutral-500 dark:text-neutral-300 absolute right-3 top-[0.8rem] cursor-pointer hover:opacity-80"
        />
      </div>
    );
  }
);

export default InputPsw;
