import { FC, Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { StarIcon } from '@heroicons/react/24/outline';

interface ReviewModalProps {
  isOpen: boolean;
  closeModal: () => void;
  sendReview: (start: number, comment: string) => void;
  booking: any;
  isLoading?: boolean;
}

const ReviewModal: FC<ReviewModalProps> = ({
  isOpen,
  closeModal,
  sendReview,
  booking,
  isLoading
}) => {
  const stars = [1, 2, 3, 4, 5];
  const [selectedStars, setSelectedStars] = useState<number>(0);
  const [comment, setComment] = useState<string>("");

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div className="fixed inset-0 flex items-center justify-center p-5 md:p-0">
              <div className="bg-white rounded-lg p-10 flex items-start flex-col justify-center">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Scrivi una recensione
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 break-words max-w-md">
                    Scrivi una recensione per la tua esperienza con {booking?.item_type === "property" ? booking?.property?.name : booking?.experience?.title}
                    aiuterai gli altri utenti a scegliere la migliore esperienza per loro.
                  </p>
                </div>
                <div className='mt-4 mb-2 w-full'>
                  <label>
                    Come valuteresti la tua esperienza?
                  </label>
                  <div className="flex flex-row gap-3">
                    {stars.map((star) => (
                      <StarIcon
                        key={star}
                        className="w-8 h-8 text-orange-500"
                        fill={selectedStars >= star ? "rgb(249 115 22" : "white"}
                        onClick={() => setSelectedStars(star)}
                      />
                    ))}
                  </div>
                  <textarea
                    className="w-full h-36 p-4 mt-4 border border-neutral-200 dark:border-neutral-700 rounded-lg focus:ring-2 focus:ring-primary-500 focus:outline-none"
                    placeholder="Scrivi qui la tua recensione"
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
                <div className="mt-4 flex flex-row gap-3">
                  <ButtonSecondary
                    onClick={closeModal}
                  >
                    Chiudi
                  </ButtonSecondary>
                  <ButtonPrimary
                    onClick={() => sendReview(selectedStars, comment)}
                    loading={isLoading}
                    disabled={selectedStars === 0}
                  >
                    Invia recensione
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default ReviewModal;
