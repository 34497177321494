import { Page } from "routers/types";
import { Route, Routes } from "react-router-dom";
import PageAddClientInfo from "containers/PageAddClientInfo/PageAddClientinfo";
import PageAddclientInfo2 from "containers/PageAddClientInfo/PageAddClientinfo2";
import PageAddclientInfo3 from "containers/PageAddClientInfo/PageAddClientinfo3";

const AddHostInfo: Page[] = [
  { path: "/add-info-1", component: PageAddClientInfo },
  { path: "/add-info-2", component: PageAddclientInfo2 },
  { path: "/add-info-3", component: PageAddclientInfo3 },
]

const AddClientInfoPagesRouter = () => {
  return (
    <Routes>
      {AddHostInfo.map(({ component, path }) => {
        const Component = component;
        return <Route key={path} element={<Component />} path={path} />;
      })}
    </Routes>

  )
}

export default AddClientInfoPagesRouter;