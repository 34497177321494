import { StarIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";

interface CommentListingDataType {
  comment: string;
  created_at: Date;
  score: number;
  name: string;
  avatar: string;
}

export interface CommentListingProps {
  className?: string;
  data?: CommentListingDataType;
  hasListingTitle?: boolean;
}


const CommentListing: FC<CommentListingProps> = ({
  className = "",
  data,
  hasListingTitle,
}) => {
  return (
    <div
      className={`nc-CommentListing flex space-x-4 ${className} p-3`}
      data-nc-id="CommentListing"
    >
      <div className="pt-0.5">
        <Avatar
          sizeClass="h-10 w-10 text-lg"
          radius="rounded-full"
          userName={data?.name || ""}
          imgUrl={data?.avatar || ""}
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between space-x-3">
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              <span>{data?.name}</span>
            </div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
              {moment(data?.created_at).fromNow()}
            </span>
          </div>
          <div className="flex text-yellow-500">
            {data && Array.from({ length: data.score }).map((_, index) => (
              <StarIcon className="w-4 h-4" />
            ))}
          </div>
        </div>
        <span className="block mt-3 text-neutral-6000 dark:text-neutral-300">
          {data?.comment}
        </span>
      </div>
    </div>
  );
};

export default CommentListing;
