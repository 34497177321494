import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { useGetMeQuery } from "features/host/hostApiSlice"
import Skeleton from "react-loading-skeleton"
import NotificationBanner from "components/NotificationBanner/NotificationBanner"
import { useEffect } from "react"


const RequireHostAuth = () => {
  const { data: host, isLoading, isFetching } = useGetMeQuery({})

  const location = useLocation()

  const verifyIfHostNeedsToCompleteInfo = () => {
    // check if the host has already completed the info
    const { host_uuid, address, city, postal_code, avatar, country, is_private_host, state, } = host;
    if (!host_uuid || !address || !city || !postal_code || !country || !state) {
      if (location.pathname !== "/host/info/add-info-1" && location.pathname !== "/host/info/add-info-2" && location.pathname !== "/host/info/add-info-3" && location.pathname !== "/host/info/upload") {
        return true
      } else {
        return false
      }
    }
  }

  if (isLoading || isFetching) return null

  return (
    host && !verifyIfHostNeedsToCompleteInfo()
      ? <>
        <Outlet />
        {!host.stripe_id && host.city && host.address && host.postal_code && host.country && host.state &&
          <NotificationBanner
            message='Configuta adesso il tuo account stripe per ricevere i pagamenti dai tuoi ospiti'
            cta='Configura'
            link='/host/stripe/connect-account'
          />
        }
      </>
      : host && verifyIfHostNeedsToCompleteInfo()
        ?
        <Navigate to="/host/info/add-info-1" state={{ from: location }} replace />
        :
        <Navigate to="/login-host" state={{ from: location }} replace />
  )
}
export default RequireHostAuth