import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentproperty, setproperty } from "features/property/propertySlice";
import { useLazyGetAveragePriceQuery } from "features/property/propertyApiSlice";
import Skeleton from "react-loading-skeleton";
import { CurrencyEuroIcon } from "@heroicons/react/24/outline";

export interface PageAddListing8Props { }

const PageAddListing8: FC<PageAddListing8Props> = () => {
  const property = useSelector(selectCurrentproperty)
  const dispatch = useDispatch()

  const [averagePrice, setAveragePrice] = useState<any>()

  const [getAveragePrice, { data, isLoading }] = useLazyGetAveragePriceQuery();

  const handleUpdateProperty = (field: string, value: any) => {
    try {
      dispatch(setproperty({ ...property, [field]: value }))
    }
    catch (e) {
      console.log(e)
    }
  };

  const handleGetAveragePrice = async () => {
    try {
      const city = await property.city;
      const price = await getAveragePrice(city).unwrap();
      setAveragePrice(price?.averagePrice.price);

    } catch (e) {
      console.log(e)
    }
  }

  const validateFields = () => {
    // check if check_in and check_out are valid hours
    const check_in = property?.check_in?.split(":");
    const check_out = property?.check_out?.split(":");
    if (check_in.length !== 2 || check_out.length !== 2) {
      return false;
    }
    if (parseInt(check_in[0]) > 24 || parseInt(check_in[1]) > 60) {
      return false;
    }
    if (parseInt(check_out[0]) > 24 || parseInt(check_out[1]) > 60) {
      return false;
    }
    // check if check_in and check_out have 4 characters
    if (property?.check_in.length !== 5 || property?.check_out.length !== 5) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (property.city.length > 0) {
      handleGetAveragePrice()
    }
  }, [property.city])

  return (
    <CommonLayout
      index="08"
      backtHref="/host/new-property/add-listing-7"
      nextHref="/host/new-property/add-listing-10"
      canGoNext={property.weekday_price?.length > 0 && property.weekend_price?.length > 0 && validateFields()}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Tariffe e condizioni</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            I prezzi variano in base al giorno della settimana e alla stagione.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="w-full">
          {isLoading && <Skeleton height={"2rem"} width={"100%"} />}
          {
            !isLoading && averagePrice && (
              <div className="flex items-center justify-between border-[1px] border-neutral-200 rounded-md p-4">
                <div className="flex flex-row gap-2 items-center">
                  <CurrencyEuroIcon className="w-8 h-8 text-primary-500 dark:text-primary-400" />
                  <span className="text-md font-normal text-neutral-500">Il prezzo consigliato per notte per una prenotazione di almeno 30 giorni a {property.city} è:</span>
                </div>
                <span className="text-lg font-semibold ">€{((averagePrice / 4) / 7).toFixed(2)}</span>
              </div>
            )
          }
        </div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label="Valuta">
            <Select>
              <option value="EUR">EUR</option>
            </Select>
          </FormItem>
          <FormItem label="Prezzo base a notte (Lunedì-Giovedì)">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">€</span>
              </div>
              <Input onChange={(e) => handleUpdateProperty("weekday_price", e.target.value)} className="!pl-8 !pr-10" placeholder="0.00" />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">EUR</span>
              </div>
            </div>
          </FormItem>
          {/* ----- */}
          <FormItem label="Prezzo base a notte (Venerd-Domenica)">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">€</span>
              </div>
              <Input onChange={(e) => handleUpdateProperty("weekend_price", e.target.value)} className="!pl-8 !pr-10" placeholder="0.00" />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">EUR</span>
              </div>
            </div>
          </FormItem>
          <FormItem label="Check-in dalle ore:">
            <div className="relative">
              <Input onChange={(e) => handleUpdateProperty("check_in", e.target.value)} className="!pl-8 !pr-10" placeholder="15:00" />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500"></span>
              </div>
            </div>
            {
              !validateFields() && <span className="text-red-500">Inserisci un orario valido per esempio 15:00 o 15:30</span>
            }
          </FormItem>
          <FormItem label="Check-out dalle ore:">
            <div className="relative">
              <Input onChange={(e) => handleUpdateProperty("check_out", e.target.value)} className="!pl-8 !pr-10" placeholder="11:00" />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500"></span>
              </div>
            </div>
            {
              !validateFields() && <span className="text-red-500">Inserisci un orario valido per esempio 15:00 o 15:30</span>
            }
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing8;
