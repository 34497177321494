import React from 'react';
import { Chat } from '../types/Chat';
import Avatar from 'shared/Avatar/Avatar';
import { ChatBubbleLeftEllipsisIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';

interface ChatBodyHeaderProps {
  chat: Chat;
  currentUserId: String
}

const ChatBodyHeader: React.FC<ChatBodyHeaderProps> = ({ chat, currentUserId }) => {
  // Implement the component logic here

  // console.log( "Inspecing Chat", {chat, currentUserId} );
  let currentUserType = chat.host_uuid === currentUserId ? 'host' : 'client';

 
  let chatHeaderName = '';
  let chatHeaderImg = '';
  if( currentUserType === 'client' ){
    chatHeaderName = chat.host_name;
    chatHeaderImg = chat.host_avatar;
  }else{
    chatHeaderName = chat.client_name;
    chatHeaderImg = chat.client_avatar;
  }

  return (
    // JSX code for the component's UI goes here
    <div className='w-full z-[1] bg-white top-0 flex-row flex items-center justify-between border-b-neutral-200 border-b-[1px] py-5 absolute pl-10 md:pl-0'>
      <div className='flex flex-row gap-6 items-center'>
        <Avatar
          imgUrl={chat?.client_avatar}
          sizeClass='h-11 w-11'
        />
        <h1 className='text-xl font-bold'>{chat?.client_name}</h1>
      </div>
      <EllipsisVerticalIcon className='w-6 h-6' />
    </div>
  );
};

export default ChatBodyHeader;
