import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionValues from "components/SectionValues/SectionValues";
import SectionPartners from "components/SectionPartners/SectionPartners";
import SectionHeroAnimated from "./SectionHeroAnimated";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Chi siamo | TRIBYOU</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHeroAnimated
          rightImg={"https://images.unsplash.com/photo-1540553016722-983e48a2cd10?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
          heading="Your places for:"
          animatedWords={["work", "live", "rest", ]}
          btnText=""
          subHeading="TribYou è una piattaforma per facilitare la ricerca e la prenotazione di destinazioni, strutture, servizi ed esperienze. Con pochi click potrai pianificare i tuoi soggiorni, personalizzarli utilizzando il calendario come planning per te e le persone che viaggiano con te, contando sul nostro algoritmo di analisi e le nostre selezioni verificate. Luoghi per lavorare, vivere, riposare, scoprire, arricchirsi, condividere, divertirsi, conoscere. Trova i tuoi luoghi"
        />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        <SectionHero
          rightImg={"https://images.unsplash.com/photo-1522202176988-66273c2fd55f?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
          heading="Ci crediamo perché..."
          btnText=""
          subHeading="Per noi il futuro fonda le proprie radici nella somma di scelte e azioni che mettiamo in campo oggi. Per chiunque, come noi, aspiri ad una società più inclusiva, efficiente e sostenibile, è arrivato il momento di agire per definirne i contorni. Contribuiamo tutti alla crescita delle opportunità racchiuse all’interno dei nostri fantastici borghi, e mettiamole al servizio dei lavoratori e delle lavoratrici di tutto il mondo per far emergere il loro talento. Prendiamo esempio dalla natura e creiamo un sistema di intelligenza diffusa che faccia rifiorire tutto il tessuto sociale."
        />
        <SectionSubscribe2 />
        <SectionHero
          rightImg={"https://images.unsplash.com/photo-1525026198548-4baa812f1183?q=80&w=3134&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
          heading="Da dove nasce TribYou"
          btnText=""
          subHeading="Nasce per valorizzare territori, per unire persone, per non dimenticare le nostre origini, per scoprire luoghi, per facilitare la ricerca, per farvi trovare posti dei sogni, per soddisfare i vostri bisogni, per una dimensione più umana. TribYou nasce da tutti noi. TribYou siamo tutti noi, Community accomunate da passioni, ambizioni, valori e luoghi. La nostra storia è giovane ma allo stess tempo densa di esperienza dall'unione di professionisti che hanno partecipato a progetti, startup e corporate in ambito turistico, culturale, digitali e della consulenza aziendale. Una storia che vogliamo continuare a scrivere con te."
        />
        
        <div className="relative py-16">
          <BackgroundSection />
          <SectionValues uniqueClassName="SectionValues_" />
        </div>
        <SectionHero
          rightImg={"https://images.unsplash.com/photo-1654355252504-42c1c9dd1fe0?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
          heading="A chi ci rivolgiamo"
          btnText=""
          subHeading="Rivolgiamo la nostra soluzione ad utenti privati e aziendali che ricerchino uno strumento di pianificazione e prenotazione per le proprie trasferte, work-holiday, team retreat, off-site, vacanze, weekend. Ci rivolgiamo ad una serie di operatori che ricerchino un nuovo canale per raggiungere utenti e aziende; un elenco non esauriente di operatori sono: Destinazioni, DMC e DMO, Tour Operator, Agenzie di Viaggio, Strutture alberghiere ed extra-alberghiere, Spazi di Co-working, fornitori di servizi ed esperienze, ristorazione, guide turistiche."
        />
        <SectionPartners />
        
      </div>
    </div>
  );
};

export default PageAbout;
