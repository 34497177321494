import React, { Fragment, FC, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { GuestsObject } from "components/HeroSearchForm/type";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";

export interface GuestsInputProps {
  className?: string;
  shadow?: boolean;
  showButtonInside?: boolean;
  onChange: (guests: GuestsObject) => void;
  guests: GuestsObject;
}

const GuestsInput: FC<GuestsInputProps> = ({ className = "flex-1", shadow, showButtonInside, onChange, guests }) => {
  const [guestAdults, setGuestAdults] = useState<number>(guests.guestAdults || 1);
  const [guestChildren, setGuestChildren] = useState<number>(guests.guestChildren || 0);
  const [guestInfants, setGuestInfants] = useState<number>(guests.guestInfants || 0);

  const [show, setShow] = useState(false);

  const handleChangeGuests = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdults,
      guestChildren: guestChildren,
      guestInfants: guestInfants,
    };
    if (type === "guestAdults") {
      newValue.guestAdults = value;
      setGuestAdults(value);
    }
    if (type === "guestChildren") {
      newValue.guestChildren = value;
      setGuestChildren(value);
    }
    if (type === "guestInfants") {
      newValue.guestInfants = value;
      setGuestInfants(value);
    }
  };

  const totalGuests = guestAdults + guestChildren + guestInfants;

  const handleClose = () => {
    if (show) {
      onChange({
        guestAdults: guestAdults,
        guestChildren: guestChildren,
        guestInfants: guestInfants,
      });
    }
    setShow(!show);
  }

  return (
    <Popover className={`flex  ${className}`}>
      {({ open, close }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none rounded-b-3xl ${open ? !shadow ? "shadow-none" : "shadow-lg" : ""
              }`}
          >
            <Popover.Button
              onClick={() => handleClose()}
              className={`relative z-10 flex-1 flex text-left items-center space-x-3 focus:outline-none`}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} Ospiti
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? "Ospiti" : "Aggiungi ospiti"}
                </span>
              </div>

            </Popover.Button>
          </div>

          <Transition
            as={"div" as any}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            show={show}
          >
            <Popover.Panel static className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5 ">
              {
                !!totalGuests && showButtonInside && (
                  <div className="absolute right-0 -top-2">
                    <Popover.Button
                      className={`relative z-10 flex-1 flex text-left items-center p-3 space-x-3 focus:outline-none`}
                    >
                      <ClearDataButton
                        onClick={() => handleClose()}
                      />
                    </Popover.Button>
                  </div>
                )
              }
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdults}
                onChange={(value) => handleChangeGuests(value, "guestAdults")}
                max={10}
                min={1}
                label="Adulti"
                desc="Più di 18 anni"
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestChildren}
                onChange={(value) => handleChangeGuests(value, "guestChildren")}
                max={4}
                label="Bambini"
                desc="Tra 2 e 17 anni"
              />

              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestInfants}
                onChange={(value) => handleChangeGuests(value, "guestInfants")}
                max={4}
                label="Neonati"
                desc="Meno di 2 anni"
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
