export const provinceItaliane = [
  { value: "ag", label: "Agrigento" },
  { value: "al", label: "Alessandria" },
  { value: "an", label: "Ancona" },
  { value: "ao", label: "Aosta" },
  { value: "ar", label: "Arezzo" },
  { value: "ap", label: "Ascoli Piceno" },
  { value: "at", label: "Asti" },
  { value: "av", label: "Avellino" },
  { value: "ba", label: "Bari" },
  { value: "bt", label: "Barletta-Andria-Trani" },
  { value: "bl", label: "Belluno" },
  { value: "bn", label: "Benevento" },
  { value: "bg", label: "Bergamo" },
  { value: "bi", label: "Biella" },
  { value: "bo", label: "Bologna" },
  { value: "bz", label: "Bolzano" },
  { value: "bs", label: "Brescia" },
  { value: "br", label: "Brindisi" },
  { value: "ca", label: "Cagliari" },
  { value: "cl", label: "Caltanissetta" },
  { value: "cb", label: "Campobasso" },
  { value: "ci", label: "Carbonia-Iglesias" },
  { value: "ce", label: "Caserta" },
  { value: "ct", label: "Catania" },
  { value: "cz", label: "Catanzaro" },
  { value: "ch", label: "Chieti" },
  { value: "co", label: "Como" },
  { value: "cs", label: "Cosenza" },
  { value: "cr", label: "Cremona" },
  { value: "kr", label: "Crotone" },
  { value: "cn", label: "Cuneo" },
  { value: "en", label: "Enna" },
  { value: "fm", label: "Fermo" },
  { value: "fe", label: "Ferrara" },
  { value: "fi", label: "Firenze" },
  { value: "fg", label: "Foggia" },
  { value: "fc", label: "Forlì-Cesena" },
  { value: "fr", label: "Frosinone" },
  { value: "ge", label: "Genova" },
  { value: "go", label: "Gorizia" },
  { value: "gr", label: "Grosseto" },
  { value: "im", label: "Imperia" },
  { value: "is", label: "Isernia" },
  { value: "sp", label: "La Spezia" },
  { value: "aq", label: "L'Aquila" },
  { value: "lt", label: "Latina" },
  { value: "le", label: "Lecce" },
  { value: "lc", label: "Lecco" },
  { value: "li", label: "Livorno" },
  { value: "lo", label: "Lodi" },
  { value: "lu", label: "Lucca" },
  { value: "mc", label: "Macerata" },
  { value: "mn", label: "Mantova" },
  { value: "ms", label: "Massa-Carrara" },
  { value: "mt", label: "Matera" },
  { value: "vs", label: "Medio Campidano" },
  { value: "me", label: "Messina" },
  { value: "mi", label: "Milano" },
  { value: "mo", label: "Modena" },
  { value: "mb", label: "Monza e Brianza" },
  { value: "na", label: "Napoli" },
  { value: "no", label: "Novara" },
  { value: "nu", label: "Nuoro" },
  { value: "og", label: "Ogliastra" },
  { value: "ot", label: "Olbia-Tempio" },
  { value: "or", label: "Oristano" },
  { value: "pd", label: "Padova" },
  { value: "pa", label: "Palermo" },
  { value: "pr", label: "Parma" },
  { value: "pv", label: "Pavia" },
  { value: "pg", label: "Perugia" },
  { value: "pu", label: "Pesaro e Urbino" },
  { value: "pe", label: "Pescara" },
  { value: "pc", label: "Piacenza" },
  { value: "pi", label: "Pisa" },
  { value: "pt", label: "Pistoia" },
  { value: "pn", label: "Pordenone" },
  { value: "pz", label: "Potenza" },
  { value: "po", label: "Prato" },
  { value: "rg", label: "Ragusa" },
  { value: "ra", label: "Ravenna" },
  { value: "rc", label: "Reggio Calabria" },
  { value: "re", label: "Reggio Emilia" },
  { value: "ri", label: "Rieti" },
  { value: "rn", label: "Rimini" },
  { value: "rm", label: "Roma" },
  { value: "ro", label: "Rovigo" },
  { value: "sa", label: "Salerno" },
  { value: "ss", label: "Sassari" },
  { value: "sv", label: "Savona" },
  { value: "si", label: "Siena" },
  { value: "sr", label: "Siracusa" },
  { value: "so", label: "Sondrio" },
  { value: "ta", label: "Taranto" },
  { value: "te", label: "Teramo" },
  { value: "tr", label: "Terni" },
  { value: "to", label: "Torino" },
  { value: "tp", label: "Trapani" },
  { value: "tn", label: "Trento" },
  { value: "tv", label: "Treviso" },
  { value: "ts", label: "Trieste" },
  { value: "ud", label: "Udine" },
  { value: "va", label: "Varese" },
  { value: "ve", label: "Venezia" },
  { value: "vb", label: "Verbano-Cusio-Ossola" },
  { value: "vc", label: "Vercelli" },
  { value: "vr", label: "Verona" },
  { value: "vv", label: "Vibo Valentia" },
  { value: "vi", label: "Vicenza" },
  { value: "vt", label: "Viterbo" }
];

