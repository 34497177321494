import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useLoadScript } from "@react-google-maps/api";
import React, { useState, useEffect, useRef, FC } from "react";
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { useDispatch } from "react-redux";
import { setCity, setCountry, setLatitude, setLongitude, setRegion } from "features/search/searchSlice";

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
}

const LocationInput: FC<Props> = ({
  onChange = () => { },
  className = "",
  defaultValue = "United States",
  headingText = "Dove?",
}) => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      language: "it",
    }
  });


  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const dispatch = useDispatch();

  const handleSelectLocation = async (address: any) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(address);
      onChange && onChange(address);
    }, 0);

    const results = await getGeocode({ address });
    const details = await getDetails({ placeId: results[0].place_id, language: "it" });
    let city, region, country;
    if (typeof details === "string") return;

    const latitude = details?.geometry?.location?.lat();
    const longitude = details?.geometry?.location?.lng();

    if (details.address_components && !details.address_components?.find((item: any) => item.types.includes("locality"))) {
      region = details?.address_components.find((item: any) => item.types.includes("administrative_area_level_1"))?.long_name;
      country = details?.address_components.find((item: any) => item.types.includes("country"))?.long_name;
    }

    city = details?.name;

    if (city !== region) {
      dispatch(setCity(city || ""));
    } else {
      dispatch(setCity(""));
    }
    dispatch(setCountry(country || ""));
    dispatch(setRegion(region || ""));
    dispatch(setLatitude(latitude || 0));
    dispatch(setLongitude(longitude || 0));
  };

  const renderSearchValues = () => {
    return (
      <>
        <p className="block font-semibold text-base">
          Destinazioni
        </p>
        <div className="mt-3">
          {data.map(({ place_id, description }) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                onClick={() => handleSelectLocation(description)}
                key={place_id}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <ComboboxOption key={place_id} value={description} style={{
                  listStyle: "none",
                }} />
              </div>
            );
          })}
        </div>
      </>
    );
  };


  return (
    <div className={`${className}`} ref={containerRef}>
      <Combobox onSelect={() => null}>
        <div className="p-5">
          <span className="block font-semibold text-xl sm:text-2xl">
            {headingText}
          </span>
          <div className="relative mt-5">
            <ComboboxInput
              value={value}
              onChange={(e) => {
                setValue(e.currentTarget.value);
              }}
              ref={inputRef}
              className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
              placeholder={"Cerca destinazioni"}
            />
            <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
              <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
            </span>
          </div>
          <div className="mt-7">
            {value
              ? renderSearchValues()
              : null}
          </div>
        </div>
      </Combobox>
    </div>
  );
};

export default LocationInput;
