import React, { useEffect, useState } from "react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Property } from "types/Property";
import { useContext } from 'react';
import { PageAddListingContextProvider } from '../../contexts/PageAddListingContext'
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import useWindowSize from "hooks/useWindowResize";
import { setBooking, setBookings } from "features/bookings/bookingsSlice";
import { setEvents } from "features/calendar/calendarSlice";
import { useDispatch } from "react-redux";

export interface CommonLayoutProps {
  index: string;
  nextHref?: string;
  backtHref?: string;
  nextBtnText?: string;
  children: React.ReactNode;
  data?: Property
  canGoNext?: boolean
}

const CommonLayout: FC<CommonLayoutProps> = ({
  index,
  children,
  nextHref,
  nextBtnText,
  backtHref,
  data,
  canGoNext
}) => {
  const { width } = useWindowSize();

  const navigate = useNavigate()

  return (
    <div className="">
      <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse justify-between mb-5 px-[5%] py-[2rem] bg-gray-100 ">
        {
          width > 1045 &&
          <ButtonSecondary
            onClick={() => navigate(backtHref || "/")}
          >
            <ArrowLeftIcon className="w-5 h-5 mr-2" />
            Indietro
          </ButtonSecondary>
        }
        <div className="items-center sm:flex sm:space-x-8 md:space-y-0 rtl:space-x-reverse space-y-5">
          <li className={`flex items-center ${index === "1" ? "text-primary-500" : "text-gray-500"}  space-x-2.5 rtl:space-x-reverse`}>
            <span className={`flex items-center justify-center w-8 h-8 border  ${index === "1" ? "border-primary-500" : "border-gray-500"}  rounded-full shrink-0 dark:border-primary-500`}>
              1
            </span>
            <span>
              <h3 className="font-medium leading-tight">Esperienze</h3>
              <p className="text-sm">Scegli le esperienze durante il soggiorno</p>
            </span>
          </li>
          <li className={`flex items-center  ${index === "2" ? "text-primary-500" : "text-gray-500"} space-x-2.5  rtl:space-x-reverse`}>
            <span className={`flex items-center justify-center w-8 h-8 border  ${index === "2" ? "border-primary-500" : "border-gray-500"} rounded-full shrink-0 dark:border-gray-400`}>
              2
            </span>
            <span>
              <h3 className="font-medium leading-tight">Il tuo pacchetto</h3>
              <p className="text-sm">Rivedi il pacchetto che stai acquistando</p>
            </span>
          </li>
          <li className={`flex items-center  ${index === "3" ? "text-primary-500" : "text-gray-500"} space-x-2.5  rtl:space-x-reverse`}>
            <span className={`flex items-center justify-center w-8 h-8 border  ${index === "3" ? "border-primary-500" : "border-gray-500"} rounded-full shrink-0 dark:border-gray-400`}>
              3
            </span>
            <span>
              <h3 className="font-medium leading-tight">Pagamento</h3>
              <p className="text-sm">Scegli come pagare</p>
            </span>
          </li>
        </div>
        {
          nextHref && width > 1045 ?
            <ButtonPrimary
              onClick={() => navigate(nextHref || "/")}
              disabled={!canGoNext}
            >
              {nextBtnText || "Continua"}
              <ArrowRightIcon className="w-5 h-5 ml-2" />
            </ButtonPrimary>
            :
            <div>

            </div>
        }
      </ol>
      <div className="">
        {children}
      </div>
      {
        width <= 1045 &&
        <div className="flex items-center flex-row justify-between bg-white w-full fixed bottom-0 py-3 sm:py-3 px-5 z-[99] border-t border-neutral-200 dark:border-neutral-6000">
          <ButtonSecondary
            onClick={() => navigate(backtHref || "/")}
          >
            <ArrowLeftIcon className="w-5 h-5 mr-2" />
            Indietro
          </ButtonSecondary>
          {
            nextHref && width <= 1045 &&
            <ButtonPrimary
              onClick={() => navigate(nextHref || "/")}
              disabled={!canGoNext}
            >
              {nextBtnText || "Continua"}
              <ArrowRightIcon className="w-5 h-5 ml-2" />
            </ButtonPrimary>
          }
        </div>
      }
    </div>
  );
};

export default CommonLayout;
