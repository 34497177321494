import { FC, Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

interface RefundModalProps {
  isOpen: boolean;
  closeModal: () => void;
  cancelBooking: () => void;
  booking: any;
  isLoading?: boolean;
}

const BookingModal: FC<RefundModalProps> = ({
  isOpen,
  closeModal,
  cancelBooking,
  booking,
  isLoading
}) => {

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div className="fixed inset-0 flex items-center justify-center p-5 md:p-0">
              <div className="bg-white rounded-lg p-10 flex items-start flex-col justify-center">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Cancella prenotazione
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 break-words max-w-md">
                    Sei sicuro di voler annullare la prenotazione per il {moment(booking?.check_in).format("DD/MM/YYYY")}?
                    Annullando la prenotazione, il cliente riceverà un rimborso completo.
                  </p>
                </div>
                <div className="mt-4 flex flex-row gap-3">
                  <ButtonSecondary
                    onClick={closeModal}
                  >
                    Chiudi
                  </ButtonSecondary>
                  <ButtonPrimary
                    onClick={cancelBooking}
                    loading={isLoading}
                  >
                    Annulla prenotazione
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default BookingModal;
