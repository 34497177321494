import { ArrowRightIcon, TrashIcon } from '@heroicons/react/24/outline';
import StayCard from 'components/StayCard/StayCard';
import MobileFooterSticky from 'containers/ListingDetailPage/(components)/MobileFooterSticky';
import { selectBookings, setBookings } from 'features/bookings/bookingsSlice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Heading from 'shared/Heading/Heading';

interface MyTripPageProps {
  // Define your props here
}

const MyTripPage: React.FC<MyTripPageProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const bookings = useSelector(selectBookings);

  const handleRemove = (property_uuid: string) => {
    // remove from bookings list by property_uuid
    const newBookings = bookings.filter((booking: any) => booking?.property?.property_uuid !== property_uuid);
    // update bookings list
    dispatch(setBookings(newBookings));
  }

  const handleCalculateTotal = () => {
    let total = 0;
    bookings.forEach((booking: any) => {
      total += Number(booking.property.weekday_price);
    });
    return total
  }

  const handleStartBooking = () => {
    navigate("/client/booking/calendar");
  }

  const total = handleCalculateTotal();

  return (
    <div>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-8 flex flex-col gap-4">
        <Heading
          desc={"Tutte le strutture che andrai a prenotare procedendo al checkout"}
          className='m-0'
        >
          Il mio viaggio
        </Heading>

        {
          bookings.map((booking: any) => {
            return (
              <div>
                <StayCard key={booking.id} data={booking.property} loading={false} />
                <span
                  className='flex flex-row items-center justify-center text-red-500 my-4 gap-2 bg-neutral-100 dark:bg-neutral-800 dark:text-neutral-200 py-2 px-2 rounded-full cursor-pointer hover:opacity-80 w-[8rem]'
                  onClick={() => handleRemove(booking?.property?.property_uuid)}
                >
                  <TrashIcon className="h-5 w-5" />
                  <span className='mt-1'>
                    Rimuovi
                  </span>
                </span>
              </div>
            )
          })
        }

        {
          bookings.length === 0 && (
            <div className='flex flex-col items-center justify-center gap-4'>
              <span>
                Non hai ancora aggiunti nessuna struttura
              </span>
              <ButtonPrimary
                onClick={() => window.location.href = '/'}
              >
                Esplora
              </ButtonPrimary>
            </div>
          )
        }
        {
          bookings.length > 0 &&
          <div className='fixed bottom-[4rem] px-5 right-0 w-full flex justify-end'>
            <div className='flex flex-row items-center'>
              <ButtonPrimary
                onClick={() => handleStartBooking()}
              >
                Scegli le esperienze
                <ArrowRightIcon className="h-5 w-5 ml-2" />
              </ButtonPrimary>
            </div>
          </div>
        }

      </div>
    </div>
  );
};

export default MyTripPage;
