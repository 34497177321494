import { Dialog, Tab, Transition } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import moment from "moment";
import { useChangeHostPasswordMutation, useGetMeQuery, useUpdateHostSelfMutation } from "features/host/hostApiSlice";
import { useDispatch } from "react-redux";
import { setHost } from "features/host/hostSlice";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useLazyGetReviewsByHostUUIDQuery } from "features/review/reviewApiSlice";
import Pagination from "shared/Pagination/Pagination";
import Input from "shared/Input/Input";
import WorkCategories from "containers/PageAddClientInfo/WorkCategories";
import { set } from "lodash";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Textarea from "shared/Textarea/Textarea";
import { toast } from "react-toastify";
import { EyeIcon } from "@heroicons/react/24/outline";
import InputPsw from "shared/InputPsw/InputPsw";

export interface AuthorPageProps {
  className?: string;
}

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const [fetchReviews, { data: reviews, isLoading: isLoadingReviews, isSuccess: isSuccessReviews }] = useLazyGetReviewsByHostUUIDQuery();
  const [updateHostSelf, { data, isLoading: updatingHost }] = useUpdateHostSelfMutation({})
  const [changePassword, { data: changePasswordData, isLoading: changingPassword }] = useChangeHostPasswordMutation({})
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")


  const { data: host, isLoading, isError } = useGetMeQuery({})

  const dispatch = useDispatch()
  const [hostUpdated, setHostUpdated] = useState<any>({})
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (host) {
      dispatch(setHost({ ...host }))
    }
  }, [isLoading]);

  useEffect(() => {
    fetchReviews({ host_uuid: host?.host_uuid, page: 1 })
  }, [host])

  let [categories] = useState(["Pacchetti", "Strutture", "Esperienze"]);

  const handleUpdateHost = (field: string, value: any) => {
    let newHost = { ...host, [field]: value }
    setHostUpdated(newHost);
  }

  const handleChangePassword = async () => {
    try {
      const changePsw = await changePassword({ oldPassword, newPassword }).unwrap();
      toast.success("Password cambiata con successo")
    }
    catch (err) {
      console.log(err);
      toast.error("Errore nel cambio della password")
    }
  }

  const submitUpdateHost = async (e: any) => {
    try {
      e.preventDefault()
      updateHostSelf({ host: hostUpdated }).unwrap();
      dispatch(setHost({ ...hostUpdated }))
      toast.success("Dati aggiornati con successo")
    }
    catch (err) {
      toast.error("Errore nell'aggiornamento dei dati")
      console.log(err)
    }
  }


  const checkDisabled = () => {
    // if all fields are the same as the original host, then disable the button, check if all fields are filled
    if (hostUpdated.name === host.name && hostUpdated.email === host.email && hostUpdated.phone === host.phone && hostUpdated.description === host.description && hostUpdated.city === host.city && hostUpdated.address === host.address) {
      return true
    }
    if (hostUpdated.name === "" || hostUpdated.email === "" || hostUpdated.phone === "" || hostUpdated.description === "" || hostUpdated.city === "" || hostUpdated.address === "") {
      return true
    }
    return false
  }


  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={host.avatar ? host.avatar : ""}
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{host.name}</h2>
          <StartRating point={host.rating?.toFixed(1)} reviewCount={host.reviews_count} className="!text-base" />
        </div>

        {/* ---- */}
        <p className="text-neutral-500 dark:text-neutral-400">
          {host.description}
        </p>

        {/* ---- */}
        {host.socials &&
          <SocialsList
            className="!space-x-3"
            itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
          />
        }

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300 capitalize">
              {host.city} - {host.state}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            {
              host.created_at &&
              <span className="text-neutral-6000 dark:text-neutral-300">
                host TRIBYOU da {moment(host.created_at).format("DD MMM YYYY")}
              </span>
            }
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Servizi</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Questi sono i servizi che offri ai tuoi clienti. Puoi combinare strutture e esperienze insieme per creare pacchetti e offrire unicità ai tuoi clienti.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${selected
                        ? "bg-secondary-900 text-secondary-50 "
                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_STAY_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <StayCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Scopri di più</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 4).map(
                    (stay) => (
                      <ExperiencesCard key={stay.id} data={stay} />
                    )
                  )}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Scopri di più</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_CAR_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <CarCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Scopri di più</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Recensioni ({Math.round(reviews?.data?.length * reviews?.totalPages)} recensioni)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {
            reviews?.data?.map((review: any) => (
              <CommentListing key={review.id} data={review} />
            ))
          }
        </div>
        <div className="w-full items-center justify-center flex">
          <Pagination
            totalPages={reviews?.totalPages || 0}
            currentPage={reviews?.currentPage || 0}
            onPageChange={(page) => fetchReviews({ host_uuid: host?.host_uuid, page })}
          />
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        {/* form to change data */}
        <h2 className="text-3xl font-semibold">Gestisci i tuoi dati</h2>
        <form onSubmit={(e) => submitUpdateHost(e)}>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Nome
              </label>
              <Input
                id="name"
                name="name"
                type="text"
                placeholder="Nome"
                defaultValue={host.name}
                onChange={(e) => handleUpdateHost("name", e.target.value)}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Email
              </label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                defaultValue={host.email}
                onChange={(e) => handleUpdateHost("email", e.target.value)}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Descrizione
              </label>
              <Textarea
                id="description"
                name="description"
                placeholder="Descrizione"
                onChange={(e) => handleUpdateHost("description", e.target.value)}
                defaultValue={host.description}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Città
              </label>
              <Input
                id="city"
                name="city"
                type="text"
                placeholder="Città"
                onChange={(e) => handleUpdateHost("city", e.target.value)}
                defaultValue={host.city}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="address"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Indirizzo
              </label>
              <Input
                id="address"
                name="address"
                type="text"
                placeholder="Indirizzo"
                onChange={(e) => handleUpdateHost("address", e.target.value)}
                defaultValue={host.address}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="address"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Codice postale
              </label>
              <Input
                id="postal_code"
                name="postal_code"
                type="text"
                placeholder="Codice postale"
                onChange={(e) => handleUpdateHost("postal_code", e.target.value)}
                defaultValue={host.postal_code}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Telefono
              </label>
              <Input
                id="phone"
                name="phone"
                type="text"
                placeholder="Telefono"
                defaultValue={host.phone}
                onChange={(e) => handleUpdateHost("phone", e.target.value)}
                className="mt-1"
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-end mt-6 gap-6">
            <span
              className="text-primary-6000 cursor-pointer hover:opacity-90"
              onClick={() => setShowModal(true)}
            >
              Cambia password
            </span>
            <ButtonPrimary
              type="submit"
              loading={updatingHost}
              disabled={checkDisabled()}
            >
              Salva
            </ButtonPrimary>
          </div>
        </form>
      </div>
    );
  };
  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Mio profilo | TRIBYOU</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{!isLoading ? renderSidebar() : <Skeleton className="h-[20rem]" />}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {host && !isLoading && !isLoadingReviews ? renderSection2() : <Skeleton className="h-[20rem]" />}
          {host && !isLoading && !isLoadingReviews ? renderSection3() : <Skeleton className="h-[20rem]" />}

        </div>
      </main>
      <>
        <Transition.Root show={showModal} as={Fragment}>
          <Dialog as="div" onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-out duration-300"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition ease-in duration-200"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <div className="fixed inset-0 flex items-center justify-center p-5 md:p-0">
                <div className="bg-white rounded-lg p-10 flex items-start flex-col justify-center w-full md:w-[30rem]">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Cambia la tua password
                  </Dialog.Title>
                  <div className="w-full my-3 relative">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    >
                      Password attuale
                    </label>
                      <InputPsw
                        id="oldPassword"
                        onChange={(e) => setOldPassword(e.target.value)}
                        name="oldPassword"
                        type="password"
                        placeholder="Password"
                        className="mt-1"
                      />
                    <label
                      htmlFor="newPassword"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mt-2"
                    >
                      Nuova password
                    </label>
                    <InputPsw
                      id="newPassword"
                      onChange={(e) => setNewPassword(e.target.value)}
                      name="newPassword"
                      type="password"
                      placeholder="Nuova password"
                      className="mt-1"
                    />
                  </div>
                  <div className="mt-4 flex flex-row gap-3">
                    <ButtonSecondary
                      onClick={() => setShowModal(false)}
                    >
                      Chiudi
                    </ButtonSecondary>
                    <ButtonPrimary
                      onClick={handleChangePassword}
                      loading={changingPassword}
                    >
                      Cambia password
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      </>
    </div>
  );
};

export default AuthorPage;
