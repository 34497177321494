import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const SectionFounder = (props: any) => {
  return (
    <div
      className={`nc-SectionHero relative ${props.className}`}
      data-nc-id="SectionHero"
    >
    
    </div>
  );
};

export default SectionFounder;
