import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC, useEffect } from "react";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { usePageAddListingContext } from "contexts/PageAddListingContext";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentproperty, setproperty } from "features/property/propertySlice";

export interface PageAddListing3Props { }

const PageAddListing3: FC<PageAddListing3Props> = () => {
  const property = useSelector(selectCurrentproperty)
  const dispatch = useDispatch()

  const handleUpdateProperty = (field: string, value: any) => {
    try {
      dispatch(setproperty({ ...property, [field]: value }))
    }
    catch (e) {
      console.log(e)
    }
  };



  useEffect(() => {
    dispatch(setproperty({ ...property, square_meters: 100, guests_included: 2, bedrooms: 1, beds: 1, bathrooms: 1, kitchen: 1 }))
  }, [])

  return (
    <CommonLayout
      index="03"
      backtHref="/host/new-property/add-listing-2"
      nextHref="/host/new-property/add-listing-4"
      canGoNext={true}
    >
      <>
        <h2 className="text-2xl font-semibold">Quanto è grande il tuo alloggio?</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label="Media (m2)">
            <Select
              onChange={(e) => handleUpdateProperty("square_meters", Number(e.target.value))}
            >
              <option value="50">50(m2) o meno</option>
              <option value="100">50(m2)-100(m2)</option>
              <option value="200">100(m2)-200(m2)</option>
              <option value="300">200(m2) o più</option>
            </Select>
          </FormItem>
          <NcInputNumber
            label="Ospiti"
            defaultValue={2}
            min={1}
            onChange={(value) => {
              handleUpdateProperty("guests_included", Number(value))
            }}
          />
          <NcInputNumber
            label="Stanze"
            defaultValue={1}
            min={1}
            onChange={(value) => {
              handleUpdateProperty("bedrooms", Number(value))
            }}
          />
          <NcInputNumber
            label="Letti"
            defaultValue={1}
            min={1}
            onChange={(value) => {
              handleUpdateProperty("beds", Number(value))
            }}
          />
          <NcInputNumber
            label="Bagni"
            defaultValue={1}
            onChange={(value) => {
              handleUpdateProperty("bathrooms", Number(value))
            }}
          />
          <NcInputNumber
            label="Cucine"
            defaultValue={1}
            onChange={(value) => {
              handleUpdateProperty("kitchen", Number(value))
            }}
          />
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing3;
