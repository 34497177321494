import { Listbox, Popover, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { setMood, setSearchResults, setSearchType, setTrend } from "features/search/searchSlice";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";

type Type = {
  name: string;
  value: string;
  desc?: string;
}

interface TypeSelectorProps {
  onSelectType: (type: Type) => void;
  defaultType: number;
  className?: string;
}

const MOODS = [
  { name: "Business", value: "business", desc: "Il mood Business è pensato per chi viaggia per lavoro. Lavorare in modo efficiente in una posizione centrale." },
  { name: "Comfort", value: "comfort", desc: "Il mood Comfort è pensato per chi viaggia per piacere o per brevi periodi. Relax e divertimento in una zona comoda." },
  { name: "Authentic", value: "authentic", desc: "Il mood Authentic è pensato per chi viaggia per vivere nuove esperienze e scoprire nuove culture. Esperienza autentica e memorabile in una zona unica." },
];
const TRENDS = [
  { name: "Smart Walking", value: "smart walking", desc: "se sei appassionato di camminate e trekking, ma hai bisogno di luoghi comodi anche per lavorare" },
  { name: "Turismo delle radici", value: "turismo delle radici", desc: "Ricerchi i luoghi delle tue origini, vuoi scoprire le storie autentiche, respirare e assaporare profumi e sapori di un tempo passato" },
  { name: "Smart hub", value: "smart hub", desc: "cerchi spazi flessibili e adattabili alle esigenze del tuo lavoro, che sia un team retreat, una riunione in presenza o in remoto" },
];

const MoodSelector: React.FC<TypeSelectorProps> = ({ onSelectType, className, defaultType }) => {
  const [selected, setSelected] = useState<Type[]>([]);
  const [selectedTrends, setSelectedTrends] = useState<Type[]>([]);
  const dispatch = useDispatch();

  const handleReset = () => {
    setSelected([]);
    setSelectedTrends([]);
  }
  const handleApply = () => {
    dispatch(setMood(selected.map((i) => i.value)));
    dispatch(setTrend(selectedTrends.map((i) => i.value)));
  }

  return (
    <div className={`${className} items-center flex justify-center`}>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center text-base xl:text-lg font-semibold rounded-full bg-white py-2 pl-3 pr-2 text-left ${open ? "!border-primary-500 " : ""
                }`}
            >
              <div className="flex flex-col">
                <span>Mood & Trends</span>
                <span className="text-sm text-neutral-400 font-normal">{selected[0]?.name || selectedTrends[0]?.name}</span>
              </div>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-7 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {MOODS.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          isChecked={selected.includes(item)}
                          subLabel={item.desc}
                          onChange={(e) => {
                            if (e) {
                              setSelectedTrends([]);
                              setSelected([item]);
                            } else {
                              setSelected(selected.filter((i) => i !== item));
                            }
                          }}
                        />
                      </div>
                    ))}
                    {TRENDS.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          isChecked={selectedTrends.includes(item)}
                          subLabel={item.desc}
                          onChange={(e) => {
                            if (e) {
                              setSelected([]);
                              setSelectedTrends([item]);
                            } else {
                              setSelectedTrends(selectedTrends.filter((i) => i !== item));
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <Popover.Button>
                      <ButtonThird onClick={handleReset} sizeClass="px-4 py-2 sm:px-5">
                        reset
                      </ButtonThird>
                    </Popover.Button>
                    <Popover.Button>
                      <ButtonPrimary
                        onClick={handleApply}
                        type="button"
                        sizeClass="px-4 py-2 sm:px-5"
                      >
                        applica
                      </ButtonPrimary>

                    </Popover.Button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>

  );
};

export default MoodSelector;
