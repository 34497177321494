import React from 'react';
import { Message } from '../types/Message';
import moment from 'moment';

interface MessageItemProps {
  message: Message;
  me: string
}

const MessageItem: React.FC<MessageItemProps> = ({ message, me }) => {
  return (
    <div className={
      `w-fit relative min-w-[7rem]
     ${message.sender_uuid === me ? "bg-primary-500" : "bg-neutral-200"}
      ${message.sender_uuid === me ? "!rounded-tl-none" : "!rounded-tr-none"}
      rounded-lg 
     p-3 ${message.sender_uuid === me ? "self-start-" : "self-end"}
     `
    }>
      <p className={`${message.sender_uuid === me ? "text-white" : "text-black"} text-sm`}>
        {message.message}
      </p>
      <p className={`text-xs bottom-1 text-right right-2 ${message.sender_uuid === me ? "text-white" : "text-black"}`}>
        {moment(message.sent_at).format('HH:mm')}
      </p>
    </div >
  );
};

export default MessageItem;
