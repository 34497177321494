import React, { FC, Fragment, useMemo, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Popover, Transition } from '@headlessui/react'
import MoodSlider from "components/MoodSlider/MoodSlider";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SearchModalLocation from "components/SearchModalLocation/SearchModalLocation";
import SearchModalDateSelector from "components/SearchModalDateSelector/SearchModalDateSelector";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface SearchModalProps {
  className?: string;
  show?: boolean;
  closeModal: () => void;
}
const SearchModal: FC<SearchModalProps> = ({ className = "", show, closeModal }) => {
  const [step, setStep] = React.useState(0);
  const [py, setPy] = React.useState("20");

  const navigate = useNavigate();

  const setStepHandler = (step: number) => {
    setStep(step);
  }

  const _handleStep = () => {
    switch (step) {
      case 0:
        return (
          <MoodSlider
            closeModal={() => closeModal()}
          />
          )
    }
  }
  return (
    <Transition
      as={Fragment}
      show={show}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div className="fixed overflow-hidden h-screen w-screen bg-black-transparent z-[99] left-0 top-0 flex items-center justify-center">
        <Popover className={`fixed bottom-0 w-full h-[70%] sm:relative sm:w-90 bg-white shadow-lg lg:w-[45rem] md:w-1/2 min-h-[25rem] rounded-md p-6 md:p-10 flex justify-center items-center flex-col`}>
          <div onClick={closeModal} className="absolute top-5 right-5 cursor-pointer bg-slate-200 rounded-md p-1">
            <XMarkIcon className="w-6 h-6 text-neutral-500" />
          </div>
          {_handleStep()}
        </Popover>
      </div >
    </Transition >
  )
}

export default SearchModal;