import { apiSlice } from "app/api/apiSlice";

export const authClientApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginClient: builder.mutation({
      query: (client) => ({
        url: "/client/login",
        method: "POST",
        body: client,
      }),
      invalidatesTags: ["Client"],
    }),
    registerClient: builder.mutation({
      query: (client) => ({
        url: "/client/sign-up",
        method: "POST",
        body: client,
      }),
      invalidatesTags: ["Client"],
    }),
    resetPswClient: builder.mutation({
      query: (client) => ({
        url: "/client/resetPassword",
        method: "POST",
        body: client,
      }),
      invalidatesTags: ["Client"],
    }),
    createNewPasswordClient: builder.mutation({
      query: (client) => ({
        url: "/client/createNewPassword",
        method: "POST",
        body: client,
      }),
      invalidatesTags: ["Client"],
    })
  })
});


export const {
  useLoginClientMutation,
  useRegisterClientMutation,
  useResetPswClientMutation,
  useCreateNewPasswordClientMutation,
} = authClientApiSlice;