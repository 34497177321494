import { Page } from "routers/types";
import { Route, Routes } from "react-router-dom";
import SiteHeader from "containers/SiteHeader";
import PageHome from "containers/PageHome/PageHome";
import PageLogout from "containers/LogoutPage";
import MainHeader from "components/Header/MainHeader";
import PageBecomeHost from "containers/PageBecomeHost/PageBecomeHost";
import PageLoginHost from "containers/PageLoginHost/PageLoginHost";
import { Host } from "types/Host";
import { useSelector } from "react-redux";
import { selectCurrentHost } from "features/host/hostSlice";
import { useGetMeQuery } from "features/host/hostApiSlice";
import useWindowSize from "hooks/useWindowResize";
import FooterNav from "components/FooterNav";
import FooterNavHost from "components/FooterNavHost";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import PageLogin from "containers/PageLogin/PageLogin";
import CalendarPage from "containers/CalendarPage/CalendarPage";
import PageCalendarStep from "containers/CheckoutSteps/CalendarStep";
import PageLoginClient from "containers/PageLoginClient/PageLoginClient";
import PageSignUpClient from "containers/PageSignUpClient/PageSignUpClient";
import { Client } from "types/Client";
import { selectCurrentclient } from "features/client/clientSlice";
import FooterNavClient from "components/FooterNavClient";
import { selectCurrentLoggedClient } from "features/clientAuth/authClientSlice";
import HostProfile from "containers/HostProfile/HostProfile";
import HostPage from "containers/HostPage/HostPage";
import PageBorgo from "containers/PageBorgo/PageBorgo";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import PageClientResetPsw from "containers/PageClientResetPsw/PageClientResetPsw";
import PageClientChangePsw from "containers/PageClientChangePsw/PageClientChangePsw";
import PageHostResetPsw from "containers/PageHostResetPsw/PageHostResetPsw";
import PageHostChangePsw from "containers/PageHostChangePsw/PageHostChangePsw";
import BorghiPage from "containers/BorghiPage/BorghiPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import PageCloseAccount from "containers/PageCloseAccount/PageCloseAccount";

const MainPages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/about", component: PageAbout },
  { path: "/contacts", component: PageContact },
  { path: "/:defaultType/search/", exact: false, component: ListingStayMapPage },

  { path: "/borghi", exact: false, component: BorghiPage },
  { path: "/borgo/:name", component: PageBorgo },
  { path: "/logout", component: PageLogout },
  { path: "/become-host", component: PageBecomeHost },
  { path: "/login", component: PageLogin },
  { path: "/login-host", component: PageLoginHost },
  { path: "/login-client", component: PageLoginClient },
  { path: "/login-client", component: PageLoginClient },
  { path: "/reset-password-client/", component: PageClientResetPsw },
  { path: "/reset-password-client/:token", component: PageClientChangePsw },
  { path: "/reset-password-host/", component: PageHostResetPsw },
  { path: "/reset-password-host/:token", component: PageHostChangePsw },
  { path: "/close-account", component: PageCloseAccount },
  { path: "/profile/:host_uuid", component: HostProfile },
  { path: "/signup-client", component: PageSignUpClient },
  { path: "/listing-stay-detail/:id", component: ListingStayDetailPage },
  { path: "/author/:host_uuid", component: HostPage },
]

const MainRouter = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const host: Host = useSelector(selectCurrentHost);
  const client: Client = useSelector(selectCurrentLoggedClient);

  return (
    <>
      <SiteHeader navType={client?.client_uuid ? "ClientNav" : ""} />
      <Routes>
        {MainPages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
      </Routes>
      {WIN_WIDTH < 768 ?
        host?.host_uuid ?
          <FooterNavHost />
          :
          client?.client_uuid ?
            <FooterNavClient />
            :
            <FooterNav />
        : null
      }
    </>

  )
}

export default MainRouter;