import React, { forwardRef } from "react";

export interface NavProps {
  className?: string;
  containerClassName?: string;
  children?: React.ReactNode;
}

const Nav = forwardRef<HTMLDivElement, NavProps>(
  ({ className = "flex", containerClassName = "", children }, ref) => {
    return (
      <nav className={containerClassName} ref={ref}>
        <ul className={`nc-Nav ${className}`}>{children}</ul>
      </nav>
    );
  }
);

export default Nav;
