import { apiSlice } from "../../app/api/apiSlice";

export const reviewApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createReview: builder.mutation({
      query: (review) => ({
        url: "/review/create",
        method: "POST",
        body: review,
      }),
    }),
    getReviewByPropertyUUID: builder.query({
      query: ({property_uuid, page}) => ({
        url: `/review/property/${property_uuid}/${page}`,
      }),
    }),
    getReviewsByHostUUID: builder.query({
      query: ({host_uuid, page}) => ({
        url: `/review/host/${host_uuid}/${page}`,
      }),
    }),
  })
});


export const {
  useCreateReviewMutation,
  useGetReviewByPropertyUUIDQuery,
  useLazyGetReviewByPropertyUUIDQuery,
  useGetReviewsByHostUUIDQuery,
  useLazyGetReviewsByHostUUIDQuery
} = reviewApiSlice;