import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

const experienceSlice = createSlice({
  name: "experience",
  initialState: {
    experience: null,
    experiencesList: [],
    editingExperience: null,
  },
  reducers: {
    setExperience: (state, action) => {
      state.experience = action.payload;
    },
    setEditingExperience: (state, action) => {
      state.editingExperience = action.payload;
    },
    setExperiencesList: (state, action) => {
      state.experiencesList = action.payload;
    },
  },
});

export const { setExperience, setEditingExperience, setExperiencesList } = experienceSlice.actions;

export default experienceSlice.reducer;

export const selectCurrentExperience = (state) => state.experience.experience;
export const selectEditingExperience = (state) => state.experience.editingExperience;
export const selectExperiencesList = (state) => state.experience.experiencesList;
