import React from 'react';
import ChatPreviewItem from './ChatPreviewItem';
import { Chat } from '../types/Chat';

interface ChatSidebarProps {
  chats: Chat[];
  handleShowChat: () => void;
}

const ChatSidebar: React.FC<ChatSidebarProps> = (props) => {

  return (
    <div className="chat-sidebar lg:w-[20rem] w-full">
      <h1 className='text-xl font-bold mb-0 p-6'>Messaggi</h1>
      <div className='flex-col flex'>
        {props.chats?.map((chat) => (
          <ChatPreviewItem
            host_name={chat.host_name}
            host_uuid={chat.host_uuid}
            host_avatar={chat.host_avatar}
            chat_uuid={chat.chat_uuid}
            key={chat.chat_uuid}
            handleShowChat={props.handleShowChat}
            previewText={chat.previewContent}
            onClick={() => null}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatSidebar;
