import { Popover, Transition } from '@headlessui/react';
import { selectRange, setRange } from 'features/bookings/bookingsSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Select from 'shared/Select/Select';

interface Props {
  className?: string;
}
const OPTIONS = [
  {
    label: 'Qualsiasi',
    value: '',
  },
  {
    label: '3 giorni',
    value: '3'
  },
  {
    label: '7 giorni',
    value: '7'
  },
  {
    label: '1 mese',
    value: '30'
  }
]

const RangeSelector: React.FC<Props> = ({ className }) => {
  const range = useSelector(selectRange)
  const dispatch = useDispatch();

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setRange(e.target.value))
  }

  return (
    <div className=''>
      <Select
        className={`RangeSelector z-10 flex ${className}`}
        defaultValue={range}
        onChange={handleSelectChange}
      >
        {OPTIONS.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default RangeSelector;
