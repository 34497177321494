import React, { useEffect, useRef } from 'react';
import { Message } from '../types/Message';
import ChatBodyHeader from './ChatBodyHeader';
import { Chat } from '../types/Chat';
import ChatBodyInput from './ChatBodyInput';
import MessageItem from './MessageItem';
import { useSelector } from 'react-redux';
import { selectCurrentChat, selectMessages } from 'features/chat/chatSlice';
import { useSendMessageMutation } from 'features/chat/chatApiSlice';
import "../Chat.css";

interface ChatBodyProps {
  chat: Chat;
  messages: Message[];
  me: any,
  handleOnSubmit: (e: any) => Promise<any>;
  sendingMessage: Boolean,
}

const ChatBody: React.FC<ChatBodyProps> = ({ chat, handleOnSubmit, me, sendingMessage }) => {

  console.log( "Inspecting me", {me} );
  const messages: Message[] = useSelector(selectMessages)
  const chatMessagesContainer = React.useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if( chatMessagesContainer.current !== null ){
      chatMessagesContainer.current.scrollTop = chatMessagesContainer.current.scrollHeight;
    }
  }, [messages])

  return (
    <div className='grow border-l-[1px] border-l-neutral-200 md:py-6 md:px-10 chat-body-container-outter'>
      <div className="relative h-full overflow-hidden overflow-hidden chat-body-container">
          {
            chat &&
            <ChatBodyHeader
              chat={chat}
              currentUserId={me}
            />
          }
          <div className='flex flex-col gap-6 overflow-y-scroll absolute pl-10 md:pl-0 pr-10 py-10 w-full chat-messages-container' ref={chatMessagesContainer}>
            {
              messages?.map((message) => (
                <MessageItem
                  me={me}
                  key={message.message_uuid}
                  message={message}
                />
              ))
            }
          </div>
          <ChatBodyInput
            sendMessage={handleOnSubmit}
            sendingMessage={sendingMessage}
          />
      </div>
    </div>
  );
};

export default ChatBody;
