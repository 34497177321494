import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { PropertyType } from "types/PropertyType";
import { property_type } from "../../constants/";
import { usePageAddListingContext } from '../../contexts/PageAddListingContext'
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentproperty, setproperty } from "features/property/propertySlice";
import { useNavigate } from "react-router-dom";
import Label from "components/Label/Label";

export interface PageAddListing1Props { }

const TRENDS = [
  {
    id: 0,
    title: "Nessuno",
    value: "",
    desc: ""
  },
  {
    id: 1,
    title: "Smart Walking",
    value: "smart walking",
    desc: "se sei appassionato di camminate e trekking, ma hai bisogno di luoghi comodi anche per lavorare",
  },
  {
    id: 2,
    title: "Turismo delle radici",
    value: "turismo delle radici",
    desc: "Ricerchi i luoghi delle tue origini, vuoi scoprire le storie autentiche, respirare e assaporare profumi e sapori di un tempo passato",
  },
  {
    id: 3,
    title: "Smart hub",
    value: "smart hub",
    desc: "cerchi spazi flessibili e adattabili alle esigenze del tuo lavoro, che sia un team retreat, una riunione in presenza o in remoto",
  },
];

const PageAddListing1: FC<PageAddListing1Props> = () => {
  const property = useSelector(selectCurrentproperty)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleUpdateProperty = async (field: string, value: any) => {
    dispatch(setproperty({ ...property, [field]: value }))
  };

  const _updateDefaultFields = async () => {
    dispatch(setproperty({ ...property, property_type: property_type[0].value, rental_from: "entire_space", mood: "business", check_in: "15:00", check_out: "11:00" }))
  }

  useEffect(() => {
    _updateDefaultFields()
    // reset property on state    
  }, []);

  return (
    <CommonLayout
      index="01"
      backtHref="/host/new-property/add-listing-1"
      nextHref={`/host/new-property/add-listing-2`}
      canGoNext={property?.name?.length > 0}
    >
      <>
        <h2 className="text-2xl font-semibold">Carica il tuo annuncio</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Scegli il tipo di proprietà"
            desc=""
          >
            <Select
              defaultValue={property?.property_type}
              onChange={(e) => handleUpdateProperty("property_type", e.target.value)}
            >
              {
                property_type.map((item: PropertyType, index: Number) => (
                  <option key={item.id} value={item.value}>{item.label}</option>
                ))
              }
            </Select>
          </FormItem>
          <FormItem
            label="Nome dell'annuncio"
            desc="Un nome accattivante di solito include: Nome della casa + Destinazione turistica"
          >
            <Input
              defaultValue={property?.name}
              onChange={(e) => handleUpdateProperty("name", e.target.value)}
              placeholder="Nome"
            />
          </FormItem>
          <FormItem
            label="Affitta:"
            desc="Tutto lo spazio: Gli ospiti hanno a disposizione l'intero spazio per loro stessi, con un ingresso privato e senza spazi in comune. Di solito, è inclusa una camera da letto, un bagno e una cucina"
          >
            <Select
              defaultValue={property?.rental_from}
              onChange={(e) => handleUpdateProperty("rental_from", e.target.value)}
            >
              <option value="entire_space">Intero immobile</option>
              <option value="private_room">Una stanza</option>
              <option value="share_room">Una stanza condivisa</option>
            </Select>
          </FormItem>

          <FormItem
            label="Mood:"
            desc="Business:Posizione centrale pensato per chi viaggia per lavoro. Comfort: Posizione comoda pensato per il relax. Authentic: zone autentiche per esperienze uniche."
          >
            <div className="my-2">
              <Label>Seleziona uno di questi mood per far identificare più velocemente il target specifico a cui ti rivolgi. Il tuo annuncio sarà visibile a tutti, ma si potrà caratterizzare e ti potrà aiutare ad attirare il target a te più congeniale</Label>
            </div>
            <Select
              defaultValue={property?.mood}
              onChange={(e) => handleUpdateProperty("mood", e.target.value)}
            >
              <option value="business">Business</option>
              <option value="comfort">Comfort</option>
              <option value="authentic">Authentic</option>
            </Select>
          </FormItem>
          <FormItem
            label="Trends:"
            desc={"Smart Walking:se sei appassionato di camminate e trekking, ma hai bisogno di luoghi comodi anche per lavorare.Turismo delle radici: Ricerchi i luoghi delle tue origini, vuoi scoprire le storie autentiche, respirare e assaporare profumi e sapori di un tempo passato.Smart hub:cerchi spazi flessibili e adattabili alle esigenze del tuo lavoro, che sia un team retreat, una riunione in presenza o in remoto"}
          >
            <Select
              defaultValue={property?.trend}
              onChange={(e) => handleUpdateProperty("trend", e.target.value)}
            >
              {
                TRENDS.map((item) => (
                  <option key={item.id} value={item.value}>{item.title}</option>
                ))
              }
            </Select>
          </FormItem>
        </div>
      </>
    </CommonLayout >
  );
};

export default PageAddListing1;
