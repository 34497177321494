const WorkCategories: string[] = [
  "Settore Tecnologico",
  "Ingegneria e Produzione",
  "Settore Commerciale e Marketing",
  "Settore Finanziario",
  "Risorse Umane",
  "Settore Creativo",
  "Settore Sanitario",
  "Istruzione e Formazione",
  "Settore Legale",
  "Settore Alberghiero e Ristorazione",
  "Altro",
];


export default WorkCategories;
