import React, { FC, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Label from "components/Label/Label";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { selectEditingExperience, setEditingExperience } from "features/experience/experienceSlice";
import { useEditExperienceMutation, useGetExperienceQuery, useLazyGetExperienceQuery } from "features/experience/experienceApiSlice";
import FormItem from "containers/PageAddListing1/FormItem";
import Checkbox from "shared/Checkbox/Checkbox";
import { toast } from 'react-toastify';

export interface EditingAvailabilitiesPageSpaceProps {
  className?: string;
}

const EditingAvailabilities: FC<EditingAvailabilitiesPageSpaceProps> = ({ className = "" }) => {
  const experienceId = useParams<{ id: string }>().id;


  const [updateExperience, { isLoading: updatingExperience }] = useEditExperienceMutation();
  const [getExperience, { isLoading, isError }] = useLazyGetExperienceQuery();

  const editingExperience = useSelector(selectEditingExperience);

  const dispatch = useDispatch();

  const handleupdateExperienceField = (field: string, value: any) => {
    dispatch(setEditingExperience({ ...editingExperience, [field]: value }))
  }

  const notify = async (success: boolean) => {
    if (success) {
      toast.success("Esperienza aggiornato con successo")
    } else {
      toast.error("Errore nell'aggiornamento dell'esperienza")
    }
  }

  const handleupdateExperience = async () => {
    try {
      dispatch(setEditingExperience({ ...editingExperience}))
      const toSend = { ...editingExperience }
      delete toSend.host

      await updateExperience(toSend).unwrap().then((payload) => notify(true))
        .catch((error) => notify(false))
    } catch (err) {
      console.log(err)
    }
  }

  const fetchExperience = async () => {
    try {
      const response = await getExperience(experienceId).unwrap();
      dispatch(setEditingExperience(response.experience))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchExperience()
    return () => {
      dispatch(setEditingExperience(null));
    };
  }, [experienceId]);


  if (isLoading || !editingExperience) {
    <div>
      <div className={`nc-EditListingPage ${className}`} data-nc-id="EditListingPage">
        <CommonLayout experienceId={experienceId}>
          <div className="space-y-6 sm:space-y-8">
            {/* HEADING */}
            <h2 className="text-3xl font-semibold">Informazioni Spazio</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 è-"></div>
            <Skeleton className="w-32 h-32" count={3} />
          </div>
        </CommonLayout>
      </div>
    </div>
  }

  return (
    <div>
      <CommonLayout experienceId={experienceId}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Informazioni spazio</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex-grow mt-10 md:mt-0 max-w-3xl space-y-6">
            <FormItem label="Prezzo per persona">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">€</span>
                </div>
                <Input defaultValue={Number(editingExperience?.price)} onChange={(e) => handleupdateExperienceField("price", e.target.value)} className="!pl-8 !pr-10" placeholder="0.00" type="number" />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">EUR</span>
                </div>
              </div>
            </FormItem>

            <FormItem label="Posti disponibili">
              <Input
                placeholder="Inserisci i posti disponibili per ogni slot"
                type="number"
                defaultValue={editingExperience?.max_guests}
                onChange={(e) => handleupdateExperienceField("max_guests", e.target.value)}
              />
            </FormItem>
            <FormItem label="Disponibilità">
              <Checkbox
                isChecked={editingExperience?.availability === "week"}
                onChange={(e) => handleupdateExperienceField("availability", "week")}
                className="!pl-8 !pr-10 mt-2"
                label="Tutta la settimana"
                name="week"
              />
              <Checkbox
                isChecked={editingExperience?.availability === "weekend"}
                onChange={(e) => handleupdateExperienceField("availability", "weekend")}
                className="!pl-8 !pr-10 mt-2"
                label="Fine settimana"
                name="weekend" />
              <Checkbox
                isChecked={editingExperience?.availability === "weekdays"}
                onChange={(e) => handleupdateExperienceField("availability", "weekdays")}
                className="!pl-8 !pr-10 mt-2"
                label="Solo in settimana"
                name="weekdays"
              />
            </FormItem>
            <FormItem label="Disponibile dalle">
              <Select onChange={(e) => handleupdateExperienceField("available_from", e.target.value)}>
                {
                  Array.from(Array(23).keys()).slice(8).map((item: any, index: Number) => (
                    <option defaultChecked={editingExperience?.available_from === item} key={item} value={item}>{item}:00</option>
                  ))
                }
              </Select>
            </FormItem>
            {
              editingExperience?.available_from && (
                <FormItem label="Disponibile fino alle">
                  <Select onChange={(e) => handleupdateExperienceField("available_to", e.target.value)}>
                    {
                      Array.from(Array(23).keys()).slice(Number(editingExperience?.available_from) + 1).map((item: any, index: Number) => (
                        <option defaultChecked={editingExperience?.available_from === item} key={item} value={item}>{item}:00</option>
                      ))
                    }
                  </Select>
                </FormItem>
              )
            }
            <FormItem label="Durata (in minuti)">
              <Input
                placeholder="Inserisci la durata dell'esperienza in minuti"
                type="number"
                defaultValue={editingExperience?.minutes_duration}
                onChange={(e) => handleupdateExperienceField("minutes_duration", e.target.value)}
              />
            </FormItem>
            <div className="pt-2">
              <ButtonPrimary onClick={handleupdateExperience} loading={isLoading || updatingExperience}>Aggiorna</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default EditingAvailabilities;
