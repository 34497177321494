
import React, { FC, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectBookings } from "features/bookings/bookingsSlice";
import { useStartBookingMutation } from "features/bookings/bookingApiSlice";
import { selectEvents } from "features/calendar/calendarSlice";
import moment from "moment";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Heading from "components/Heading/Heading";
import './carousel.css'
import SummaryDrawer from "./SummaryDrawer";

export interface PageAddListing1Props { }

const SummaryStep: FC<PageAddListing1Props> = () => {
  const bookings = useSelector(selectBookings);
  const events = useSelector(selectEvents);

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedExperiences, setSelectedExperiences] = useState<any>([]);
  const [selectedProperty, setSelectedProperty] = useState<any>({});

  const prevUrl = `/client/booking/calendar`;

  const returnTotalPrice = (bookings: any, events: any) => {
    let total = 0;
    bookings?.map((item: any) => {
      total += Number(item.price);
    })
    events?.map((item: any) => {
      total += Number(item.price);
    })
    return total;
  }

  const handleOpenDrawer = (item: any) => {
    setLoading(true);
    setSelectedProperty(item.property);
    const experiences = events?.filter((event: any) => {
      const eventStartDate = moment(event?.start).format('YYYY-MM-DD');
      if (moment(eventStartDate).isBetween(moment(item?.startDate), moment(item?.endDate), undefined, '[]')) {
        return event;
      }
    }
    )
    setSelectedExperiences(experiences);
    setIsOpen(true);
    setLoading(false);
  }

  return (
    <CommonLayout
      index="2"
      backtHref={prevUrl}
      canGoNext={true}
      nextHref="/client/booking/payment"
    >
      <div className=" flex flex-col lg:px-[10rem] md:py-[2rem] px-[2rem] pt-[1rem] pb-[5rem]">
        <Heading desc="Tutti i pacchetti che hai deciso di prenotare per il tuo viaggio">
          Riepilogo prenotazione
        </Heading>
        <div className="flex flex-row flex-wrap gap-10">
          {
            bookings?.map((item: any, index: number) => {
              let totalEvents = 0;
              let price = Number(item.price);
              return (
                <div className="flex flex-col border-b-[1px] border-b-neutral-200 shadow-lg rounded-md lg:basis-[30%] basis-full">
                  <div className="w-full mb-2">
                    <img src={item?.property?.cover} alt="property" className="w-full h-full rounded-t-md" />
                  </div>
                  <div className="p-4 flex flex-col my-2">
                    <h4 className="text-xl font-medium">
                      Soggiorno presso {item?.property?.name}
                    </h4>
                    <span className="text-gray-500">
                      {item?.property?.description.slice(0, 100)} ...
                    </span>
                    <span className="text-gray-500">
                      {moment(item?.startDate).format('DD/MM/YYYY')} - {moment(item?.endDate).format('DD/MM/YYYY')}
                    </span>
                  </div>

                  <div className="p-4 flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-1">
                      {
                        // map events where start date is between checkIn and checkOut
                        events?.map((event: any) => {
                          price += Number(event.price);
                          const eventStartDate = moment(event?.start).format('YYYY-MM-DD');
                          if (moment(eventStartDate).isBetween(moment(item?.startDate), moment(item?.endDate), undefined, '[]')) {
                            totalEvents++;
                            return (
                              <img src={event?.cover} alt="event" className="w-[2rem] h-[2rem] rounded-full" />
                            )
                          }
                        })
                      }
                    </div>
                    <span className="text-gray-500">
                      {totalEvents} esperienze
                    </span>
                  </div>
                  <div className="flex flex-row justify-between items-center mt-2 pb-2 px-2">
                    <span className="text-lg font-semibold px-3 py-2">
                      Totale: {price}€
                    </span>
                    <ButtonPrimary
                      loading={loading}
                      onClick={() => handleOpenDrawer(item)}
                      className="!py-[0.6rem] "
                    >
                      Dettagli
                    </ButtonPrimary>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <SummaryDrawer
        isOpen={isOpen}
        experiences={selectedExperiences}
        property={selectedProperty}
        closeModal={() => setIsOpen(false)}
      />

    </CommonLayout >
  )
};

export default SummaryStep;
