import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import { useEffect } from "react";
import clientSayMain from "images/clientSayMain.png";
import clientSay1 from "images/clientSay1.png";
import clientSay2 from "images/clientSay2.png";
import clientSay3 from "images/clientSay3.png";
import clientSay4 from "images/clientSay4.png";
import clientSay5 from "images/clientSay5.png";
import clientSay6 from "images/clientSay6.png";
import quotationImg from "images/quotation.png";
import quotationImg2 from "images/quotation2.png";
import useNcId from "hooks/useNcId";

export interface SectionClientSayProps {
  className?: string;
  uniqueClassName: string;
}
const SectionValues: FC<SectionClientSayProps> = ({
  className = "",
  uniqueClassName = "",
}) => {
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useNcId();

  return (
    <div
      className={`nc-SectionClientSay relative ${className} `}
      data-nc-id="SectionClientSay"
    >
      <Heading className="m-0" desc="Crediamo fortemente in una particolare attenzione ai nostri clienti, ai membri delle nostre TribYou attuali e future. Facciamo scoprire luoghi che meritano di essere vissuti. Facilitiamo incontri fra persone che possano arricchire i momenti vissuti nelle nostre destinazioni. Crediamo nel facilitare la vita delle persone individuando luoghi di aggregazione e per lavorare." isCenter>
        I nostri valori
      </Heading>
    </div>
  );
};

export default SectionValues;
