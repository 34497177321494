import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import f1 from "../../images/f1.png"
import f2 from "../../images/f2.png"

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Dati fiscali",
    menus: [
      { href: "#", label: "TRIBYOU è un marchio di HQVILLAGE SB SRL" },
      { href: "#", label: "Sede legale: Via Giordano Bruno 21 - 56025 Pontedera (PI). Numero REA PI-207419" },
      { href: "#", label: "Sede operativa: Via Giovanni Andrea Dell'Anguillara 4 - 01015 Sutri (VT)" },
      { href: "#", label: "P.I 02443490509" },
      { href: "#", label: "Capitale sociale I.V. : 65000€" },
      { href: "#", label: "SCIA Prot. n. 1016 del 11/06/2024, rilasciata dal Comune di Sutri // Polizza RC Generali Italia Spa n° 440749858" },
      { href: "#", label: "Fondo Vacanze Felici S.c.a.r.l. numero di iscrizione 2787" }
    ],
  },
  {
    id: "5",
    title: "Privacy & Terms",
    menus: [
      { href: "https://www.iubenda.com/privacy-policy/67407318", label: "Privacy policy" },
      { href: "https://www.iubenda.com/privacy-policy/67407318/cookie-policy", label: "Cookie policy" },
      { href: "https://tribyou.s3.eu-north-1.amazonaws.com/album/Termini+e+Condizioni+-+TribYou.pdf", label: "Termini e condizioni" },
      { href: "/#/close-account", label: "Cancella il tuo account" }
    ],
  },
  {
    id: "2",
    title: "Con il supporto economico di",
    menus: [
      { href: "#", image: f1 },
      { href: "#", image: f2 },
    ],
  },
  {
    id: "4",
    title: "Supporto & Press",
    menus: [
      { href: "#", label: "help@tribyou.life" },
      { href: "#", label: "press@tribyou.life" },
    ],
  },
  {
    id: "6",
    title: "Altri dati",
    menus: [
      { href: "#", label: "PEC: hqvillagesbsrl@pec.it" },
      { href: "#", label: "Codice univoco: M5UXCR1" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {
                item.label &&
                <a
                  key={index}
                  className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                  href={item.href}
                >
                  {item.label}
                </a>
              }
              {
                item.image &&
                <img className="w-[8rem]" src={item.image} />
              }
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-6 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
