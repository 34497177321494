import React, { useState, FC, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import it from "date-fns/locale/it"; // the locale you want
import moment from "moment";
import "./DatePicker.css";
import { useSelector } from "react-redux";
import { selectRange } from "features/bookings/bookingsSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
export interface StayDatesRangeInputProps {
  className?: string;
  shadow?: boolean;
  centerPicker?: boolean;
  showButtonInside?: boolean;
  startDateParam?: Date | null;
  endDateParam?: Date | null;
  onMonthChange: (date: Date) => void;
  onChange: (value: [Date | null, Date | null]) => void;
  show: boolean;
  setShow: (value: boolean) => void;
  rangeAvailability: any;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "flex-1",
  shadow,
  centerPicker,
  showButtonInside,
  onMonthChange,
  startDateParam,
  endDateParam,
  onChange,
  show,
  setShow,
  rangeAvailability
}) => {
  const [startDate, setStartDate] = useState<Date | null>(startDateParam || new Date("2023/02/20"));
  const [endDate, setEndDate] = useState<Date | null>(endDateParam || new Date("2023/02/25"));

  const range = useSelector(selectRange)

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const endDate = moment(start).add((range - 1), "days").toDate()
    setStartDate(start);
    if (range) {
      setEndDate(endDate);
    } else {
      setEndDate(end);
    }
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("it-IT", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
              endDate?.toLocaleDateString("it-IT", {
                month: "short",
                day: "2-digit",
              })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out"}
          </span>
        </div>
      </>
    );
  };

  const handleClose = () => {

    if (startDate && endDate && show) {
      if (
        startDateParam?.getTime() !== startDate?.getTime() ||
        endDateParam?.getTime() !== endDate?.getTime()
      ) {
        onChange([startDate, endDate])
      }
    }
    setShow(!show);
  }

  function generateBookedDates(availableDaysByMonth: any) {
    const bookedDates: any = [];

    for (const month in availableDaysByMonth) {
      const days = availableDaysByMonth[month];
      days.forEach((day: any) => {
        const dateString = `${new Date().getFullYear()}-${month}-${day}`;
        bookedDates.push({ date: new Date(dateString) });
      });
    }

    return bookedDates;
  }

  return (
    <Popover className={`StayDatesRangeInput z-10 flex ${className} items-center justify-center`}>
      {({ open }) => (
        <>
          <Popover.Button
            onClick={() => handleClose()}
            className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${open ? !shadow ? "shadow-none" : "shadow-lg" : ""
              }`}
          >
            {renderInput()}
            {startDate && open && !showButtonInside && (
              <ClearDataButton onClick={() => onChangeDate([null, null])} />
            )}
          </Popover.Button>

          <Transition
            as={"div" as any}
            show={show}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className={`absolute left-0 ${centerPicker ? "md:translate-x-1/2" : "xl:-right-10"} z-10 mt-3 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl`}>
              {
                startDate && showButtonInside && (
                  <div className="absolute right-0 -top-2">
                    <Popover.Button
                      className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${open ? !shadow ? "shadow-none" : "shadow-lg" : ""
                        }`}
                    >
                      <ClearDataButton onClick={() => handleClose()} />
                    </Popover.Button>
                  </div>
                )
              }
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  locale={it}
                  selectsRange
                  monthsShown={2}
                  shouldCloseOnSelect={true}
                  showPopperArrow={false}
                  excludeDates={generateBookedDates(rangeAvailability)}
                  placeholderText="Select a date other than today or yesterday"
                  inline
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomDay
                      dayOfMonth={day}
                      date={date}
                      rangeAvailability={rangeAvailability}
                    />
                  )}
                />
                <ButtonPrimary
                  onClick={() => handleClose()}
                  className="mt-4"
                >
                  Conferma
                </ButtonPrimary>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;
