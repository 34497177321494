import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import React from 'react';
import { Helmet } from "react-helmet";
import rightImage from "images/hero-right2.png";
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';

interface Props {
  // Define your component's props here
}

const BorghiPage: React.FC<Props> = () => {
  // Your component logic goes here

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Borghi</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden p-5 md:p-0">
        <div
          className={`nc-SectionHeroArchivePage flex flex-col relative md:pb-[16rem] pb-[5rem] pt-[3rem]`}
          data-nc-id="SectionHeroArchivePage"
        >
          <div className="flex flex-col lg:flex-row lg:items-center">
            <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0">
              <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
                Scopri i borghi più belli d'Italia
              </h2>
            </div>
            <div className="flex-grow">
              <img className="w-full" src={rightImage} alt="hero" />
            </div>
          </div>
        </div>
        <div className=" lg:flow-root w-full">
          <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
            <div className="relative py-16">
              <BackgroundSection />
              <SectionSliderNewCategories
                heading="Destinazione"
                subHeading="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
                categoryCardType="card5"
                itemPerRow={5}
                sliderStyle="style2"
                uniqueClassName="ListingExperiencesMapPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BorghiPage;
