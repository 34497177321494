import { apiSlice } from "../../app/api/apiSlice";

export const searchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchProperties: builder.query({
      query: (query) => ({
        url: "/property/search",
        method: "POST",
        body:{
          region: query.region,
          city: query.city,
          check_in: query.check_in,
          check_out: query.check_out,
          guests: query.guests,
        } 
      }),
      providesTags: ["SearchResults"],
      invalidatesTags: ["SearchResults"],
    }),
    filterProperties: builder.query({
      query: (filter) => ({
        url: `/property/filter/`,
        method: "POST",
        body: filter,
      }),
      invalidatesTags: ["SearchResults"],
    }),
    getByCity: builder.query({
      query: (filter) => ({
        url: `/property/byCity/`,
        method: "POST",
        body: filter,
      }),
      invalidatesTags: ["SearchResults"],
    }),
  })
});


export const {
  useSearchPropertiesQuery,
  useLazySearchPropertiesQuery,
  useLazyFilterPropertiesQuery,
  useLazyGetByCityQuery
} = searchApiSlice;