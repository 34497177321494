import { createSlice } from "@reduxjs/toolkit";

const authClientSlice = createSlice({
  name: "authclient",
  initialState: {
    token: null,
    loggedClient: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { token, client } = action.payload
      state.token = token
      state.loggedClient = client
    },
    setLoggedClient: (state, action) => {
      const { client } = action.payload
      state.loggedClient = client
    },  
    logout: (state) => {
      state.token = null
      state.loggedClient = null
    }
  },
});

export const { setCredentials, setLoggedClient, logout } = authClientSlice.actions;

export default authClientSlice.reducer;

export const selectCurrentToken = (state) => {
  return state.persistedReducer.authclient.token
};
export const selectCurrentLoggedClient = (state) => {
  return state.persistedReducer.authclient.loggedClient
};