import { FC, Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

interface RefundModalProps {
  isOpen: boolean;
  closeModal: () => void;
  cancelBooking: () => void;
  booking: any;
  isLoading?: boolean;
}

const RefundModal: FC<RefundModalProps> = ({
  isOpen,
  closeModal,
  cancelBooking,
  booking,
  isLoading
}) => {
  const [daysUntilCheckIn, setDaysUntilCheckIn] = useState<number>(0);
  const [bookingDuration, setBookingDuration] = useState<number>(0);
  const [refundable, setRefundable] = useState<boolean>(false);

  const checkIfRefundable = () => {
    if (booking) {
      // calculate how many days are left until the check-in date
      const checkInDate = moment(booking.check_in)
      const checkOutDate = moment(booking.check_out)
      const today = moment()
      const bookingDuration = moment.duration(checkOutDate.diff(checkInDate))
      setBookingDuration(bookingDuration.asDays())
      const daysUntilCheckIn = checkInDate.diff(today, 'days')
      setDaysUntilCheckIn(daysUntilCheckIn)
      // if daysUntilCheckIn is lower than booking duration, then it's not refundable
      if (daysUntilCheckIn > bookingDuration.asDays()) {
        setRefundable(true)
      } else {
        setRefundable(false)
      }
    }
  };

  useEffect(() => {
    checkIfRefundable()
  }, [booking])

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div className="fixed inset-0 flex items-center justify-center p-5 md:p-0">
              <div className="bg-white rounded-lg p-10 flex items-start flex-col justify-center">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Annulla la prenotazione
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 break-words max-w-md">
                    {refundable ? `Sei sicuro di voler annullare la prenotazione? Hai ancora ${daysUntilCheckIn - bookingDuration} giorni per farlo.\n
                    Riceverai un rimborso completo.`
                      : `Non è possibile ricevere un rimborso per la prenotazione a meno di ${bookingDuration} giorni dalla data di check-in.
                      Puoi pur sempre annullare la prenotazione, ma non riceverai alcun rimborso.
                      `}
                  </p>
                </div>
                <div className="mt-4 flex flex-row gap-3">
                  <ButtonSecondary
                    onClick={closeModal}
                  >
                    Chiudi
                  </ButtonSecondary>
                  <ButtonPrimary
                    onClick={cancelBooking}
                    loading={isLoading}
                  >
                    Annulla prenotazione
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default RefundModal;
