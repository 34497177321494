import React, { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/home_3.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className} gap-10 self-center`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="self-center lg:self-start">
        <NcImage src={rightImg} className="h-[10rem]" />
      </div>
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/3">
        <h2 className="font-semibold text-4xl">Iscriviti alla nostra newsletter 🎉</h2>
        <span className="mt-5 text-neutral-500 dark:text-neutral-400">
          Unisciti alla nostra tribù di viaggiatori, smartworker ed aziende.
        </span>

        <form className="mt-10 relative max-w-sm">
          <Input
            required
            aria-required
            placeholder="Inserisci la tua email"
            type="email"
            rounded="rounded-full"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
    </div>
  );
};

export default SectionSubscribe2;
