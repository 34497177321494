import React, { FC, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import StayCard from "components/StayCard/StayCard";
import ExperiencesFilter from "containers/ListingExperiencesPage/ExperiencesFilter";
import { filter } from "lodash";
import { useLazyFilterExperienceQuery } from "features/experience/experienceApiSlice";
import { useDispatch } from "react-redux";
import { selectLatitude, selectLongitude, setSearchResults } from "features/search/searchSlice";
import { useSelector } from "react-redux";
import useWindowSize from "hooks/useWindowResize";

export interface SectionGridHasMapProps {
  className?: string;
  data?: any;
  isLoading?: boolean;
  city: string;
  region: string;
  check_in: string;
  check_out: string;
  guests: number;
  hasMap?: boolean;
  cardType?: string
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({
  className = "",
  data,
  isLoading,
  city,
  region,
  check_in,
  check_out,
  guests,
  cardType
}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const dispatch = useDispatch();
  const latitude = useSelector(selectLatitude)
  const longitude = useSelector(selectLongitude)
  const { width } = useWindowSize();

  const [filterExperiences, { data: experiences, isLoading: isLoadingExperiences, isFetching: isFetchingExperiencs, isSuccess: isSuccessExperiences }] =
    useLazyFilterExperienceQuery({});

  const handleFilter = async (selectedType: any, latitude: any, longitude: any, radius: number, rangePrices: any, selectedMoment: any) => {
    try {
      const response = await filterExperiences({ type: selectedType, latitude, longitude, radius, price: rangePrices, moment: selectedMoment, }).unwrap();
      dispatch(setSearchResults(response));
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div>

      <div className="relative flex flex-col min-h-screen">
        <div className="lg:pl-[2rem]">
          <Heading2
            city={city}
            region={region}
            check_in={check_in}
            check_out={check_out}
            guests={guests}
            results={data?.length}
          />
          <div className="mb-8 lg:mb-1">
            {
              cardType === "card5" ? (
                <ExperiencesFilter
                  latitude={latitude}
                  longitude={longitude}
                  handleFilter={handleFilter}
                />
              ) : (
                <TabFilters />
              )
            }
          </div>
        </div>
        <div className="relative flex">
          {/* CARDSSSS */}
          <div className={`min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ${showFullMapFixed && "min-h-screen"}`}>
            <div className="mb-8 lg:mb-11">
            </div>
            <div className={
              `grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 
            ${cardType === "card5" ? "lg:grid-cols-1" : "lg:grid-cols-2"}
            ${cardType === "card5" ? "xl:grid-cols-1" : "xl:grid-cols-2"}
             `}>
              {!isLoading ? data?.map((stay: any) => (
                cardType === "card5" ? (
                  <StayCardH
                    key={stay.experience_uuid}
                    data={stay}
                  />
                ) : (
                  <StayCard
                    key={stay.property_uuid}
                    data={stay}
                  />
                )
              ))
                :
                Array.from({ length: 8 }).map((_, index) => (
                  <StayCard key={index} loading />
                ))
              }
            </div>
            <div className="flex mt-16 justify-center items-center">
            </div>
          </div>

          {!showFullMapFixed && (
            <div
              className="flex xl:hidden items-center justify-center fixed bottom-[4rem] left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
              onClick={() => setShowFullMapFixed(true)}
            >
              <i className="text-lg las la-map"></i>
              <span>Mappa</span>
            </div>
          )}

          {/* MAPPPPP */}
          <div
            className={`xl:flex-grow xl:static xl:block ${showFullMapFixed ? "fixed inset-0 z-[99]" : "hidden"
              }`}
          >
            {showFullMapFixed && (
              <ButtonClose
                onClick={() => setShowFullMapFixed(false)}
                className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
              />
            )}

            
              <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
                <GoogleMapReact
                  defaultZoom={12}
                  center={{ lat: data[0]?.latitude, lng: data[0]?.longitude }}
                  bootstrapURLKeys={{
                    key: "AIzaSyCVgu-JWoz3RsKhafHgSffRNKPBngBETxs",
                  }}
                  yesIWantToUseGoogleMapApiInternals
                >
                  {data.map((item: any) => (
                    <AnyReactComponent
                      isSelected={currentHoverID === item.property_uuid}
                      key={item?.property_uuid}
                      lat={item?.latitude}
                      lng={item?.longitude}
                      listing={item}
                    />
                  ))}
                </GoogleMapReact>
              </div>
            

          </div>
        </div>

      </div>
    </div >
  );
};

export default SectionGridHasMap;
