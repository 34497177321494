export type Property = {
  host_uuid?: string
  name?: string
  rental_from?: string
  property_type?: string
  city?: string
  postal_code?: string
  address?: string
  country?: string
  state?: string
  square_meters?: number
  guests_included?: number
  bedrooms?: number
  beds?: number
  bathrooms?: number
  kitchen?: number
  description?: string
  weekend_price?: number
  weekday_price?: number
  currency?: string
  cleaning_fee?: number
  minimum_stay?: number
  maximum_stay?: number
  created_at?: Date
  location?: {
    latitude: number
    longitude: number
  }
  amenities?: Array<string>
}

export type PropertyContextType = {
  property: Property
  updateProperty: (field: String, value: any) => void
}

export const defaultProperty: Property = {
  host_uuid: '',
  name: '',
  rental_from: '',
  property_type: '',
  city: '',
  postal_code: '',
  address: '',
  country: '',
  state: '',
  square_meters: 100,
  guests_included: 2,
  bedrooms: 1,
  beds: 1,
  bathrooms: 1,
  kitchen: 1,
  description: '',
  weekend_price: 0,
  weekday_price: 0,
  currency: '',
  cleaning_fee: 0,
  minimum_stay: 0,
  maximum_stay: 0,
  created_at: new Date(),
  location: {
    latitude: 0,
    longitude: 0
  },
  amenities: []
}

export type PropertyImage = {
  uuid?: string
  property_uuid?: string
  url?: string
  created_at?: Date
  isCover?: boolean
}

export const DefaultImagesList: Array<File> = [

]
