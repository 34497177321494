import { Page } from "routers/types";
import { Route, Routes } from "react-router-dom";
import PageAddHostInfo from "containers/PageAddHostinfo/PageAddHostinfo";
import PageAddHostInfo2 from "containers/PageAddHostinfo/PageAddHostinfo2";
import PageAddHostInfo3 from "containers/PageAddHostinfo/PageAddHostinfo3";
import PageAddHostinfoUpload from "containers/PageAddHostinfo/PageAddHostinfoUpload";

const AddHostInfo: Page[] = [
  { path: "/add-info-1", component: PageAddHostInfo },
  { path: "/add-info-2", component: PageAddHostInfo2 },
  { path: "/add-info-3", component: PageAddHostInfo3 },
  { path: "/upload", component: PageAddHostinfoUpload },

]

const AddHostInfoRouter = () => {
  return (
    <Routes>
      {AddHostInfo.map(({ component, path }) => {
        const Component = component;
        return <Route key={path} element={<Component />} path={path} />;
      })}
    </Routes>

  )
}

export default AddHostInfoRouter;