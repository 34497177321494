import { createSlice } from "@reduxjs/toolkit";

const authHostSlice = createSlice({
  name: "authHost",
  initialState: {
    host: null,
    token: null
  },
  reducers: {
    setCredentials: (state, action) => {
      const { host, token } = action.payload
      state.host = host
      state.token = token
    },
    logout: (state) => {
      state.host = null
      state.token = null

      localStorage.removeItem('host_token')
    }
  },
});

export const { setCredentials, logout } = authHostSlice.actions;

export default authHostSlice.reducer;

export const selectCurrentHost = (state) => state.hostAuthhost;
export const selectCurrentToken = (state) => {
  return state.hostAuth.token
};