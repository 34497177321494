import { useLoadScript } from "@react-google-maps/api";
import React from "react";
import MyRouter from "routers/index";
const libraries = ["places", "maps"];

function App() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCVgu-JWoz3RsKhafHgSffRNKPBngBETxs" as string,
    libraries: libraries as any,
  });
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
