import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SideDrawer from "components/SideDrawer/SideDrawer";

export interface StayCardHProps {
  className?: string;
  data?: StayDataType;
}
const returnMoodColor = (mood: string) => {
  switch (mood) {
    case "authentic":
      return "green";
    case "business":
      return "blue";
    case "comfort":
      return "yellow";
    default:
      return "red";
  }
}
const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCardH: FC<any> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    address,
    title,
    href,
    mood,
    like,
    saleOff,
    media,
    price,
    name,
    rating,
    reviews_count,
    max_guests,
    minutes_duration,
    id,
  } = data;

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({});

  const handleSelectExperience = () => {
    setSelected(data);
    setOpen(true);
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={media}
          uniqueID={`StayCardH_${id}`}
          href={href}
        />
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="hidden sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-user text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              Massimo {max_guests} Persone
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-clock text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {minutes_duration} Minuti
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <span className="text-xl font-bold">
            {name}
          </span>
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              {address}
            </span>
          </div>
          {
            /*           <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>*/
          }
          {mood && <Badge name={mood} color={returnMoodColor(mood)} />}
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch()}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-center">
          <StartRating reviewCount={reviews_count} point={rating} />
          <span className="text-base font-semibold text-secondary-500">
            {(Number(price) + (Number(price) * 0.15) + (Number(price) * 0.15 * 0.22)).toFixed(2)}
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /a persona
            </span>
          </span>
          <ButtonSecondary
            onClick={handleSelectExperience}
          >
            Scopri di più
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <Link to={href} className="absolute inset-0"></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      <SideDrawer
        isOpen={open}
        closeModal={() => setOpen(false)}
        experience={selected}
      />
    </div>
  );
};

export default StayCardH;
