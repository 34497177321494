
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/clientAuth/authClientSlice";
import { useCreateNewPasswordClientMutation, useLoginClientMutation, useRegisterClientMutation, useResetPswClientMutation } from "features/clientAuth/authClientApiSlice";
import { useCreateNewPasswordHostMutation } from "features/hostAuth/authHostApiSlice";
import InputPsw from "shared/InputPsw/InputPsw";

export interface PageSignUpClientProps {
  className?: string;
  onLogin?: () => void;
}

const PageHostChangePsw: FC<PageSignUpClientProps> = ({ className = "", onLogin }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [resetPsw, { isLoading: isLoading, isSuccess }] = useCreateNewPasswordHostMutation({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token } = useParams();

  const handleResetPsw = async (e: any) => {
    e.preventDefault();
    try {
      const resetPswResponse = await resetPsw({ password, token }).unwrap()
    } catch (error: any) {
      setError("Si è verificato un errore, riprova più tardi")
    }
  }


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Recupera passowrd | TRIBYOU</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}

          {!isSuccess ?
            <>
              <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Imposta la nuova password
              </h2>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleResetPsw} method="post">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Nuova password
                  </span>
                  <InputPsw
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Nuova password"
                    className="mt-1"
                  />
                </label>
                {error && <span className="text-red-500">{error}</span>}
                <ButtonPrimary type="submit" loading={isLoading}>Imposta password</ButtonPrimary>
              </form>
            </>
            :
            <>
              <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Hai recuperato la tua password con successo
              </h2>
              <p className="mt-2 text-neutral-800">
                Effettua nuovamente il login con la tua nuova password per recuperare l'accesso al tuo account
              </p>
            </>
          }

        </div>
      </div>
    </div>
  );
};

export default PageHostChangePsw;
