import React, { FC, Fragment, useEffect, useState } from "react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardH";
import Calendar from "components/CustomCalendar/CustomCalendar";
import CustomCalendar from "components/CustomCalendar/CustomCalendar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectEvents, setEvents } from "features/calendar/calendarSlice";
import moment from "moment";
import { selectCurrentproperty } from "features/property/propertySlice";
import { useLazyFilterExperienceQuery, useLazyGetExperienceTimeSlotsQuery, useLazyGetExperiencesNearbyQuery } from "features/experience/experienceApiSlice";
import Skeleton from "react-loading-skeleton";
import { selectBookingByPropertyUUID, selectBookings, setBooking, setBookings } from "features/bookings/bookingsSlice";
import { setExperiencesList } from "features/experience/experienceSlice";
import SideDrawer from "components/SideDrawer/SideDrawer";
import { useNavigate } from "react-router-dom";
import useWindowSize from "hooks/useWindowResize";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BottomSheet from "components/BottomSheet/BottomSheet";
import { Popover, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Select from "shared/Select/Select";
import { useLazyCheckExperienceAvailabilityQuery } from "features/bookings/bookingApiSlice";
import { toast } from 'react-toastify';


export interface SectionGridHasCalendarProps { }

const SectionGridHasCalendar: FC<SectionGridHasCalendarProps> = () => {

  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [defaultDate, setDefaultDate] = useState<Date | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [drawerItem, setDrawerItem] = useState<any>(null);
  const { width } = useWindowSize();

  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const events = useSelector(selectEvents)
  const bookings = useSelector(selectBookings)
  const [selectedBooking, setSelectedBooking] = useState<any>(bookings[0])

  const navigate = useNavigate()

  const [defaultNewEventDate, setDefaultNewEventDate] = useState<Date | null>(bookings[0]?.startDate);
  const [selectedExperienceDate, setSelectedExperienceDate] = useState<any>(bookings[0]?.startDate);

  const [fetchExperiencesNearby, { data: experiencesNearby, isLoading: isLoadingExperiences, isSuccess: isSuccessExperiencesNearby }] = useLazyGetExperiencesNearbyQuery(bookings[0]?.property?.region);
  const [filterExperiences, { data: filterExperienceData, isFetching: isLoadingFilterExperience }] = useLazyFilterExperienceQuery({});
  const [fetchExperienceTimeSlots, { data: timeSlots, isFetching: isLoadingTimeSlots }] = useLazyGetExperienceTimeSlotsQuery({});
  const [checkExperienceAvailability, { data: experienceAvailability, isFetching: isLoadingExperienceAvaialbility }] = useLazyCheckExperienceAvailabilityQuery({});

  const handleOpenBottomSheet = (item: any) => {
    try {
      const response = fetchExperienceTimeSlots({ experience_uuid: item.experience_uuid, date: moment(defaultNewEventDate).toDate() }).unwrap();
      setBottomSheetOpen(true);
    }
    catch (e) {
      console.log(e)
    }
  }

  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (selectedBooking?.property?.region) {
      fetchExperiencesNearby(selectedBooking?.property?.region)
    }
  }, [selectedBooking])


  const handleFilter = async (selectedType: any, latitude: any, longitude: any, radius: number, rangePrices: any, selectedMoment: any) => {
    try {
      const response = await filterExperiences({ type: selectedType, latitude, longitude, radius, price: rangePrices, moment: selectedMoment }).unwrap();
      dispatch(setExperiencesList(response));
    } catch (e) {
      console.log(e)
    }
  }

  const handleCheckExperienceAvailability = async (experience: any, start: any, end: any) => {
    try {
      let obj = { ...experience };
      obj.start = start;
      obj.end = end;
      const response = await checkExperienceAvailability({ experience: obj }).unwrap();
      return response;
    } catch (e) {
      console.log(e)
    }
  }


  const handleCardAddToCalendar = (item: any) => {
    const duration = item.minutes_duration / 60;
    console.log(item)
    const start_date = moment(defaultNewEventDate).set({ hour: item.available_from, minute: 0, second: 0 }).toDate();
    const end = moment(start_date).add(duration, "hours").toDate();
    const obj = { ...item };

    obj.start = start_date;
    obj.end = end;

    // Calcolo del nuovo prezzo
    const basePrice = Number(item.price);
    const markup = basePrice * 0.15;
    const tax = markup * 0.22;
    const newPrice = basePrice + markup + tax;

    obj.price = newPrice.toFixed(2);

    dispatch(setEvents([...events, obj]))
  }

  const prepareEvents = (events: any) => {
    return events.map((event: any) => {
      return {
        ...event,
        start: moment(event.start).toDate(),
        end: moment(event.end).toDate(),
      }
    })
  }

  useEffect(() => {
    if (isLoadingFilterExperience) {
      setShowSkeleton(true);
    }
    else {
      const skeletonTimeout = setTimeout(() => {
        setShowSkeleton(false);
      }, 700); // Mostra gli skeleton per almeno un secondo
      return () => {
        clearTimeout(skeletonTimeout);
      };
    }
  }, [isLoadingFilterExperience]);

  const handleOpenDrawer = (item: any) => {
    setDrawerItem(item);
    setOpenModal(true);
  }
  const closeBottomSheet = () => {
    setBottomSheetOpen(false);
  }

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal])

  const SlotSelector: React.FC<any> = ({ available_from, available_to, minutes_duration, mainCta }) => {
    const [slotIsAvailable, setIsAvailable] = useState(true);

    let slots = [];
    let currentTime = available_from;

    while (currentTime <= available_to) {
      slots.push(currentTime);
      currentTime += minutes_duration / 60; // Aggiunge la durata in ore
    }

    const handleMainCta = async (slot: any) => {
      const start = moment(selectedExperienceDate).set({ hour: slot, minute: 0, second: 0 }).toDate();
      let obj = { ...selectedExperience };
      obj.requestedCheckin = start;
      obj.end = moment(start).add(minutes_duration / 60, "hours").toDate();

      const isAvailable = await mainCta(obj);
      if (isAvailable?.available) {
        setShowModal(false);
        dispatch(setEvents([...events, obj]))
        toast.success("Esperienza aggiunta alle ore " + slot + ":00" + " Il " + moment(start).format("DD MMMM YYYY"))
      } else {
        toast.error("Esperienza non disponibile alle ore " + slot + ":00" + " Il " + moment(start).format("DD MMMM YYYY"))
      }

    }

    return (
      slots.map((slot, i) => (
        <div key={i} className="flex items-center justify-between border-b-[1px] border-b-neutral-200 pb-2">
          <span className="text-md text-neutral-500 dark:text-neutral-400">
            {slot}:00
            {
              !slotIsAvailable &&
              <span className="text-md text-red-500 dark:text-red-400">
                Slot non disponibile
              </span>
            }
          </span>
          <ButtonSecondary
            className="text-sm  !p-3 2xl:p-10 h-[3rem]"
            onClick={handleMainCta && (() => handleMainCta(slot))}
            loading={isLoadingExperienceAvaialbility}
          >
            Seleziona
          </ButtonSecondary>
          {/* SEPARATOR */}
        </div>
      ))
    );
  };

  const handleRemoveExperienceFromCalendar = (experience: any) => {
    dispatch(setEvents(events.filter((event: any) => event.experience_uuid !== experience.experience_uuid)))
    toast.success("Esperienza rimossa dal calendario")
  }

  const handleOpenSelector = (item: any) => {
    setSelectedExperience(item);
    setShowModal(true);
  }

  const getHourFromCheckin = (checkin: any) => {
    // from string like "15:30" to hour like 15 and minute like 30
    const [hour, minute] = checkin.split(":");
    return { hour, minute };
  }

  return (
    <div>
      <div className="relative flex min-h-screen py-0 md:py-10">
        <SideDrawer
          isOpen={openModal}
          experience={drawerItem}
          closeModal={closeModal}
        />
        <BottomSheet
          isOpen={bottomSheetOpen}
          experience={selectedExperience}
          closeModal={closeBottomSheet}
          isLoading={isLoadingTimeSlots}
        />
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[720px] 2xl:w-[950px] px-5 md:p-0 flex-shrink-1 xl:pl-8 ">
          <div className="mb-8 lg:mb-7">
            <Select
              className="max-w-[15rem] my-4"
              onChange={(e) => setSelectedBooking(bookings.find((booking: any) => booking.property.property_uuid === e.target.value))}
            >
              {
                bookings?.map((booking: any) => (
                  booking.type === "stay" &&
                  <option key={booking.id} value={booking?.property?.property_uuid}>
                    {booking?.property.name}
                  </option>
                ))
              }
            </Select>
            <h2 className="text-3xl font-semibold">Esperienze vicino {bookings[0]?.city}</h2>
            <span className="block text-neutral-500 dark:text-neutral-400 mt-1">
              {
                bookings[0]?.startDate &&
                moment(bookings[0].startDate).format("DD MMMM YYYY") + " - " + moment(bookings[0].endDate).format("DD MMMM YYYY")
              }
              <span className="mx-2">·</span>
              {isLoadingExperiences ? "" : experiencesNearby?.length} esperienze
              <span className="mx-2">·</span>{bookings[0]?.guests} persone
            </span>
          </div>
          <div className="mb-8 lg:mb-8">
            <TabFilters handleFilter={handleFilter} latitude={bookings[0]?.property?.latitude} longitude={bookings[0]?.property?.longitude} />
          </div>
          <div className="grid grid-cols-1 gap-8">
            {
              isLoadingFilterExperience || showSkeleton ?
                Array.from(Array(3).keys()).map((_, i) => (
                  <div key={i} className="w-full h-[10rem]">
                    <Skeleton className="w-full h-full" />
                  </div>
                ))
                : filterExperienceData ?
                  filterExperienceData?.map((item: any) => (
                    <div
                      key={item.experience_uuid}
                      onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                      onMouseLeave={() => setCurrentHoverID((_) => -1)}
                    >
                      <ExperiencesCardH
                        showAuthor={false}
                        data={item}
                        showButtons
                        mainCta={() => width > 1280 ? handleCardAddToCalendar(item) : handleOpenBottomSheet(item)}
                        secondaryCta={() => handleOpenDrawer(item)}
                        openModal={() => handleOpenSelector(item)}
                        isExperienceOnCalendar={events.find((event: any) => event.experience_uuid === item.experience_uuid)}
                        removeFromCalendar={() => handleRemoveExperienceFromCalendar(item)}
                      />
                    </div>
                  ))
                  :
                  experiencesNearby?.map((item: any) => (
                    <div
                      key={item.experience_uuid}
                      onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                      onMouseLeave={() => setCurrentHoverID((_) => -1)}
                    >
                      <ExperiencesCardH
                        showAuthor={false}
                        data={item}
                        showButtons
                        mainCta={() => width > 1280 ? handleCardAddToCalendar(item) : handleOpenBottomSheet(item)}
                        secondaryCta={() => handleOpenDrawer(item)}
                        openModal={() => handleOpenSelector(item)}
                        isExperienceOnCalendar={events.find((event: any) => event.experience_uuid === item.experience_uuid)}
                        removeFromCalendar={() => handleRemoveExperienceFromCalendar(item)}
                      />
                    </div>
                  ))
            }
          </div>
          <div className="flex mt-16 justify-center items-center">
          </div>
        </div>

        {
          width > 1280 &&
          <div
            className={`xl:flex-grow xl:static xl:block ${showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
              }`}
          >
            <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 h-full xl:h-[calc(100vh-88px)] rounded-md overflow-scroll">
              <CustomCalendar
                checkIfSlotsIsAvailable={handleCheckExperienceAvailability}
                events={prepareEvents(events)}
                defaultDate={bookings[0]?.startDate ? moment(bookings[0].startDate).toDate() : new Date()}
                onNavigate={(date: Date) => setDefaultNewEventDate(date)}
              />
            </div>
          </div>
        }

        {/* TRANSITION MODAL FROM BOTTOM TO TOP */}
        <Transition
          as={Fragment}
          show={showModal}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <div className="fixed overflow-scroll h-screen w-screen bg-black-transparent z-[99] left-0 top-0 flex items-center justify-center">
            <Popover className={`fixed bottom-0 w-full h-[70%] sm:relative sm:w-90 bg-white shadow-lg lg:w-[35rem] md:w-1/2  rounded-md p-6 md:p-10 flex justify-center items-center flex-col`}>
              <div onClick={() => setShowModal(false)} className="absolute top-5 right-5 cursor-pointer bg-slate-200 rounded-md p-1">
                <XMarkIcon className="w-6 h-6 text-neutral-500" />
              </div>
              <div className="flex flex-1 flex-col w-full h-full">
                <h4 className="font-bold text-lg">Scegli lo slot che preferisci</h4>
                {/* MAP SLOT FROM AVAILABLE_FROM TO AVAILABLE_TO */}
                <div className="flex flex-col space-y-4 mt-4 gap-1 overflow-scroll pb-[5rem] relative">
                  <Select
                    onChange={(e) => setSelectedExperienceDate(e.target.value)}
                  >
                    {/* map dates from bookings[0].startDate to bookings[0].endDate */}
                    {
                      selectedBooking?.startDate && selectedBooking.endDate &&
                      Array.from(Array(moment(selectedBooking.endDate).diff(moment(selectedBooking.startDate), "days") + 1).keys()).map((_, i) => (
                        <option
                          key={i}
                          value={moment(selectedBooking.startDate).add(i, "days").toString()}
                        >
                          {moment(selectedBooking.startDate).add(i, "days").format("DD MMMM YYYY")}
                        </option>
                      ))
                    }
                  </Select>
                  {
                    selectedExperience?.available_from && selectedExperience?.available_to &&
                    <SlotSelector
                      available_from={selectedExperience.available_from}
                      available_to={selectedExperience.available_to}
                      minutes_duration={selectedExperience.minutes_duration}
                      mainCta={handleCheckExperienceAvailability}
                    />
                  }
                </div>
              </div>
            </Popover>
          </div >
        </Transition >
      </div>
    </div>
  );
};

export default SectionGridHasCalendar;
