import React from "react";
import { ReactNode } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectSearchType } from "features/search/searchSlice";

export interface Heading2Props {
  className?: string;
  city?: string;
  region?: string;
  check_in: string;
  check_out: string;
  guests: number;
  results: number;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  city,
  region,
  check_in,
  check_out,
  guests,
  results,
}) => {
  const searchType = useSelector(selectSearchType);

  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      {city && <h2 className="text-4xl font-semibold">{searchType === "experiences" || searchType === 0 ? "Esperienze a " + city : "Soggiorni a " + city}</h2>}
      {region && !city && <h2 className="text-4xl font-semibold">{searchType === "experiences" || searchType === 0 ? "Esperienze in " + region : "Soggiorni in " + region}</h2>}
      {
        !city && !region &&
        <h2 className="text-4xl font-semibold">
          {searchType === "experiences" || searchType === 0 ? "Esperienze" : "Soggiorni"}
        </h2>
      }
      <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
        {results} risultati
        <span className="mx-2">·</span>
        {
          check_in && check_out ?
          moment(check_in).format("DD MMM") + " - " + moment(check_out).format("DD MMM")
          : "Date non specificate"
        }
        <span className="mx-2">·</span>{guests} ospiti
      </span>
    </div>
  );
};

export default Heading2;
