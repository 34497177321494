import React, { useEffect, useState } from "react";
import GuestsInput from "../GuestsInput";
import LocationInput from "../LocationInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { useSelector } from "react-redux";
import { selectMood, selectSearchType, setMood, setSearchType } from "features/search/searchSlice";
import Select from "shared/Select/Select";
import { useDispatch } from "react-redux";

const StaySearchForm = () => {
  //
  const [fieldNameShow, setFieldNameShow] = useState<
    "location" | "dates" | "guests" | "mood" | "typeSelector"
  >("location");
  //
  const [locationInputTo, setLocationInputTo] = useState("");
  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  });
  const [startDate] = useState<Date | null>(new Date());
  const [endDate] = useState<Date | null>(new Date());
  //
  const mood = useSelector(selectMood);
  const dispatch = useDispatch();
  const searchType = useSelector(selectSearchType);

  useEffect(() => {
    dispatch(setSearchType("properties"));
  }, [])

  const renderInputLocation = () => {
    const isActive = fieldNameShow === "location";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("location")}
          >
            <span className="text-neutral-400">Dove</span>
            <span>{locationInputTo || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            defaultValue={locationInputTo}
            onChange={(value) => {
              setLocationInputTo(value);
              setFieldNameShow("dates");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">Quando</span>
            <span>
              {startDate
                ? converSelectedDateToString([startDate, endDate])
                : "Aggiungi data"}
            </span>
          </button>
        ) : (
          <DatesRangeInput />
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest =
        (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0);
      guestSelected += `${guest} ospiti`;
    }

    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} infants`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">Chi</span>
            <span>{guestSelected || `Aggiungi ospiti`}</span>
          </button>
        ) : (
          <GuestsInput defaultValue={guestInput} onChange={setGuestInput} />
        )}
      </div>
    );
  };

  const handleShowMoodDescription = () => {
    switch (mood) {
      case "comfort":
        return <span className="mb-5 block  text-md sm:text-md">
          {"Il mood comfort è pensato per chi viaggia per piacere o per brevi periodi. Relax e divertimento in una zona comoda."}
        </span>
      case "business":
        return <span className="mb-5 block  text-md sm:text-md">
          {"Il mood Business è pensato per chi viaggia per lavoro. Lavorare in modo efficiente in una posizione centrale."}
        </span>
      case "authentic":
        return <span className="mb-5 block  text-md sm:text-md">
          {"Il mood Authentic è pensato per chi viaggia per vivere nuove esperienze e scoprire nuove culture. Esperienza autentica e memorabile in una zona unica."}
        </span>
      default:
        return null
    }
  }
  const renderInputMood = () => {
    const isActive = fieldNameShow === "mood";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("mood")}
          >
            <span className="text-neutral-400">Mood</span>
            <span>{mood ? mood : "Qualsiasi"}</span>
          </button>
        ) : (
          <div className={`flex flex-col relative p-5`}>
            <span className="mb-5 block font-semibold text-xl sm:text-2xl">
              {`Mood`}
            </span>
            {
              handleShowMoodDescription()
            }
            <Select
              onChange={(e) => dispatch(setMood(e.target.value))}>
              <option value="">Qualsiasi</option>
              <option value="comfort">Comfort</option>
              <option value="business">Business</option>
              <option value="authentic">Authentic</option>
            </Select>
          </div>
        )}
      </div>
    );
  };

  const renderTypeInput = () => {
    const isActive = fieldNameShow === "typeSelector";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("typeSelector")}
          >
            <span className="text-neutral-400">Tipo di ricerca</span>
            <span>{searchType === "experieces" ? "Esperienze" : "Alloggi"}</span>
          </button>
        ) : (
          <div className={`flex flex-col relative p-5`}>
            <span className="mb-5 block font-semibold text-xl sm:text-2xl">
              {`Tipo di ricerca`}
            </span>

            <Select
              onChange={(e) => dispatch(setSearchType(e.target.value))}>
              <option value="properties">Alloggi</option>
              <option value="experiences">Esperienze e servizi</option>
            </Select>
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      <div className="w-full space-y-5">
        {/*  */}
        {renderInputLocation()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {renderInputGuests()}
        {/*  */}
        {renderTypeInput()}
        {/*  */}
        {renderInputMood()}

      </div>
    </div>
  );
};

export default StaySearchForm;
