
import React, { FC, Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { useGetHostStatsQuery } from "features/host/hostApiSlice";
import { useDispatch } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import Badge from "shared/Badge/Badge";

export interface AuthorPageProps {
  className?: string;
}

const HostStatsPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const { data: stats } = useGetHostStatsQuery({})
  const dispatch = useDispatch()

  const getOccupancyDays = () => {
    // from occupancy rate calculate the number of days booked
    return Math.round((stats?.occupancyRate || 0) * 30)
  }

  const getEarnings = () => {
    const bookings = stats?.earnings;
    // group bookings by month

    const groupedBookings: Record<string, number> = bookings.reduce((acc: Record<string, number>, booking: any) => {
      const month: string = moment(booking.check_in).format('MMMM');
      if (!acc[month]) {
        acc[month] = 0;
      }
      acc[month] += booking.price;
      return acc;
    }, {});
    const values = Object.values(groupedBookings);
    const labels = Object.keys(groupedBookings);
    const earningsData = {
      labels: labels,
      datasets: [
        {
          label: 'Guadagni',
          data: values,
          borderWidth: 1,
          tension: 0.4,
          fill: true,
          backgroundColor: 'rgba(85, 172, 255, 0.2)'
        }
      ],
    };
    console.log(earningsData);

    return earningsData
  }

  useEffect(() => {
    if (stats?.earnings)
      getEarnings()
  }, [stats])
  const worksData = {
    labels: stats?.propertiesStats?.works?.labels,
    datasets: [
      {
        data: stats?.propertiesStats?.works?.counts,
        backgroundColor: [
          'rgba(247, 60, 105,0.9)',
          'rgba(54, 162, 235,0.9)',
          'rgba(255, 206, 86,0.9)',
          'rgba(75, 192, 192,0.9)',
          'rgba(153, 102, 255,0.9)',
          'rgba(255, 159, 64,0.9)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const agesData = {
    labels: stats?.propertiesStats?.ages?.labels,
    datasets: [
      {
        label: "Anni di nascita",
        data: stats?.propertiesStats?.ages?.counts,
        backgroundColor: [
          'rgba(247, 60, 105,0.9)',
          'rgba(54, 162, 235,0.9)',
          'rgba(255, 206, 86,0.9)',
          'rgba(75, 192, 192,0.9)',
          'rgba(153, 102, 255,0.9)',
          'rgba(255, 159, 64,0.9)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const citiesData = {
    labels: stats?.propertiesStats?.cities?.labels,
    datasets: [
      {
        data: stats?.propertiesStats?.cities?.counts,
        backgroundColor: [
          'rgba(247, 60, 105,0.9)',
          'rgba(54, 162, 235,0.9)',
          'rgba(255, 206, 86,0.9)',
          'rgba(75, 192, 192,0.9)',
          'rgba(153, 102, 255,0.9)',
          'rgba(255, 159, 64,0.9)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const renderSidebar = () => {
    return (
      <div className=" w-[100%] flex flex-col items-center text-left sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <div className="w-full">
          <h2 className="text-2xl font-semibold">Tasso di occupazione ({moment().format('MMMM')})</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Il tasso di occupazione è il rapporto tra il numero di prenotazioni effettuate e il numero di giorni in cui la tua struttura è stata disponibile durante il mese corrente.
          </span>
          <Doughnut
            className="mt-2"
            data={{
              labels: ['Giorni prenotati', 'Giorni disponibili'],
              datasets: [{
                data: [getOccupancyDays(), 30],
                borderWidth: 1
              }]
            }}
          />
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Guadagni</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <Line
            data={getEarnings()}
            options={{

            }}

          />
        </div>

      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        {/* form to change data */}
        <h2 className="text-3xl font-semibold">La tua tribù</h2>
        <p className='text-neutral-500 dark:text-neutral-400 !mt-2'>
          Scopri chi sono i viaggiatori che ti hanno scelto e quali sono le loro caratteristiche
        </p>
        <div className="flex flex-row flex-wrap !mt-10 gap-6 md:gap-0">
          <div className="flex flex-col md:basis-[10%] basis-full">
            <div className="w-[13rem] mb-[1rem]">
              <Badge className="!text-[0.8rem] !p-2" name="Settore lavorativo" color="red" />
            </div>
            <Doughnut data={worksData} />
          </div>
          <div className="flex flex-col md:basis-[10%] basis-full">
            <div className="w-[13rem] mb-[1rem]">
              <Badge className="!text-[0.8rem] !p-2" name="Età media" color="blue" />
            </div>
            {agesData?.labels.length ? <Doughnut data={agesData} /> :
              <span>
                I dati non sono disponibili
              </span>
            }
          </div>
          <div className="flex flex-col md:basis-[10%] basis-full">
            <div className="w-[13rem] mb-[1rem]">
              <Badge className="!text-[0.8rem] !p-2" name="Provenienza" color="green" />
            </div>
            {citiesData?.labels.length ? <Doughnut data={citiesData} /> :
              <span>
                I dati non sono disponibili
              </span>
            }
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Mio profilo | TRIBYOU</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {
            stats?.propertiesStats &&
            renderSection2()
          }
          {
            stats?.propertiesStats &&
            renderSection3()
          }
        </div>
      </main>
    </div>
  );
};

export default HostStatsPage;
