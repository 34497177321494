import React, { FC, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import Avatar from "shared/Avatar/Avatar";
import Textarea from "shared/Textarea/Textarea";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentclient, setclient } from "features/client/clientSlice";
import { useNavigate } from "react-router-dom";
import {  useUploadClientAvatarMutation } from "features/client/clientApiSlice";
import Skeleton from "react-loading-skeleton";

export interface PageAddclientInfoProps { }

const PageAddclientInfo2: FC<PageAddclientInfoProps> = () => {
  const [canGoNext, setCanGoNext] = useState<boolean>(false)

  const [uploadAvatar, { isLoading: isUploading }] = useUploadClientAvatarMutation()
  const client = useSelector(selectCurrentclient)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [imageUrl, setImageUrl] = useState<string>("")

  const handleUpdateclient = (field: string, value: any) => {
    dispatch(setclient({ ...client, [field]: value }));
  };

  const _handleAvatarPicker = async (e: any) => {
    try {
      const url = URL.createObjectURL(e.target.files[0]);
      setImageUrl(url)

      const formData = new FormData()
      formData.append('avatar', e.target.files[0])

      const upload = await uploadAvatar(formData).unwrap();
      handleUpdateclient("avatar", upload.client[0].avatar)
    }
    catch (err) {
      console.log(err)
    }
  }

  const onSubmit = async () => {
    navigate("/client/info/add-info-3")
  }

  if (!client) return
  return (
    <CommonLayout
      index="02"
      nextHref={canGoNext ? "/client/info/add-info-3" : ""}
      backtHref="/client/info/add-info-1"
      canGoNext={client.description?.length > 30 ? true : false}
      onNext={onSubmit}
    >
      <>
        <h2 className="text-2xl font-semibold">I tuoi dati</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="La tua descrizione"
            desc="Descrivi brevemente chi sei e cosa ti piace"
          >
            <Textarea
              defaultValue={client.description}
              onChange={(e) => handleUpdateclient("description", e.target.value)}
              placeholder="Descrizione"
            />
          </FormItem>
          <FormItem
            label="Foto profilo"
            desc="Inserisci una tua foto o il logo della tua azienda"
          >

            {
              isUploading ?
                <div className="w-32 h-32 ">
                  <Skeleton className="h-full rounded-full" />
                </div>
                :
                <div className="flex-shrink-0 flex items-start">
                  <div className="relative rounded-full overflow-hidden flex">
                    <Avatar imgUrl={imageUrl} sizeClass="w-32 h-32" />
                    <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                          stroke="currentColor"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="mt-1 text-xs">Carica foto</span>
                    </div>
                    <input
                      type="file"
                      name="property-cover"
                      onChange={(e) => _handleAvatarPicker(e)}
                      className="absolute inset-0 opacity-0 cursor-pointer"
                    />
                  </div>
                </div>
            }

          </FormItem>
        </div>
      </>
    </CommonLayout >
  );
};

export default PageAddclientInfo2;
