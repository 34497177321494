import React, { FC } from "react";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { usePageAddListingContext } from "contexts/PageAddListingContext";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentproperty, setproperty } from "features/property/propertySlice";
import Input from "shared/Input/Input";

export interface PageAddListing6Props { }

const PageAddListing6: FC<PageAddListing6Props> = () => {
  const property = useSelector(selectCurrentproperty)
  const dispatch = useDispatch()
  const handleUpdateProperty = (field: string, value: any) => {

    try {
      dispatch(setproperty({ ...property, [field]: value }))
    }
    catch (e) {
      console.log(e)
    }
  };

  return (
    <CommonLayout
      index="06"
      backtHref="/host/new-property/add-listing-4"
      nextHref="/host/new-property/add-listing-7"
      canGoNext={property.description?.length > 30 && property.cusr}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">
            Descrivi il tuo alloggio
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Scrivi una descrizione che attiri gli ospiti e li aiuti a immaginare
            il loro soggiorno. Descrivi la tua zona, i motivi per cui ti piace e
            le cose che gli ospiti possono fare nelle vicinanze. Includi alcuni dei servizi che offri.
          </span>
        </div>

        <Textarea
          defaultValue={property.description || ""}
          onChange={(e) => handleUpdateProperty("description", e.target.value)}
          placeholder="Minimio 30 caratteri"
          maxLength={2000}
          rows={24} />
        <div>
          <h2 className="text-2xl font-semibold">
            Scheda tecnica
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Inserisci la scheda tecnica della struttura.
          </span>
        </div>

        <Textarea
          defaultValue={property.item_detail || ""}
          onChange={(e) => handleUpdateProperty("item_detail", e.target.value)}
          placeholder="..."
          maxLength={1000}
          rows={14} />
        {
          property.description?.length < 30 && (
            <div className="mt-4 text-sm text-neutral-500 dark:text-neutral-400">
              Scrivi almeno 30 caratteri
            </div>
          )
        }

        <div>
          <h2 className="text-2xl font-semibold">
            codice unico di ricettività turistica
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Inserisci qui il codice unico di ricettività turistica della proprietà.
          </span>
        </div>

        <Input
          defaultValue={property.cusr || ""}
          onChange={(e) => handleUpdateProperty("cusr", e.target.value)}
          placeholder="..."
        />
      </>
    </CommonLayout>
  );
};

export default PageAddListing6;
