import { Dialog, Transition } from '@headlessui/react'
import GallerySlider from 'components/GallerySlider/GallerySlider';
import StartRating from 'components/StartRating/StartRating';
import { Fragment, useState } from 'react'
import React, { FC } from "react";
import Skeleton from 'react-loading-skeleton';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

export interface SideDrawerProps {
  className?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  experience: any;
  closeModal: () => void;
  isLoading: boolean;
}

const BottomSheet: FC<SideDrawerProps> = ({
  className = "",
  children,
  isOpen,
  experience,
  isLoading,
  closeModal
}) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex h-full items-center justify-end  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-1/2 fixed bottom-0 rounded-t-lg max-w-md transform overflow-hidden md:rounded-s-2xl bg-white p-8 text-left align-middle shadow-xl transition-all ">
                  {
                    isLoading ?
                      <div className='flex flex-col'>
                        <div>
                          <Skeleton className="w-full h-[5rem]" />
                        </div>
                      </div>
                      : null
                  }
                  <div className="flex flex-row items-center absolute bottom-6">
                    <ButtonSecondary
                      onClick={closeModal}
                    >
                      Chiudi
                    </ButtonSecondary>
                    <ButtonPrimary
                      className="ml-4"
                    >
                      Aggiungi al calendario
                    </ButtonPrimary>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default BottomSheet;
