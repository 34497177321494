import { apiSlice } from "app/api/apiSlice";

export const authHostSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginHost: builder.mutation({
      query: (host) => ({
        url: "/host/login",
        method: "POST",
        body: host,
      }),
      invalidatesTags: ["Host"],
    }),
    registerHost: builder.mutation({
      query: (host) => ({
        url: "/host/sign-up",
        method: "POST",
        body: host,
      }),
      invalidatesTags: ["Host"],
    }),
    resetPswHost: builder.mutation({
      query: (client) => ({
        url: "/host/resetPassword",
        method: "POST",
        body: client,
      }),
      invalidatesTags: ["Host"],
    }),
    createNewPasswordHost: builder.mutation({
      query: (client) => ({
        url: "/host/createNewPassword",
        method: "POST",
        body: client,
      }),
      invalidatesTags: ["Host"],
    })
  })
});


export const {
  useLoginHostMutation,
  useRegisterHostMutation,
  useResetPswHostMutation,
  useCreateNewPasswordHostMutation
} = authHostSlice;