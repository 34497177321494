import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import React, { FC, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useCreateExperienceMutation, useLazyGetExperienceQuery } from "features/experience/experienceApiSlice";
import { selectCurrentExperience, setExperience } from "features/experience/experienceSlice";
import ExperienceCard from "components/ExperienceCard/ExperienceCard";

export interface PageAddExperience5Props { }

const PageAddExperience5: FC<PageAddExperience5Props> = () => {
  const experience = useSelector(selectCurrentExperience)
  const [createExperience, { isLoading, isError }] = useCreateExperienceMutation();
  const [triggerGetQuey, { data: getProperty, isLoading: isLoadingProperty, isSuccess }] = useLazyGetExperienceQuery(experience?.experience_uuid)

  const dispatch = useDispatch();

  const handleExperienceCreation = async () => {
    try {
      const obj = {
        ...experience
      }

      let item_detail = "";

      item_detail = item_detail + " " + experience.expect + " " + experience.included + " " + experience.not_included + " " + experience.what_to_see + " " + experience.addons + " " + experience.what_to_wear + " " + experience.difficulty + " "+ experience.meeting_point
      obj.item_detail = item_detail
     
      delete obj.expect 
      delete obj.included
      delete obj.not_included
      delete obj.what_to_see
      delete obj.addons
      delete obj.what_to_wear
      delete obj.difficulty
      delete obj.meeting_point

      const response = await createExperience(obj).unwrap();
      const updatedExperience = await triggerGetQuey(response.experience_uuid).unwrap();
      dispatch(setExperience({ ...updatedExperience.experience }))
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (experience) {
      handleExperienceCreation()
    }
  }, [])

  if ((isLoading || isError) || !isSuccess || !experience.experience_uuid) {
    return (
      <div className="flex flex-1 items-center justify-center p-10 w-screen h-screen">
        <div className="md:w-1/3 w-full">
          <Skeleton className="w-full h-[30rem] md:h-[40rem]" />
        </div>
      </div>
    )
  }
  return (
    <CommonLayout
      nextBtnText="I tuoi annunci"
      index="4"
      nextHref="/host/my-properties"
      canGoNext={true}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Congratulazioni 🎉</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Il tuo annuncio è stato pubblicato con successo. Puoi visualizzarlo o modificarlo nella sezione "I miei annunci".
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div>
          <h3 className="text-lg font-semibold">Il tuo annuncio</h3>
          <div className="max-w-xs">
            <ExperienceCard
              className="mt-8"
              data={{ ...experience, reviewStart: 0 }}
            />
          </div>
          <div className="flex items-center space-x-5 mt-8">
            <ButtonSecondary href={"/host/edit-experience/" + experience.experience_uuid}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span className="ml-3">Modifica</span>
            </ButtonSecondary>
          </div>
        </div>
        {/*  */}
      </>
    </CommonLayout>
  );
};

export default PageAddExperience5;
