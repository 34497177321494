import { createSlice } from "@reduxjs/toolkit";

const borghiSlice = createSlice({
  name: "borghiSlice",
  initialState: {
    borgo: null,
  },
  reducers: {
    setBorgo: (state, action) => {
      state.borgo = action.payload
    },
  },
});

export const { setBorgo } = borghiSlice.actions;

export const selectCurrentBorgo = (state) => state.borghi.borgo;

export default borghiSlice.reducer;

