
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/clientAuth/authClientSlice";
import { useLoginClientMutation, useRegisterClientMutation } from "features/clientAuth/authClientApiSlice";
import InputPsw from "shared/InputPsw/InputPsw";

export interface PageSignUpClientProps {
  className?: string;
  onLogin?: () => void;
}

const PageLoginClient: FC<PageSignUpClientProps> = ({ className = "", onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const [loginClient, { isLoading: isLoginClientLoading }] = useLoginClientMutation();
  const [registerClient, { isLoading: isRegisterLoading }] = useRegisterClientMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showOtp, setShowOtp] = useState(false);


  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      const loginReponse = await loginClient({ email, password, otp_code: otp }).unwrap()
      dispatch(setCredentials({ ...loginReponse }))
      if (!loginReponse.error) {
        localStorage.setItem('client_token', loginReponse.token)
        if (onLogin) {
          onLogin()
        } else {
          navigate("/client/")
        }
      }
    } catch (error: any) {
      if (!error?.data) {
        setError("Nessuna connessione al server")
      } else {
        setError("Email o password sbagliata")
      }
    }
  }


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Accedi | TRIBYOU</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}

          {!showOtp ?

            <>
              <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Accedi a tribyou
              </h2>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin} method="post">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Indirzzo email
                  </span>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                  </span>
                  <InputPsw
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="mt-1"
                  />
                </label>
                <span className="block text-left text-neutral-700 dark:text-neutral-300 cursor-pointer hover:text-primary-6000">
                  <Link to="/reset-password-client">Ho dimenticato la password</Link>
                </span>
                {error && <span className="text-red-500">{error}</span>}
                <ButtonPrimary type="submit" loading={isLoginClientLoading}>Continua</ButtonPrimary>
              </form>

              {/* ==== */}
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Non sei un utente TRIBYOU? {` `}
                <Link to="/signup-client">Registrati</Link>
              </span>
            </>
            :
            <>
              <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Verifica la tua email
              </h2>
              <p className="mt-2 text-neutral-800">
                Ti abbiamo inviato un codice di verifica all'indirizzo email
                <span className="font-semibold"> {email}</span>
              </p>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin} method="post">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Codice a 6 cifre
                  </span>
                  <Input
                    onChange={(e) => setOtp(e.target.value)}
                    type="text"
                    placeholder="123456"
                    className="mt-1"
                  />
                </label>
                {error && <span className="text-red-500">{error}</span>}
                <ButtonPrimary type="submit" loading={isLoginClientLoading}>Verifica</ButtonPrimary>
              </form>

              {/* ==== */}
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Non hai ricevuto il codice? {` `}
                <a className="text-red-500">Invia di nuovo</a>
              </span>
            </>
          }

        </div>
      </div>
    </div>
  );
};

export default PageLoginClient;
