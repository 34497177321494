import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import SearchModal from "components/SearchModal/SearchModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useWindowSize from "hooks/useWindowResize";
import { setMood } from "features/search/searchSlice";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  return (
    <>
      <SearchModal
        show={show}
        closeModal={() => setShow(false)}
      />
      <div
        className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
        data-nc-id="SectionHero"
      >
        <div className="flex flex-col lg:flex-row lg:items-center">
          <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
            <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
              Every place, is your place
            </h2>
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              Trova la destinazione e Tribù che fa per te. Pianifica il tuo soggiorno in pochi click. Scopri i servizi ed esperienze che mettiamo a disposizione per viaggiatori, lavoratori da remoto e aziende
            </span>
            {
              width > 768 ? <ButtonPrimary onClick={() => setShow(true)}>Esplora</ButtonPrimary>
                :
                <ButtonPrimary onClick={() => dispatch(setMood(""))}>Esplora</ButtonPrimary>
            }
          </div>
          <div className="flex-grow">
            <img className="w-full" src={imagePng} alt="hero" />
          </div>
        </div>
      </div>
    </>

  );
};

export default SectionHero;
