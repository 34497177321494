import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import React, { FC, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentproperty, setproperty } from "features/property/propertySlice";
import { useCreatePropertyMutation, useGetPropertyQuery, useLazyGetPropertyQuery } from "features/property/propertyApiSlice";
import Skeleton from "react-loading-skeleton";

export interface PageAddListing10Props { }

const PageAddListing10: FC<PageAddListing10Props> = () => {
  const property = useSelector(selectCurrentproperty)
  const [createProperty, { isLoading, isError }] = useCreatePropertyMutation();
  const [triggerGetQuey, { data: getProperty, isLoading: isLoadingProperty, isSuccess }] = useLazyGetPropertyQuery(property?.property_uuid)
  const [error, setError] = React.useState<string>("")
  const dispatch = useDispatch();

  const handlePropertyCreation = async () => {
    try {
      const response = await createProperty(property).unwrap();
      // trigger query
      const updatedProperty = await triggerGetQuey(response.property_uuid).unwrap();
      dispatch(setproperty({ ...updatedProperty.property }))
    } catch (err: any) {
      if (err?.data?.error?.code === "00002") {
        setError("Esiste già un annuncio con lo stesso nome")
      } 
      console.log(err)
    }
  }
  useEffect(() => {
    if (property) {
      handlePropertyCreation()
    }
  }, [])

  const handleError = () => {
    dispatch(setproperty({}))
    window.open(`/#/host/new-listing`)
  }

  if ((isLoading)) {
    return (
      <div className="flex flex-1 items-center justify-center p-10 w-screen h-screen">
        <div className="md:w-1/3 w-full">
          <Skeleton className="w-full h-[30rem] md:h-[40rem]" />
        </div>
      </div>
    )
  }
  if (isError) {
    return (
      <CommonLayout
        nextBtnText="I tuoi annunci"
        index="9"
        nextHref="/host/my-properties"
        canGoNext={true}
      >
        <>
          <div>
            <h2 className="text-2xl font-semibold">Si è verificato un errore!</h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              {error || "Riprova nuovamente la procedura cliccando il pulsante sottostante."}
            </span>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          {/* FORM */}
          <div>
            <div className="flex items-center space-x-5 mt-8">
              <ButtonSecondary href={"mailto:help@tribyou.life"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
                <span className="ml-3">Chiedi aiuto</span>
              </ButtonSecondary>

              <ButtonPrimary
                onClick={handleError}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
                <span className="ml-3">Riprova la procedura</span>
              </ButtonPrimary>
            </div>
          </div>
          {/*  */}
        </>
      </CommonLayout>

    )
  }
  return (
    <CommonLayout
      nextBtnText="I tuoi annunci"
      index="9"
      nextHref="/host/my-properties"
      canGoNext={true}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Congratulazioni 🎉</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Il tuo annuncio è stato pubblicato con successo. Puoi visualizzarlo o modificarlo nella sezione "I miei annunci".
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div>
          <h3 className="text-lg font-semibold">Il tuo annuncio</h3>
          <div className="max-w-xs">
            <StayCard
              className="mt-8"
              data={{ ...property, reviewStart: 0 }}
            />
          </div>
          <div className="flex items-center space-x-5 mt-8">
            <ButtonSecondary href={"/host/edit-property/" + property.property_uuid}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span className="ml-3">Modifica</span>
            </ButtonSecondary>

            <ButtonPrimary
              onClick={() => window.open(`/#/listing-stay-detail/${property.property_uuid}`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
              <span className="ml-3">Preview</span>
            </ButtonPrimary>
          </div>
        </div>
        {/*  */}
      </>
    </CommonLayout>
  );
};

export default PageAddListing10;
