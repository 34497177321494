import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { PropertyType } from "types/PropertyType";
import { property_type } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { experiences_type } from "constants/experiences_type";
import { selectCurrentExperience, setExperience } from "features/experience/experienceSlice";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";

export interface PageAddListing1Props { }

const PageAddExperience1: FC<PageAddListing1Props> = () => {
  const experience = useSelector(selectCurrentExperience)

  const dispatch = useDispatch()

  const handleUpdateProperty = async (field: string, value: any) => {
    dispatch(setExperience({ ...experience, [field]: value }))
  };

  useEffect(() => {
    dispatch(setExperience({ ...experience, mood: "business" }))
  }, [])
  return (
    <CommonLayout
      index="01"
      backtHref="/host/new-listing/"
      nextHref={`/host/new-experience/add-listing-2`}
      canGoNext={experience?.name?.length > 0 && experience?.description?.length > 0 && experience?.type?.length > 0}
    >
      <>
        <h2 className="text-2xl font-semibold">Carica il tuo annuncio</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Scegli l’esperienza"
            desc=""
          >
            <Select
              defaultValue={""}
              onChange={(e) => handleUpdateProperty("type", e.target.value)}
            >
              <option defaultChecked value={""}>Seleziona</option>
              {
                experiences_type.map((item: any, index: Number) => (
                  <option key={item.id} value={item.value}>{item.label}</option>
                ))
              }
            </Select>
          </FormItem>
          <FormItem
            label="Nome dell'annuncio"
            desc="Un nome accattivante di solito include: Attività + Destinazione turistia"
          >
            <Input
              defaultValue={experience?.name}
              onChange={(e) => handleUpdateProperty("name", e.target.value)}
              placeholder="Nome"
            />
          </FormItem>
          <FormItem
            label="Mood:"
            desc="Business:Posizione centrale pensato per chi viaggia per lavoro. Comfort: Posizione comoda pensato per il relax. Authentic: zone autentiche per esperienze uniche."
          >
            <div className="my-2">
              <Label>Seleziona uno di questi mood per far identificare più velocemente il target specifico a cui ti rivolgi. Il tuo annuncio sarà visibile a tutti, ma si potrà caratterizzare e ti potrà aiutare ad attirare il target a te più congeniale</Label>
            </div>

            <Select
              defaultValue={experience?.mood}
              onChange={(e) => handleUpdateProperty("mood", e.target.value)}
            >
              <option value="business">Business</option>
              <option value="comfort">Comfort</option>
              <option value="authentic">Authentic</option>
            </Select>
          </FormItem>
          <FormItem
            label="Descrizione"
            desc="Descrivi la tua esperienza, includi informazioni utili per gli ospiti come: cosa faranno, cosa impareranno, cosa vedranno, cosa mangeranno, ecc."
          >
            <Textarea
              defaultValue={experience?.description}
              onChange={(e) => handleUpdateProperty("description", e.target.value)}
              placeholder="descrizione..."
            />
          </FormItem>
          <FormItem
            label="Cosa aspettarsi"
            desc=""
          >
            <Textarea
              defaultValue={experience?.expect}
              onChange={(e) => handleUpdateProperty("expect", e.target.value)}
              placeholder="descrizione..."
            />
          </FormItem>
          <FormItem
            label="Cosa vedrai"
            desc=""
          >
            <Textarea
              defaultValue={experience?.what_to_see}
              onChange={(e) => handleUpdateProperty("what_to_see", e.target.value)}
              placeholder="descrizione..."
            />
          </FormItem>
          <FormItem
            label="Cosa è incluso"
            desc=""
          >
            <Textarea
              defaultValue={experience?.included}
              onChange={(e) => handleUpdateProperty("included", e.target.value)}
              placeholder="descrizione..."
            />
          </FormItem>
          <FormItem
            label="Cosa non è incluso"
            desc=""
          >
            <Textarea
              defaultValue={experience?.not_included}
              onChange={(e) => handleUpdateProperty("not_included", e.target.value)}
              placeholder="descrizione..."
            />
          </FormItem>
          <FormItem
            label="Servizi accessori acquistabili"
            desc=""
          >
            <Textarea
              defaultValue={experience?.addons}
              onChange={(e) => handleUpdateProperty("addons", e.target.value)}
              placeholder="descrizione..."
            />
          </FormItem>
          <FormItem
            label="Cosa indossare"
            desc=""
          >
            <Textarea
              defaultValue={experience?.what_to_wear}
              onChange={(e) => handleUpdateProperty("what_to_wear", e.target.value)}
              placeholder="descrizione..."
            />
          </FormItem>
          <FormItem
            label="Livello di difficoltà"
            desc=""
          >
            <Input
              defaultValue={experience?.difficulty}
              onChange={(e) => handleUpdateProperty("difficulty", e.target.value)}
              placeholder="descrizione..."
            />
          </FormItem>
          <FormItem
            label="Punto e orario di incontro"
            desc=""
          >
            <Input
              defaultValue={experience?.meeting_point}
              onChange={(e) => handleUpdateProperty("meeting_point", e.target.value)}
              placeholder="descrizione..."
            />
          </FormItem>

        </div>
      </>
    </CommonLayout >
  );
};

export default PageAddExperience1;
