import { createSlice } from "@reduxjs/toolkit";

const hostSlice = createSlice({
  name: "host",
  initialState: {
    host: null,
    properties: [],
  },
  reducers: {
    setHost: (state, action) => {
      state.host = action.payload
    },
    setProperties: (state, action) => {
      state.properties = action.payload
    },
  },
});

export const { setHost, setProperties } = hostSlice.actions;

export default hostSlice.reducer;

export const selectCurrentHost = (state) => state.host.host;
export const selectProperties = (state) => state.host.properties;