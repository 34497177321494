import { Fragment, useEffect, useMemo, useState } from 'react'
import React, { FC } from "react";
import partner1 from "images/partner1.png";
import partner2 from "images/partner2.png";
import partner3 from "images/partner3.png";
import partner4 from "images/partner4.png";
import partner5 from "images/partner5.png";
import partner6 from "images/partner6.png";
import useNcId from 'hooks/useNcId';
import Glide from "@glidejs/glide";
import NcImage from 'shared/NcImage/NcImage';
import NextPrev from 'shared/NextPrev/NextPrev';
import Heading2 from 'components/Heading/Heading2';
import Heading from 'shared/Heading/Heading';

export interface SectionPartnersProps {
    className?: string;
}

const PARTNERS = [
    { id: 1, img: partner1, name: "Partner 1" },
    { id: 2, img: partner2, name: "Partner 2" },
    { id: 3, img: partner3, name: "Partner 3" },
    { id: 4, img: partner4, name: "Partner 4" },
    { id: 5, img: partner5, name: "Partner 5" },
    { id: 6, img: partner6, name: "Partner 6" },
]
const SectionPartners: FC<SectionPartnersProps> = ({
    className = "",
}) => {
    const UNIQUE_CLASS = `partnerSlider` + useNcId();

    let MY_GLIDEJS = useMemo(() => {
        return new Glide(`.${UNIQUE_CLASS}`, {
            perView: 5,
            gap: 0,
            keyboard: false,
            rewind: true,
            type: "carousel",
            breakpoints: {
                1024: {
                    perView: 4,
                },
                768: {
                    perView: 3,
                },
                640: {
                    perView: 2,
                },
                480: {
                    perView: 1,
                },
            },
        });
    }, [UNIQUE_CLASS]);

    useEffect(() => {
        setTimeout(() => {
            try {
                MY_GLIDEJS.mount();
            }
            catch (error) {
                console.error("Error on mounting glide", error);
            }
        }, 200);

    }, [MY_GLIDEJS,]);

    return (
        <div>
            <Heading desc="I partner di TribYou" className='m-0'>
                I nostri partner
            </Heading>
            <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}>
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {PARTNERS.map((item, index) => (
                            <li key={index} className="glide__slide">
                                <div className={`flex items-center h-auto lg:h-[17rem] w-full`}>
                                    <img src={item.img} />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
                    <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
                </div>
            </div>
        </div>

    );
};

export default SectionPartners;
