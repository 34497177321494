import React, { FC, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectBookings } from "features/bookings/bookingsSlice";
import CalendarPage from "containers/CalendarPage/CalendarPage";

export interface PageAddListing1Props { }

const PageCalendarStep: FC<PageAddListing1Props> = () => {
  const bookings = useSelector(selectBookings)
  // compose string url 
  const prevUrl =  `/listing-stay-detail/${bookings[0]?.property.property_uuid}/`

  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <CommonLayout
      index="1"
      backtHref={prevUrl}
      nextHref={`/client/booking/summary`}
      canGoNext={true}
    >
      <CalendarPage />
    </CommonLayout >
  );
};

export default PageCalendarStep;
