export const experiences_type = [
  {
    label: 'Escursioni',
    value: 'excursion',
    id: '4'
  },
  {
    label: 'Cibo',
    value: 'food',
    id: '1'
  },
  {
    label: 'Animali',
    value: 'animals',
    id: '3'
  },
  {
    label: 'Altro',
    value: 'other',
    id: '5'
  }
]
