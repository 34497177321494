import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { provinceItaliane } from "./provincie";
import { selectCurrentclient, setclient } from "features/client/clientSlice";
import { useUpdateclientMutation } from "features/client/clientApiSlice";
import WorkCategories from "./WorkCategories";

export interface PageAddHostInfoProps { }

const PageAddclientInfo3: FC<PageAddHostInfoProps> = () => {
  const client = useSelector(selectCurrentclient);
  const [updateClient, { isLoading: isUpdating }] = useUpdateclientMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [canGoNext, setCanGoNext] = useState<boolean>(false)

  const handleUpdateClient = (field: string, value: any) => {
    dispatch(setclient({ ...client, [field]: value }));
  };

  const _updateDefaultFields = () => {
    dispatch(setclient({
      ...client,
      state: "italia",
    }));
  }

  const onSubmit = async () => {
    try {
      const response = await updateClient(client).unwrap();
      navigate("/client")
    } catch (err) {
      alert("Errore durante l'aggiornamento dei dati")
      console.log(err)
    }
  }

  useEffect(() => {
    _updateDefaultFields()
  }, []);

  useEffect(() => {
    if (client.address && client.city && client.country && client.postal_code) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
    }
  }, [client])

  return (
    <CommonLayout
      index="03"
      nextHref={canGoNext ? "/client/info/upload" : ""}
      backtHref="/client/info/add-info-2"
      canGoNext={canGoNext}
      onNext={onSubmit}
    >
      <>
        <h2 className="text-2xl font-semibold">I tuoi dati</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Nazione"
          >
            <Select
              defaultValue={client.state}
              onChange={(e) => handleUpdateClient("state", e.target.value)}
              placeholder="Nazione"
            >
              <option value="Italia">Italia</option>
            </Select>
          </FormItem>
          <FormItem
            label="Indirizzo"
          >
            <Input
              defaultValue={client.address}
              onChange={(e) => handleUpdateClient("address", e.target.value)}
              placeholder="Indirizzo"
            />
          </FormItem>
          <FormItem
            label="Città"
          >
            <Input
              defaultValue={client.city}
              onChange={(e) => handleUpdateClient("city", e.target.value)}
              placeholder="Città"
            />
          </FormItem>
          <FormItem
            label="Provincia"
          >
            <Select
              defaultValue={client.country}
              onChange={(e) => handleUpdateClient("country", e.target.value)}
              placeholder="Provnicia"
            >
              {
                provinceItaliane.map((p, i) => (
                  <option key={i} value={p.label.toLowerCase()}>{p.label}</option>
                ))
              }
            </Select>
          </FormItem>
          <FormItem
            label="Codice postale"
          >
            <Input
              defaultValue={client.postal_code}
              onChange={(e) => handleUpdateClient("postal_code", e.target.value)}
              placeholder="Codice postale"
            />
          </FormItem>

          <FormItem
            label="Di cosa ti occupi?"
          >
            <Select
              defaultValue={client.country}
              onChange={(e) => handleUpdateClient("work", e.target.value)}
              placeholder="Professione"
            >
              {
                WorkCategories.map((p, i) => (
                  <option key={i} value={p}>{p}</option>
                ))
              }
            </Select>
          </FormItem>
          <FormItem
            label="Data di nascità"
          >
            <Input
              defaultValue={client.birthday}
              onChange={(e) => handleUpdateClient("birthday", e.target.value)}
              placeholder="Data di nascità"
              type="date"
            />
          </FormItem>
        </div>
      </>
    </CommonLayout >
  );
};

export default PageAddclientInfo3;
