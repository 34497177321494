import React, { FC, Fragment, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Listbox, Popover, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon } from "@heroicons/react/24/outline";
import useWindowSize from "hooks/useWindowResize";

export interface ExperiencesCardHProps {
  className?: string;
  data?: any;
  mainCta?: () => void;
  secondaryCta?: (item: any) => void;
  openModal?: () => void;
  removeFromCalendar?: () => void;
  showButtons?: boolean;
  showAuthor?: boolean;
  showDropDown?: boolean;
  isExperienceOnCalendar?: boolean;
}

const DEMO_DATA: ExperiencesDataType = DEMO_EXPERIENCES_LISTINGS[0];

const ExperiencesCardH: FC<ExperiencesCardHProps> = ({
  className = "",
  data = DEMO_DATA,
  mainCta,
  secondaryCta,
  openModal,
  removeFromCalendar,
  isExperienceOnCalendar,
  showButtons,
  showDropDown,
  showAuthor
}) => {
  const {
    galleryImgs,
    address,
    name,
    href,
    like,
    saleOff,
    isAds,
    description,
    price,
    minutes_duration,
    max_guests,
    cover,
    reviewStart,
    reviews_count,
    rating,
    author,
    experience_uuid,
    media,
    available_from,
    available_to,

  } = data;
  const { width } = useWindowSize();

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full md:w-72 flex-shrink-0 overflow-hidden">
        <GallerySlider
          ratioClass="aspect-w-12 aspect-h-9 md:aspect-h-11"
          galleryImgs={media && media.length ? media : [cover]}
          uniqueID={`ExperiencesCardH_${experience_uuid}`}
          href={href}
        />
        <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{name}</span>
            </h2>
            <span className="text-base font-semibold text-secondary-700 ">
              {(Number(price) + (Number(price) * 0.15) + (Number(price) * 0.15 * 0.22)).toFixed(2)}
              {` `}
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /a persona
              </span>
            </span>
          </div>
          <div className="flex items-center space-x-4 text-sm text-neutral-500 dark:text-neutral-400">
            <StartRating reviewCount={reviews_count} point={rating} />
            <span>· </span>
            <div className="flex items-center">
              <span className="hidden sm:inline-block  text-base">
                <i className="las la-map-marked"></i>
              </span>
              <span className="sm:ml-2"> {address}</span>
            </div>
          </div>
        </div>
        {/* <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div> */}
        <div className="hidden sm:block text-sm text-neutral-500 dark:text-neutral-400 mt-4">
          <span className="line-clamp-2">
            {description}
          </span>
        </div>
        <div className="flex items-center space-x-8 mt-4  ">
          <div className="flex items-center space-x-2">
            <i className="las la-clock text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {minutes_duration} minuti
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <i className="las la-user text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              Fino a {max_guests} persone
            </span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-end gap-2 items-center">
          {
            showAuthor &&
            <div className="flex items-center space-x-3 text-sm text-neutral-700  dark:text-neutral-300">
              <Avatar imgUrl={author.avatar} userName={author.displayName} />
              <span className="hidden sm:inline-block">
                <span className="hidden sm:inline"></span>{" "}
                {author.displayName}
              </span>
            </div>
          }
          <ButtonSecondary
            className="text-sm  !p-3 2xl:p-10 h-[3rem]"
            onClick={secondaryCta && (() => secondaryCta(data))}
          >
            Scopri di più
          </ButtonSecondary>
          {
            showButtons && width > 1280 &&
            <ButtonPrimary
              data-dropdown-toggle="dropdown"
              className="text-sm  !p-3 2xl:p-10 h-[3rem]"
              onClick={() => mainCta && mainCta()}
            >
              Aggiungi al calendario
            </ButtonPrimary>
          }
          {
            showButtons && width <= 1280 ?
              isExperienceOnCalendar ?
                <ButtonPrimary
                  data-dropdown-toggle="dropdown"
                  className="text-sm  !p-3 2xl:p-10 h-[3rem]"
                  onClick={() => removeFromCalendar && removeFromCalendar()}
                >
                  Rimuovi
                </ButtonPrimary>
                :
                <ButtonPrimary
                  data-dropdown-toggle="dropdown"
                  className="text-sm  !p-3 2xl:p-10 h-[3rem]"
                  onClick={() => openModal && openModal()}
                >
                  Seleziona slot
                </ButtonPrimary>
              : null
          }
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`nc-ExperiencesCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
        data-nc-id="ExperiencesCardH"
      >
        <Link to={href} className="absolute inset-0" />
        <div className="md:flex md:flex-row">
          {renderSliderGallery()}
          {renderContent()}
        </div>
      </div>

    </>
  );
};

export default ExperiencesCardH;
