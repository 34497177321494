import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useEffect } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { useGetAllPropertiesQuery, useGetHighlightsQuery } from "features/property/propertyApiSlice";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { setpropertyList } from "features/property/propertySlice";
import { setBookings } from "features/bookings/bookingsSlice";
import { setEvents } from "features/calendar/calendarSlice";
import SectionTrend from "components/SectionTrend/SectionTrend";
import SectionPartners from "components/SectionPartners/SectionPartners";

function PageHome() {
  const { data: highlights, isLoading: loadingHighlights } = useGetHighlightsQuery({})

  const dispatch = useDispatch();

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28 flex flex-col">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION 
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        />
        
        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        {
          loadingHighlights ?
            <Skeleton count={4} />
            :
            highlights?.properties.length ?
              <div className="relative py-16">
                <BackgroundSection />
                <SectionGridFeaturePlaces
                  stayListings={highlights?.properties}
                  tabs={highlights?.borghi.map((item: TaxonomyType) => item.name) || []}
                />
              </div> : null
        }
        {/* SECTION */}
        <SectionHowItWork />

        {/* SECTION 1 
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Esperienze consigliate da trib you"
            subHeading="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor "
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div>
*/}
        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION 
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div>*/}

        {/* SECTION 
                <SectionGridCategoryBox />
        */}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

        <SectionTrend />
        {/* SECTION 1 */}
        <SectionSliderNewCategories
          heading="Destinazione"
          subHeading="Trova la destinazione perfetta per la tua vacanza."
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        />

        {/* SECTION 
        <SectionVideos />*/}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>

        <SectionPartners />
      </div>
    </div>
  );
}

export default PageHome;
