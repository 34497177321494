import { apiSlice } from "../../app/api/apiSlice";

export const clientApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMeClient: builder.query({
      query: () => ({
        url: "/client/",
        method: "GET",
      }),
      providesTags: ["Client"],
    }),
    getMyBookings: builder.query({
      query: () => ({
        url: "/client/bookings",
        method: "GET",
      }),
      providesTags: ["Bookings"],
      invalidatesTags: ["Bookings"],
    }),
    uploadClientAvatar: builder.mutation({
      query: (file) => ({
        url: "/client/upload/avatar",
        method: "POST",
        body: file,
      }),
    }),
    updateclient: builder.mutation({
      query: (client) => ({
        url: "/client/update",
        method: "PUT",
        body: client,
      }),
      invalidatesTags: ["Client"],
    }),
    updateClientSelf: builder.mutation({
      query: (client) => ({
        url: "/client/update/self",
        method: "PUT",
        body: client,
      }),
      invalidatesTags: ["Client"],
    }),
    changePassword: builder.mutation({
      query: ({ oldPassword, newPassword }) => ({
        url: "/client/changePassword",
        method: "POST",
        body: { oldPassword, newPassword }
      }),
      invalidatesTags: ["Client"],
    }),
  })
});


export const {
  useGetMeClientQuery,
  useLazyGetMeClientQuery,
  useUploadClientAvatarMutation,
  useUpdateclientMutation,
  useGetMyBookingsQuery,
  useLazyGetMyBookingsQuery,
  useUpdateClientSelfMutation,
  useChangePasswordMutation
} = clientApiSlice;